import classNames from 'classnames';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from 'src/actions';
import { Icon, IconType } from 'src/components/atoms/Icon';
import { IconButton } from 'src/components/atoms/IconButton';
import { Chat, ChatMessage, ID } from 'src/types';
import { normalizeTime } from 'src/utils/date';

import styles from 'src/components/molecules/Message/styles.module.css';

type ToolsProps = {
  isOwnerMessage: boolean;
};

const Tools: React.FC<ToolsProps> = ({ isOwnerMessage }) => (
  <>
    <IconButton icon={isOwnerMessage ? 'edit' : 'reply'} />
    <IconButton icon="forward" />
    <IconButton icon="moreHoriz" />
  </>
);

export type Props = {
  isBlast?: boolean;
  user?: string;
  company?: string;
  date: string;
  isOwnerMessage?: boolean;
  messageType: ChatMessage['type'];
  message: ChatMessage['message'] | ReactElement;
  isNew?: boolean;
  chatId?: Chat['chatId'];
  messageId?: ID;
};

const chaTypeToIcon: Record<ChatMessage['type'], IconType | null> = {
  'system.addToRoom': 'InDoor',
  'system.deleteFromRoom': 'Exit',
  'user.sendAlert': 'bell',
  'user.message': null,
  'system.deal': null,
  'system.people': 'warning',
};

export const Message: React.FC<Props> = ({
  isBlast,
  user,
  company,
  date,
  isOwnerMessage = false,
  messageType,
  message,
  isNew,
  chatId,
  messageId,
}) => {
  const dispatch = useDispatch();
  const [isHighlight, setIsHighlight] = useState(isNew ?? false);
  const wrapperClass = classNames(
    styles.wrapper,
    isHighlight && (messageType === 'user.sendAlert' || isBlast)
      ? styles.unReadNotification
      : ''
  );
  const [isHover, setIsHover] = useState(false);
  const normalizedDate = normalizeTime(date);
  const icon = chaTypeToIcon[messageType];
  /* const messageClass = classNames(
    styles.message,
    icon ? styles.messageMargin : ''
  ); */

  useEffect(() => {
    if (isHighlight && chatId && messageId) {
      setTimeout(() => {
        setIsHighlight(false);
        dispatch(
          actions.ui.chat.changeStatusMessage({
            messageId,
            chatId,
          })
        );
      }, 500);
    }
  }, [isHighlight]);

  /*   const colorItem = getComputedStyle(document.documentElement)
    .getPropertyValue('--COLOR-WHITE-ONE')
    .trim();
 */

  const isShowHeader = (): boolean =>
    messageType !== 'system.people' &&
    messageType !== 'system.addToRoom' &&
    messageType !== 'system.deleteFromRoom';

  return (
    <div
      role="button"
      tabIndex={0}
      className={wrapperClass}
      onMouseEnter={() => setIsHover((prevState) => prevState)} //TODO: TURN ON LATER - setIsHover((prevState) => !prevState)}
      onMouseLeave={() => setIsHover((prevState) => prevState)} //TODO: TURN ON LATER - setIsHover((prevState) => !prevState)}
    >
      <div className={styles.top}>
        {
          <div className={styles.header}>
            {isShowHeader() && icon && icon !== 'bell' && (
              <div className={styles.infoMessageIcon}>
                <Icon icon={icon} />
              </div>
            )}
            {isShowHeader() && <span className={styles.user}>{user}</span>}

            {isShowHeader() && user && company && (
              <span className={styles.company}>{company}</span>
            )}
          </div>
        }
        <div className={styles.rightBlock}>
          {isHover && (
            <span className={styles.tools}>
              <Tools isOwnerMessage={isOwnerMessage} />
            </span>
          )}
        </div>
      </div>
      <div className={styles.bottom}>
        {
          <div className={styles.header}>
            {icon && (
              <div className={styles.infoMessageIcon}>
                <Icon icon={icon} />
              </div>
            )}
            <span className={styles.message}>{message}</span>
            <span className={styles.date}>{normalizedDate}</span>
          </div>
        }
      </div>
    </div>
  );
};
