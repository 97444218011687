import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useContext, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { Autocomplete } from 'src/components/atoms/Autocomplete';
import { Button } from 'src/components/atoms/Button';
import { DatePickerModal } from 'src/components/atoms/DatePickerModal';
import { InputModal } from 'src/components/atoms/InputModal';
import { FormLeg } from 'src/components/molecules/FormLeg';
import { TicketHeader } from 'src/components/molecules/TicketHeader';
import { ModalStateContext } from 'src/components/providers/ModalProvider';
import { CLASSES, DIRECTIONS, INSTRUMENTS } from 'src/constants/blotter';
import { REGEXP } from 'src/constants/regexp';
import { ReduxState } from 'src/reducers';
import { ITicketFormValues, Ticket as TypeTicket } from 'src/types';
import { getCorrectDay, getWorkingDay } from 'src/utils/blotter';
import { prepearFormData } from 'src/utils/forms';
import {
  normalizeCalculationBase,
  normalizeContactDictionary,
  normalizeCurrencyPairDictionary,
  normalizeOrganizationDictionary,
  normalizeSimpleDictionary,
} from 'src/utils/monitor';

import styles from 'src/components/organisms/modals/Ticket/styles.module.css';

export type Props = { defaulValue: Partial<TypeTicket> | null };

export const Ticket: React.FC<Props> = ({ defaulValue }) => {
  const dispatch = useDispatch();
  const {
    activeClass,
    currencyPair,
    currency,
    calculationBase,
    instrument,
    transactionDirection,
    organization,
    contact,
    userId,
  } = useSelector((state: ReduxState) => ({
    activeClass: state.monitor.dictionaries.activeClass,
    currencyPair: state.monitor.dictionaries.currencyPair,
    currency: state.monitor.dictionaries.currency,
    calculationBase: state.monitor.dictionaries.calculationBase,
    instrument: state.monitor.dictionaries.instrument,
    transactionDirection: state.monitor.dictionaries.transactionDirection,
    organization: state.blotter.temp.organization,
    contact: state.blotter.temp.contact,
    userId: state.access.authRecord?.userId,
  }));

  const { setIsActiveTicket, clearDefaulValue } = useContext(ModalStateContext);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    getValues,
    register,
    setFocus,
    reset,
  } = useForm<ITicketFormValues>({
    defaultValues: {
      class:
        defaulValue?.activeClass &&
        defaulValue.activeClassId &&
        defaulValue?.activeClassDescription
          ? {
              title: defaulValue.activeClassDescription,
              id: defaulValue.activeClassId,
              code: defaulValue.activeClass,
            }
          : normalizeSimpleDictionary(activeClass)?.[0],
      direction:
        defaulValue?.transactionDirection &&
        defaulValue?.transactionDirectionId &&
        defaulValue.transactionDirectionDescription
          ? {
              title: defaulValue.transactionDirectionDescription,
              id: defaulValue.transactionDirectionId,
              code: defaulValue.transactionDirectionDescription,
            }
          : normalizeSimpleDictionary(transactionDirection)?.find(
              (item) => item.code === DIRECTIONS.BUY
            ),
      instrument:
        defaulValue?.instrument &&
        defaulValue?.instrumentId &&
        defaulValue?.instrumentDescription
          ? {
              title: defaulValue.instrumentDescription,
              id: defaulValue.instrumentId,
              code: defaulValue.instrument,
            }
          : normalizeSimpleDictionary(instrument)?.find(
              (item) => item.code === INSTRUMENTS.SPOT
            ),
      organization:
        defaulValue?.counterAgentTraderContactOrganization &&
        defaulValue?.counterAgentTraderContactOrganizationId
          ? {
              title: defaulValue.counterAgentTraderContactOrganization,
              id: defaulValue.counterAgentTraderContactOrganizationId,
            }
          : null,
      currencyPair:
        defaulValue?.currencyPair && defaulValue?.currencyPairId
          ? { title: defaulValue.currencyPair, id: defaulValue.currencyPairId }
          : null,
      contact:
        defaulValue?.counterAgentTraderContact &&
        defaulValue?.counterAgentTraderContactId
          ? {
              title: defaulValue.counterAgentTraderContact,
              id: defaulValue.counterAgentTraderContactId,
            }
          : null,
      transactionVolume: defaulValue?.transactionVolume
        ? defaulValue.transactionVolume
        : '',
      fullPrice: defaulValue?.fullPrice || '',
      valueDate:
        defaulValue?.valueDate &&
        defaulValue?.instrument &&
        defaulValue?.currencyPair
          ? getCorrectDay(
              defaulValue.instrument,
              defaulValue.currencyPair,
              dayjs()
            )
          : getWorkingDay(dayjs()),
      base_currency:
        defaulValue?.firstCurrency && defaulValue?.firstCurrencyId
          ? {
              title: defaulValue.firstCurrency,
              id: defaulValue.firstCurrencyId,
            }
          : null,
      credit_sum: defaulValue?.creditSum || '',
      date_from: null,
      date_to: null,
      interest_rate: defaulValue?.interestRate || '',
      interest_payment_date: defaulValue?.interestPaymentDate
        ? getWorkingDay(dayjs(defaulValue.interestPaymentDate))
        : '',
      calculation_base:
        defaulValue?.calculationBase && defaulValue.calculationBaseId
          ? {
              title: defaulValue.calculationBase,
              id: defaulValue.calculationBaseId,
            }
          : null,
      counter_currency:
        defaulValue?.firstCurrency && defaulValue?.firstCurrencyId
          ? {
              title: defaulValue.firstCurrency,
              id: defaulValue.firstCurrencyId,
            }
          : null,
      spot_price: defaulValue?.spotPrice || '',
      forward_points: defaulValue?.forwardPoints || '',
      swap_points: defaulValue?.swapPoints || '',
      shortLeg: {
        transaction_direction:
          defaulValue?.shortLegTransactionDirection &&
          defaulValue?.shortLegTransactionDirectionId &&
          defaulValue?.shortLegTransactionDirectionDescription
            ? {
                title: defaulValue.shortLegTransactionDirectionDescription,
                id: defaulValue.shortLegTransactionDirectionId,
                code: defaulValue.shortLegTransactionDirection,
              }
            : normalizeSimpleDictionary(transactionDirection)?.find(
                ({ code }) => code === DIRECTIONS.BUY
              ),
        tenor: null,
        instrument:
          defaulValue?.shortLegInstrument &&
          defaulValue?.shortLegInstrumentId &&
          defaulValue?.shortLegInstrumentDescription
            ? {
                title: defaulValue.shortLegInstrumentDescription,
                id: defaulValue.shortLegInstrumentId,
                code: defaulValue?.shortLegInstrument,
              }
            : normalizeSimpleDictionary(instrument)?.find(
                (item) => item.code === INSTRUMENTS.SPOT
              ),
        volume: defaulValue?.shortLegTransactionVolume || '',
        full_price: defaulValue?.shortLegFullPrice || '',
        date_value: getWorkingDay(dayjs()),
        spot_price: defaulValue?.shortLegSpotPrice || '',
        forward_points: defaulValue?.shortLegForwardPoints || '',
      },
      longLeg: {
        transaction_direction:
          defaulValue?.longLegTransactionDirection &&
          defaulValue.longLegTransactionDirectionId &&
          defaulValue?.longLegTransactionDirectionDescription
            ? {
                title: defaulValue.longLegTransactionDirectionDescription,
                id: defaulValue.longLegTransactionDirectionId,
                code: defaulValue.longLegTransactionDirection,
              }
            : normalizeSimpleDictionary(transactionDirection)?.find(
                ({ code }) => code === DIRECTIONS.SELL
              ),
        instrument:
          defaulValue?.longLegInstrument &&
          defaulValue?.longLegInstrumentId &&
          defaulValue?.longLegInstrumentDescription
            ? {
                title: defaulValue.longLegInstrumentDescription,
                id: defaulValue.longLegInstrumentId,
                code: defaulValue.longLegInstrument,
              }
            : normalizeSimpleDictionary(instrument)?.find(
                (item) => item.title === INSTRUMENTS.FORWARD
              ),
        tenor: null,
        volume: defaulValue?.longLegTransactionVolume || '',
        full_price: defaulValue?.longLegFullPrice || '',
        date_value: getCorrectDay(
          INSTRUMENTS.SPOT,
          'USD/CAD',
          getWorkingDay(dayjs())
        ),
        spot_price: defaulValue?.longLegSpotPrice || '',
        forward_points: defaulValue?.longLegForwardPoints || '',
      },
    },
  });

  watch([
    'class',
    'direction',
    'instrument',
    'organization',
    'contact',
    'shortLeg.transaction_direction',
    'longLeg.transaction_direction',
    'shortLeg',
    'longLeg',
    'currencyPair',
    'valueDate',
    'date_from',
    'date_to',
  ]);

  const onSubmit: SubmitHandler<ITicketFormValues> = (data) => {
    const ticket = prepearFormData(
      data,
      getValues('class.code'),
      getValues('instrument.code'),
      userId
    );
    defaulValue?.id
      ? dispatch(
          actions.api.blotter.changeTicketStatus.started({
            ticket_id: defaulValue.id,
            action: 'SEND',
            extra: { userId },
          })
        )
      : dispatch(actions.api.blotter.createTicket.started(ticket as never));
    handleClose();
  };

  const currentTrader = contact?.find(
    (item) => item.id === getValues('contact')?.id
  );

  const currentOrganiation = organization?.find(
    (item) => item.id === getValues('organization')?.id
  );

  useEffect(() => {
    if (
      currentOrganiation &&
      currentOrganiation?.id !== currentTrader?.organization_id
    ) {
      dispatch(
        actions.api.blotter.contact.started({
          organization_id: currentOrganiation?.id,
        })
      );
      setValue('contact', { title: '', id: '' });
      setFocus('contact');
    }
  }, [dispatch, getValues('organization')]);

  useEffect(() => {
    if (
      currentTrader &&
      currentOrganiation?.id !== currentTrader?.organization_id
    ) {
      setValue('organization', {
        id: currentTrader.organization_id,
        title: currentTrader.Organization.title,
      });
    }
  }, [dispatch, getValues('contact')]);

  const organizationFetch = (value: string) => {
    if (value.length) {
      dispatch(
        actions.api.blotter.organization.started({
          title: value,
        })
      );
    }
    if (value.length === 0) {
      dispatch(actions.api.blotter.organization.started());
    }
  };
  const contactFetch = (value: string) => {
    if (!currentOrganiation && value.length) {
      const words = value.split(' ');
      dispatch(
        actions.api.blotter.contact.started({
          first_name: words[0] ?? value,
          last_name: words[1] ?? value,
        })
      );
    }
    if (!currentOrganiation && value.length === 0) {
      dispatch(actions.api.blotter.contact.started());
    }
  };

  const currencyPairFetch = (value: string) => {
    if (value.length) {
      dispatch(
        actions.api.dictionaries.currencyPair.started({
          name: value,
        })
      );
    }
    if (value.length === 0) {
      dispatch(actions.api.dictionaries.currencyPair.started());
    }
  };

  useEffect(() => {
    const classCode = getValues('class.code');
    const instrumentCode = getValues('instrument.code');
    if (classCode === CLASSES.DP) {
      setValue(
        'instrument',
        normalizeSimpleDictionary(instrument)?.find(
          ({ code }) => code === INSTRUMENTS.CREDIT
        )
      );
    }
    if (classCode === CLASSES.FX && instrumentCode === INSTRUMENTS.CREDIT) {
      setValue(
        'instrument',
        normalizeSimpleDictionary(instrument)?.find(
          ({ code }) => code === INSTRUMENTS.SPOT
        )
      );
    }
    if (
      classCode === CLASSES.DP &&
      (getValues('direction.code') !== DIRECTIONS.BORROW ||
        getValues('direction.code') !== DIRECTIONS.LEND)
    ) {
      setValue(
        'direction',
        normalizeSimpleDictionary(transactionDirection)?.find(
          ({ code }) => code === DIRECTIONS.LEND
        )
      );
    }
    if (
      classCode === CLASSES.FX &&
      (getValues('direction.code') !== DIRECTIONS.BUY ||
        getValues('direction.code') !== DIRECTIONS.SELL)
    ) {
      setValue(
        'direction',
        normalizeSimpleDictionary(transactionDirection)?.find(
          ({ code }) => code === DIRECTIONS.BUY
        )
      );
    }
  }, [getValues('class')]);

  useEffect(() => {
    getCorrectDay(
      getValues('instrument.code') as never,
      getValues('currencyPair.title')
    );

    setValue(
      'valueDate',
      getCorrectDay(
        getValues('instrument.code') as never,
        getValues('currencyPair.title')
      )
    );
  }, [getValues('currencyPair'), getValues('instrument')]);

  const handleClose = () => {
    reset();
    dispatch(actions.ui.blotter.clearSearch());
    setIsActiveTicket?.(false);
    clearDefaulValue?.();
    dispatch(actions.api.blotter.organization.started());
    dispatch(actions.api.blotter.contact.started());
    dispatch(actions.api.dictionaries.currencyPair.started());
  };

  useEffect(() => {
    if (
      (getValues('instrument.code') === INSTRUMENTS.SWAP &&
        getValues('shortLeg.date_value')?.isAfter(
          getValues('longLeg.date_value'),
          'd'
        )) ||
      (getValues('instrument.code') === INSTRUMENTS.SWAP &&
        getValues('shortLeg.date_value')?.isSame(
          getValues('longLeg.date_value'),
          'd'
        ))
    ) {
      setValue('longLeg.date_value', null);
      setFocus('longLeg.date_value');
    }
  }, [getValues('shortLeg.date_value'), getValues('longLeg.date_value')]);

  useEffect(() => {
    if (
      (getValues('class.code') === CLASSES.DP &&
        getValues('date_from')?.isAfter(getValues('date_to'), 'd')) ||
      (getValues('class.code') === CLASSES.DP &&
        getValues('date_from')?.isSame(getValues('date_to'), 'd'))
    ) {
      setValue('date_to', null);
      setFocus('date_to');
    }
  }, [getValues('date_from'), getValues('date_to')]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
      <TicketHeader handleClose={handleClose} leftIcon="cable" />

      <div className={styles.content}>
        <div className={styles.classWrapper}>
          <div className={styles.activeClass}>
            <Controller
              name="class"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  label="Класс"
                  list={normalizeSimpleDictionary(activeClass)}
                  onChange={field.onChange}
                  field={field}
                  placeholder="Класс"
                  defaultValue={getValues('class')}
                />
              )}
            />
          </div>

          {getValues('instrument.code') !== INSTRUMENTS.SWAP && (
            <div className={styles.direction}>
              <span className={styles.wrapperDirection}>
                <Button
                  additionalClass={classNames(styles.buy, {
                    [styles.active]:
                      getValues('direction.code') === DIRECTIONS.BUY ||
                      getValues('direction.code') === DIRECTIONS.LEND,
                  })}
                  size="s"
                  onClick={() =>
                    setValue(
                      'direction',
                      normalizeSimpleDictionary(transactionDirection)?.find(
                        ({ code }) =>
                          (getValues('class.code') === CLASSES.DP &&
                            code === DIRECTIONS.LEND) ||
                          (getValues('class.code') === CLASSES.FX &&
                            code === DIRECTIONS.BUY)
                      )
                    )
                  }
                >
                  {getValues('class.code') === CLASSES.FX
                    ? 'Покупка'
                    : 'Занять'}
                </Button>
                <Button
                  additionalClass={classNames(styles.sell, {
                    [styles.active]:
                      getValues('direction.code') === DIRECTIONS.SELL ||
                      getValues('direction.code') === DIRECTIONS.BORROW,
                  })}
                  size="s"
                  onClick={() =>
                    setValue(
                      'direction',
                      normalizeSimpleDictionary(transactionDirection)?.find(
                        ({ code }) =>
                          (getValues('class.code') === CLASSES.DP &&
                            code === DIRECTIONS.BORROW) ||
                          (getValues('class.code') === CLASSES.FX &&
                            code === DIRECTIONS.SELL)
                      )
                    )
                  }
                >
                  {getValues('class.code') === CLASSES.DP
                    ? 'Одолжить'
                    : 'Продажа'}
                </Button>
              </span>
            </div>
          )}
        </div>
        {getValues('class.code') === CLASSES.FX && (
          <div className={styles.types}>
            <Button
              size="s"
              additionalClass={classNames(styles.btn, {
                [styles.active]:
                  getValues('instrument.code') === INSTRUMENTS.SPOT,
              })}
              onClick={() =>
                setValue(
                  'instrument',
                  normalizeSimpleDictionary(instrument)?.find(
                    ({ code }) => code === INSTRUMENTS.SPOT
                  )
                )
              }
            >
              Спот
            </Button>
            <Button
              size="s"
              additionalClass={classNames(styles.btn, {
                [styles.active]:
                  getValues('instrument.code') === INSTRUMENTS.FORWARD,
              })}
              onClick={() =>
                setValue(
                  'instrument',
                  normalizeSimpleDictionary(instrument)?.find(
                    ({ code }) => code === INSTRUMENTS.FORWARD
                  )
                )
              }
            >
              Форвард
            </Button>
            <Button
              size="s"
              additionalClass={classNames(styles.btn, {
                [styles.active]:
                  getValues('instrument.code') === INSTRUMENTS.SWAP,
              })}
              onClick={() => {
                setValue(
                  'instrument',
                  normalizeSimpleDictionary(instrument)?.find(
                    (item) => item.code === INSTRUMENTS.SWAP
                  )
                );

                setValue(
                  'direction',
                  normalizeSimpleDictionary(transactionDirection)?.find(
                    (item) => item.code === DIRECTIONS.BUY
                  )
                );
              }}
            >
              Своп
            </Button>
          </div>
        )}

        <div className={styles.section}>
          <Controller
            rules={{ required: true }}
            name="organization"
            control={control}
            render={({ field }) => {
              return (
                <Autocomplete
                  label="Контрагент"
                  list={normalizeOrganizationDictionary(organization)}
                  onChange={field.onChange}
                  onFetch={organizationFetch}
                  field={field}
                  placeholder="Контрагент"
                  defaultValue={getValues('organization')}
                />
              );
            }}
          />
        </div>

        <div className={styles.section}>
          <Controller
            rules={{ required: true }}
            name="contact"
            control={control}
            render={({ field }) => (
              <Autocomplete
                label="Трейдер контр."
                list={normalizeContactDictionary(contact)}
                onChange={field.onChange}
                onFetch={contactFetch}
                field={field}
                placeholder="Трейдер контрагента"
                defaultValue={getValues('contact')}
              />
            )}
          />
        </div>

        {getValues('class.code') === CLASSES.DP && (
          <>
            <div className={styles.section}>
              <Controller
                rules={{ required: true }}
                name="base_currency"
                control={control}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      label="Валюта"
                      list={normalizeSimpleDictionary(currency)}
                      onChange={field.onChange}
                      field={field}
                      placeholder="Валюта"
                      defaultValue={getValues('base_currency')}
                    />
                  );
                }}
              />
            </div>
            <div className={styles.section}>
              <InputModal
                register={register}
                name="credit_sum"
                label="Сумма кредита"
                placeholder="Сумма кредита"
              />
            </div>
            <div className={styles.section}>
              <Controller
                rules={{ required: true }}
                name="date_from"
                control={control}
                render={({ field }) => {
                  return (
                    <DatePickerModal
                      field={field}
                      label="Дата с."
                      dateTo={getWorkingDay(dayjs())}
                    />
                  );
                }}
              />
            </div>
            <div className={styles.section}>
              <Controller
                rules={{ required: true }}
                name="date_to"
                control={control}
                render={({ field }) => {
                  return (
                    <DatePickerModal
                      field={field}
                      label="Дата по."
                      dateFrom={getValues('date_from')?.add(1, 'd')}
                    />
                  );
                }}
              />
            </div>
            <div className={styles.section}>
              <InputModal
                register={register}
                name="interest_rate"
                label="Проц. ставка"
                placeholder="Процентная ставка"
                regx={REGEXP.NUM2}
              />
            </div>
            <div className={styles.section}>
              <Controller
                name="interest_payment_date"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <DatePickerModal field={field} label="Дата уплаты %." />
                  );
                }}
              />
            </div>
            <div className={styles.section}>
              <Controller
                name="calculation_base"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      label="База"
                      list={normalizeCalculationBase(calculationBase)}
                      onChange={field.onChange}
                      field={field}
                      placeholder="База расчета"
                      defaultValue={getValues('calculation_base')}
                    />
                  );
                }}
              />
            </div>
            <div className={styles.section}>
              <Controller
                name="counter_currency"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      label="Валюта расчета"
                      list={normalizeSimpleDictionary(currency)}
                      onChange={field.onChange}
                      field={field}
                      placeholder="Валюта расчета"
                      defaultValue={getValues('counter_currency')}
                    />
                  );
                }}
              />
            </div>
          </>
        )}

        {getValues('class.code') === CLASSES.FX && (
          <>
            <div className={styles.section}>
              <Controller
                name="currencyPair"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      label="Вал. пара."
                      list={normalizeCurrencyPairDictionary(currencyPair)}
                      onChange={field.onChange}
                      field={field}
                      onFetch={currencyPairFetch}
                      placeholder="Валютная пара"
                      defaultValue={getValues('currencyPair')}
                    />
                  );
                }}
              />
            </div>
            {getValues('instrument.code') !== INSTRUMENTS.SWAP && (
              <>
                <div className={styles.section}>
                  <InputModal
                    register={register}
                    icon="calculate"
                    name="transactionVolume"
                    label="Объем"
                    placeholder="Объем"
                    regx={REGEXP.NUM2}
                  />
                </div>
                <div className={styles.section}>
                  <InputModal
                    register={register}
                    name="fullPrice"
                    label="Полная цена"
                    placeholder="Полная цена"
                    regx={REGEXP.NUM4}
                  />
                </div>

                <div className={styles.section}>
                  <Controller
                    name="valueDate"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <DatePickerModal
                          field={field}
                          label="Дата вал."
                          dateFrom={getValues('valueDate')}
                        />
                      );
                    }}
                  />
                </div>
              </>
            )}

            {getValues('instrument.code') === INSTRUMENTS.FORWARD && (
              <>
                <div className={styles.section}>
                  <InputModal
                    register={register}
                    name="spot_price"
                    label="Спот"
                    placeholder="Спот цена"
                    regx={REGEXP.NUM4}
                  />
                </div>
                <div className={styles.section}>
                  <InputModal
                    register={register}
                    name="forward_points"
                    label="Фрвд. пункты"
                    placeholder="Форвардные пункты"
                    regx={REGEXP.NUM6}
                  />
                </div>
              </>
            )}
          </>
        )}

        {getValues('instrument.code') === INSTRUMENTS.SWAP && (
          <>
            <div className={styles.section}>
              <FormLeg
                getValues={getValues}
                leg="shortLeg"
                control={control}
                instruments={normalizeSimpleDictionary(instrument)}
                register={register}
                setValue={setValue}
                directions={normalizeSimpleDictionary(transactionDirection)}
              />
            </div>
            <div className={styles.section}>
              <InputModal
                register={register}
                name="swap_points"
                label="Своп пункты"
                placeholder="Своп пункты"
                regx={REGEXP.NUM6}
              />
            </div>
            <div className={styles.section}>
              <FormLeg
                getValues={getValues}
                leg="longLeg"
                control={control}
                instruments={normalizeSimpleDictionary(instrument)}
                register={register}
                setValue={setValue}
                directions={normalizeSimpleDictionary(transactionDirection)}
              />
            </div>
          </>
        )}
      </div>

      <div className={styles.footer}>
        <Button
          onClick={() => {
            handleClose();
          }}
        >
          Отменить
        </Button>
        <Button onClick={handleSubmit(onSubmit)}>Отправить</Button>
      </div>
    </form>
  );
};
