import React from 'react';

import { Logo } from 'src/components/atoms/Logo';

import styles from 'src/components/atoms/WelcomBlock/styles.module.css';

export type Props = {
  isDark?: boolean;
  isHorizontal?: boolean;
};

export const WelcomeBlock: React.FC<Props> = ({
  isDark = false,
  isHorizontal = false,
}) => (
  <div className={styles.wrapper}>
    <Logo isDark={isDark} isHorizontal={isHorizontal} />
    <h1 className={styles.title}>Добро пожаловать</h1>
  </div>
);
