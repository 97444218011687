import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import styles from 'src/components/atoms/Input/styles.module.css';

export type Props = {
  name?: string;
  type: 'text' | 'password' | 'email';
  value?: string;
  error?: string;
  placeholder: string;
  disabled?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEnterSubmit?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  isHeader?: boolean;
  headerColumnName?: string;
  filterTickets?: (
    filterField: string,
    filteredData: string[] | string,
    filterType: 'date' | 'select' | 'name'
  ) => void;
};

export const Input: React.FC<Props> = ({
  name,
  type,
  value = '',
  error,
  placeholder,
  disabled,
  required,
  autoFocus,
  onBlur,
  onChange,
  onEnterSubmit,
  isHeader,
  headerColumnName,
  filterTickets,
}) => {
  const [innerValue, setInnerValue] = useState('');
  const wrapperClass = classNames(
    styles.input,
    !isHeader ? styles.notHeaderStyle : styles.headerStyle
  );

  useEffect(() => {
    setInnerValue(value);
  }, [value, type]);

  const onInnerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event);
    setInnerValue(event.target.value);
    isHeader &&
      headerColumnName &&
      filterTickets?.(headerColumnName, event.target.value, 'name');
  };

  return (
    <>
      {error && <span className={styles.error}>{error}</span>}

      <input
        className={wrapperClass}
        style={{
          width: '100%',
          opacity: disabled && !isHeader ? '0.5' : '1',
        }}
        type={type}
        name={name}
        value={innerValue}
        disabled={disabled}
        autoFocus={autoFocus}
        onBlur={onBlur}
        onChange={onInnerChange}
        placeholder={placeholder}
        required={required}
        onKeyDown={onEnterSubmit}
      />
    </>
  );
};
