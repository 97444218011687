import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { GroupChatTabBar } from 'src/components/atoms/GroupChatTabBar';
import { GroupChatContactsSection } from 'src/components/molecules/GroupChatContactsSection';
import { GroupChatCreatForm } from 'src/components/molecules/GroupChatCreatForm';
import { GroupChatSectionHeader } from 'src/components/molecules/GroupChatSectionHeader';
import { defaultSettingsForCreate } from 'src/constants/chat';
import { ReduxState } from 'src/reducers';
import { ChatInstance, ChatInternal, FilterType, User } from 'src/types';
import { isIdContainsChecking } from 'src/utils/chat';

import styles from 'src/components/organisms/GroupChatCreation/styles.module.css';

export type Props = {
  childer?: ReactNode;
  contacts: User[];
  handleClose: () => void;
  filterChat?: (filterType: FilterType) => void;
  activeGroupId: ChatInstance['activeGroupId'];
  defaultValue?: string;
  defaultMembers?: User[];
  defaultOwners?: User[];
  defaultAdmins?: User[];
  isEditingChat?: boolean;
  isAdmin?: boolean;
  currentChat?: ChatInternal;
};

export const GroupChatCreation: React.FC<Props> = ({
  contacts,
  handleClose,
  activeGroupId,
  defaultValue = '',
  defaultMembers = [],
  defaultOwners = [],
  defaultAdmins = [],
  isEditingChat,
  currentChat,
  isAdmin,
  filterChat,
}) => {
  const [value, setValue] = useState(defaultValue);
  const [owners, setOwners] = useState<User[]>(defaultOwners);
  const [members, setMembers] = useState<User[]>(defaultMembers);
  const [admins, setAdmins] = useState<User[]>(defaultAdmins);
  const currentUserId = useSelector(
    (state: ReduxState) => state.access.authRecord?.userId
  );
  const chatOwner = contacts.filter((item) => item.userId === currentUserId);
  useEffect(() => {
    setOwners(defaultOwners.length ? defaultOwners : chatOwner);
  }, [currentUserId, contacts]);

  const membersFormat = members.map((item) => ({
    ...item,
    roles: ['ChatMember'],
  }));

  const adminsFormat = admins.map((item) => ({
    ...item,
    roles: ['ChatAdmin'],
  }));

  const ownersFormat = owners.map((item) => ({
    ...item,
    roles: ['Owner'],
  }));

  const unitedMembers = ownersFormat.concat(adminsFormat, membersFormat);

  const dispatch = useDispatch();
  const handleCreateGroupChat = () => {
    dispatch(
      actions.api.chat.newChat.started({
        name: value,
        isPrivate: false,
        iconId: null,
        chatType: 'PEOPLE',
        members: unitedMembers,
        settings: defaultSettingsForCreate,
        groups: [
          {
            chatGroupId: activeGroupId,
            iconId: null,
            isRoot: false,
            isPrivate: false,
          },
        ],
      })
    );
    handleClose();
    filterChat && filterChat(localStorage.getItem('filterType') as FilterType);
  };

  const onEnterSubmit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && value.length && members.length) {
      handleCreateGroupChat();
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const editChatSettings = () => {
    if (currentChat) {
      dispatch(
        actions.api.chat.chatEdit.started({
          chatId: currentChat.chatId,
          name: value,
          members: unitedMembers,
        })
      );
    }
    handleClose();
  };

  const handleMembers = (
    e: React.MouseEvent<HTMLDivElement | HTMLElement, MouseEvent>,
    id: string,
    isDelete?: boolean
  ) => {
    e.stopPropagation();
    const currentUser = contacts.filter((item) => item.userId === id)[0];
    if (
      id !== currentUserId &&
      !isIdContainsChecking(admins, id) &&
      !isIdContainsChecking(members, id)
    ) {
      setMembers((prevState) =>
        isIdContainsChecking(prevState, id)
          ? prevState
          : prevState.concat(currentUser)
      );
    } else if (isIdContainsChecking(members, id) && isDelete) {
      const membersFiltered = members.filter((item) => item.userId !== id);
      setMembers(membersFiltered);
    } else if (isIdContainsChecking(admins, id) && isDelete) {
      const adminsFiltered = admins?.filter((item) => item.userId !== id);
      setAdmins(adminsFiltered);
    }
  };

  return (
    <div className={styles.container}>
      <GroupChatTabBar
        title={
          defaultValue
            ? 'Редактирование группового чата'
            : 'Создать групповой чат'
        }
        handleClose={() => handleClose()}
      />
      <div className={styles.content}>
        <div className={styles.contentItem}>
          <GroupChatSectionHeader title="Профиль группового чата" />
          <GroupChatCreatForm
            onChange={onChange}
            value={value}
            onEnterSubmit={onEnterSubmit}
            disabled={isAdmin}
          />
        </div>
        <div className={styles.contentItem}>
          <GroupChatSectionHeader
            title="Контакты"
            icons
            handleMembers={handleMembers}
          />

          <GroupChatContactsSection
            contacts={members}
            members={members}
            currentUserId={currentUserId}
            handleMembers={handleMembers}
            admins={admins}
          />

          <div className={styles.wrapperButtons}>
            <button
              onClick={isEditingChat ? editChatSettings : handleCreateGroupChat}
              disabled={!value.length || !members.length}
            >
              Сохранить
            </button>
            <button onClick={handleClose}>Отмена</button>
          </div>
        </div>
      </div>
    </div>
  );
};
