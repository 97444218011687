import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { Button } from 'src/components/atoms/Button';
import { Divider } from 'src/components/molecules/Divider';
import {
  Position,
  TypeDialogPanelProp,
} from 'src/components/organisms/ChatInstance';
import { ChooseMessageType } from 'src/components/organisms/ChooseMessageType';
import { SectionHeader } from 'src/components/organisms/SectionHeader';
import { ReduxState } from 'src/reducers';
import { ChatInstance, FoundChat } from 'src/types';

import styles from 'src/components/organisms/SearchMessageMessagesPanel/styles.module.css';

export type Props = {
  activeChatId: ChatInstance['activeChatId'];
  position?: Position;
  setPosition?: React.Dispatch<React.SetStateAction<Position>>;
  comingSearchString: string;
  setTypePanel?: (value: React.SetStateAction<TypeDialogPanelProp>) => void;
  goToChat: () => void;
  chat: FoundChat | null;
  backButton?: boolean;
  handleBackButtonClick?: () => void;
  handleClose: () => void;
};

export type file = {
  file_name: string;
  size: string;
  createdAt: string;
};

export const SearchMessageMessagesPanel: React.FC<Props> = ({
  activeChatId = '',
  position,
  setPosition,
  comingSearchString,
  setTypePanel,
  goToChat,
  chat,
  backButton,
  handleBackButtonClick,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const [searchString, setIsSearchString] = useState('');
  const endMessageRef = useRef<HTMLDivElement>(null);

  const { messages } = useSelector((state: ReduxState) => ({
    messages: state.chat.temp.foundMessageList?.messages,
  }));

  const useArrayRef = () => {
    const refs: HTMLElement[] = [];
    return [refs, (el: HTMLElement) => el && refs.push(el)];
  };

  const [elements, ref] = useArrayRef();

  useEffect(() => {
    if (comingSearchString) {
      setIsSearchString(comingSearchString);
    }
  }, [comingSearchString]);

  useEffect(() => {
    dispatch(
      actions.api.chat.messagesList.started({
        chat: [activeChatId],
        extra: { search: true },
      })
    );
  }, [dispatch, activeChatId]);

  return (
    <>
      <SectionHeader
        title={chat?.chatName}
        subTitle={chat?.chatName}
        activeChatId={activeChatId}
        backButton={backButton}
        handleBackButtonClick={handleBackButtonClick}
        position={position}
        setPosition={setPosition}
        setSearchMessageForSearchPanel={setIsSearchString}
        setTextSearchRefsArray={elements}
        comingSearchString={searchString}
        setTypePanel={setTypePanel}
        closeButton={true}
        handleClose={handleClose}
      />
      <div className={styles.messagesWrapper}>
        <div className={styles.messages}>
          {messages?.map((message, index) => (
            <div key={message.messageId + index.toString()}>
              {!index && (
                <Divider
                  date={message.createdAt}
                  activeChatId={activeChatId}
                  isLastMessage={!index}
                  messageCreateAt={message.createdAt}
                />
              )}
              <ChooseMessageType
                setRefsFunc={ref}
                memberName={message.memberName}
                messageCreateAt={message.createdAt}
                message={message.message}
                messageType={message.type}
                searchString={searchString}
                isNew={false}
              />
            </div>
          ))}
          <div ref={endMessageRef} />
        </div>
      </div>

      <div className={styles.footer}>
        <Button onClick={goToChat}>Перейти к чату</Button>
      </div>
    </>
  );
};
