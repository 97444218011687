import classNames from 'classnames';
import React from 'react';

import { Icon, IconType } from 'src/components/atoms/Icon';

import styles from 'src/components/atoms/Button/styles.module.css';

export type Props = {
  children: React.ReactNode;
  iconStart?: IconType;
  iconEnd?: IconType;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  size?: 'xs' | 's' | 'm';
  variant?: 'filled' | 'outline' | 'text';
  additionalClass?: any;
};

export const Button: React.FC<Props> = ({
  children,
  iconStart,
  iconEnd,
  onClick,
  disabled,
  size = 'm',
  variant = 'filled',
  additionalClass,
}) => {
  const buttonClass = classNames(
    styles.wrapper,
    styles[size],
    styles[variant],
    additionalClass,
    { [styles.disabled]: disabled }
  );

  return (
    <button
      type="button"
      onClick={onClick}
      className={buttonClass}
      disabled={disabled}
    >
      {iconStart ? <Icon icon={iconStart} /> : null}
      <span className={styles.textWrapper}>{children}</span>
      {iconEnd ? <Icon icon={iconStart} /> : null}
    </button>
  );
};
