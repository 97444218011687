import React from 'react';

import styles from 'src/components/molecules/SimpleTabbar/styles.module.css';

export type Props = {
  title?: string;
};

export const SimpleTabbar: React.FC<Props> = ({ title }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{title}</div>
    </div>
  );
};
