import { Alert } from '@mui/material';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { WelcomeBlock } from 'src/components/atoms/WelcomBlock';
import { Divider } from 'src/components/molecules/Divider';
import { ChatFooter } from 'src/components/organisms/ChatFooter';
import {
  Position,
  TypeDialogPanelProp,
} from 'src/components/organisms/ChatInstance';
import { ChooseMessageType } from 'src/components/organisms/ChooseMessageType';
import { DropItem } from 'src/components/organisms/DropItemList';
import {
  Functionality,
  SectionHeader,
} from 'src/components/organisms/SectionHeader';
import { ReduxState } from 'src/reducers';
import { ChatInstance, ChatMessage } from 'src/types';
import { isSameDate, normalizeShortDate } from 'src/utils/date';
import { getItem, setItem } from 'src/utils/storage';

import styles from 'src/components/organisms/ChatDialogPanel/styles.module.css';

export type Props = {
  activeChatId: ChatInstance['activeChatId'];
  activeGroupId: ChatInstance['activeGroupId'];
  isShown: boolean;
  setIsShown: (value: React.SetStateAction<boolean>) => void;
  position: Position;
  setPosition: React.Dispatch<React.SetStateAction<Position>>;
  setTypePanel: (value: React.SetStateAction<TypeDialogPanelProp>) => void;
  setMemberId?: React.Dispatch<React.SetStateAction<string>>;
};

export type file = {
  file_name: string;
  size: string;
  createdAt: string;
};

export const ChatDialogPanel: React.FC<Props> = ({
  activeChatId = '',
  isShown,
  setIsShown,
  position,
  setPosition,
  setTypePanel,
  setMemberId,
}) => {
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const endMessageRef = useRef<HTMLDivElement>(null);

  const useArrayRef = () => {
    const refs: HTMLElement[] = [];
    return [refs, (el: HTMLElement) => el && refs.push(el)];
  };

  const [elements, ref] = useArrayRef();
  const [functionality, setFunctionality] = useState<Functionality[]>(
    getItem('chatTools', ['forwardto', 'volumeOff'])
  );
  const [searchString, setIsSearchString] = useState('');

  const { chat, currentMessage, file, messages } = useSelector(
    (state: ReduxState) => ({
      chat: state.chat.chats[activeChatId],
      currentMessage: state.chat.temp.currentMessages?.find(
        (curMessage) => curMessage.id === activeChatId
      ),
      file: state.media.temp.tempFilesBeforeSending?.find(
        (tempFile) => tempFile.id === activeChatId
      ),
      messages: state.chat.messages[activeChatId] ?? [],
    })
  );

  const currentUserId = useSelector(
    (state: ReduxState) => state.access.authRecord?.userId
  );

  const isNoMessages = !messages.filter(
    ({ type }) =>
      type === 'user.message' ||
      type === 'user.sendAlert' ||
      type === 'system.deal' ||
      type === 'system.people'
  ).length;

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setVisible(false);
      }, 5000);
    }
  }, [visible]);

  useEffect(() => {
    setItem('chatTools', functionality);
  }, [functionality]);

  useEffect(() => {
    if (isNoMessages && !!activeChatId) {
      dispatch(
        actions.api.chat.messagesList.started({
          chat: [activeChatId],
          // limit: 30,
          // dateBeetwen: {
          //   startedDate: DATE_NOW_MINUS_DAY,
          //   endDate: DATE_NOW,
          // },
        })
      );
    }
    if (!isNoMessages && !!activeChatId) {
      dispatch(
        actions.ws.event({
          method: 'readMessage',
          roomId: activeChatId,
        })
      );
    }
  }, [dispatch, isNoMessages, activeChatId]);

  useLayoutEffect(() => {
    endMessageRef.current?.scrollIntoView({ behavior: 'auto' });
  }, [messages.length]);

  if (!activeChatId || !chat) {
    return <WelcomeBlock />;
  }

  function checkName(mess: ChatMessage, prevMess: ChatMessage) {
    return (
      normalizeShortDate(mess?.createdAt) !==
        normalizeShortDate(prevMess?.createdAt) ||
      prevMess?.memberId !== mess?.memberId ||
      (prevMess?.type !== 'user.message' && prevMess?.type !== 'user.sendAlert')
    );
  }

  const dropItemsHeader: DropItem[] = [
    {
      id: '0',
      title: 'Поиск',
      endIcon: 'pushpin',
      iconStart: 'search',
      disabled: false,
      pinned: functionality.includes('search'),
    },
    {
      id: '1',
      title: 'Заглушить',
      endIcon: 'pushpin',
      iconStart: 'volumeOff',
      disabled: false,
      pinned: functionality.includes('volumeOff'),
    },
    {
      id: '2',
      title: 'Отправить на email',
      endIcon: 'pushpin',
      iconStart: 'forwardto',
      disabled: false,
      pinned: functionality.includes('forwardto'),
    },
  ];

  /* const dropItemsBody: DropItem[] = [
    {
      id: '6',
      title: 'В избранное',
      iconStart: 'star',
      disabled: false,
    },
  ];*/

  /* need refactoring, when back change */

  const userCompany = (chat: any) => {
    if (chat.chatType.code === 'P2P') {
      return chat.members.find((member: any) => member.userId !== currentUserId)
        ?.organization.organizationName;
    }
    return chat.members.length > 5
      ? `${chat.members.length} участников`
      : `${chat.members.length} участника`;
  };

  const uniqueIds = new Set();
  const uniqueMessages = messages.filter((message) => {
    const isDuplicate = uniqueIds.has(message.messageId);
    uniqueIds.add(message.messageId);
    if (!isDuplicate) {
      return true;
    }
    return false;
  });

  return (
    <div className={styles.wrapper}>
      <SectionHeader
        title={chat.name}
        subTitle={userCompany(chat)}
        // functionality={['bell', 'forwardTo']}
        functionality={functionality}
        activeChatId={activeChatId}
        //isBorder
        //dropItemsBody={dropItemsBody}
        dropItemsHeader={dropItemsHeader}
        setFunctionality={setFunctionality}
        isShown={isShown}
        setIsShown={setIsShown}
        position={position}
        setPosition={setPosition}
        setSearchMessageForSearchPanel={setIsSearchString}
        setTextSearchRefsArray={elements}
        setTypePanel={setTypePanel}
        chatType={chat.chatType.code}
        setMemberId={setMemberId}
      />
      <div className={styles.messagesWrapper}>
        <div className={styles.messages}>
          {uniqueMessages.map((message, index, array) => (
            <div key={message.messageId + index.toString()}>
              {(!index ||
                !isSameDate(array[index - 1].createdAt, message.createdAt)) && (
                <Divider
                  date={message.createdAt}
                  activeChatId={activeChatId}
                  isLastMessage={!index}
                  messageCreateAt={message.createdAt}
                />
              )}
              <ChooseMessageType
                setRefsFunc={ref}
                memberName={message.memberName}
                viewName={checkName(
                  uniqueMessages[index],
                  uniqueMessages[index - 1]
                )}
                company={
                  message.organizationName ??
                  message.organization?.organizationName
                }
                messageCreateAt={message.createdAt}
                message={message.message}
                messageType={message.type}
                searchString={searchString}
                isNew={message.isNew}
                chatId={chat?.chatId}
                messageId={message.messageId}
                setAlertMessage={setAlertMessage}
                setVisible={setVisible}
                isOwnerMessage={currentUserId === message.memberId}
                isDeleted={message.isDeleted}
              />
            </div>
          ))}
          <div ref={endMessageRef} />
        </div>
      </div>
      <ChatFooter
        blast={false}
        type="main"
        chatId={chat.chatId}
        activeChatId={activeChatId}
        setVisible={setVisible}
        setAlertMessage={setAlertMessage}
        status={chat.status?.userStatus}
        chatType={chat.chatType.code}
        currentMessage={currentMessage?.message}
        file={file?.file}
      />
      {visible && (
        <Alert
          className={styles.alertWrapper}
          onClose={() => {
            setVisible(false);
          }}
          variant="filled"
          severity="warning"
        >
          {alertMessage}
        </Alert>
      )}
    </div>
  );
};
