import React from 'react';

import { IconType } from 'src/components/atoms/Icon';
import { DropDownItem } from 'src/components/molecules/DropDownItem';
import { DropSubItem } from 'src/components/molecules/DropDownSubItem';
import { Functionality } from 'src/components/organisms/SectionHeader';

import styles from 'src/components/organisms/DropItemList/styles.module.css';

export type DropItem = {
  id: string;
  title: string;
  endIcon?: 'checkbox' | 'arrow' | 'pushpin';
  iconStart?: IconType;
  disabled?: boolean;
  pinned?: boolean;
  action?: () => void;
  subItems?: DropSubItem[];
};

export type Props = {
  dropItems: DropItem[];
  setFunctionality?: (f: Functionality[]) => void;
  functionality?: Functionality[];
  memberId?: string;
  ownerId?: string;
};

export const DropItemList: React.FC<Props> = ({
  dropItems,
  setFunctionality,
  functionality,
  memberId,
  ownerId,
}) => (
  <div className={styles.itemList}>
    {dropItems.map(
      (item) =>
        item && (
          <DropDownItem
            id={item.id}
            title={item.title}
            endIcon={item.endIcon}
            iconStart={item.iconStart}
            disabled={item.disabled}
            key={item.id}
            pinned={item.pinned}
            setFunctionality={setFunctionality}
            functionality={functionality}
            action={item?.action}
            subItems={item?.subItems}
            memberId={memberId}
            ownerId={ownerId}
          />
        )
    )}
  </div>
);
