/* eslint-disable @typescript-eslint/no-non-null-assertion */
import classNames from 'classnames';
import React, { Dispatch } from 'react';

import { Checkbox } from 'src/components/atoms/Checkbox';
import { Icon } from 'src/components/atoms/Icon';
import { BlotterColumn, SortType, Ticket } from 'src/types';

import styles from 'src/components/organisms/List/styles.module.css';

export type Props = {
  isSettingsMode?: boolean;
  columnList: BlotterColumn[];
  setColumnList: Dispatch<React.SetStateAction<BlotterColumn[]>>;
  chekedItems: BlotterColumn[];
  setChekedItems?: Dispatch<React.SetStateAction<BlotterColumn[]>>;
  sortedField?: {
    sortField: keyof Ticket;
    sortType: SortType;
  };
  setSortedField?: Dispatch<
    React.SetStateAction<{
      sortField: keyof Ticket;
      sortType: SortType;
    }>
  >;
};

export const List: React.FC<Props> = ({
  isSettingsMode = false,
  columnList,
  setColumnList,
  chekedItems,
  setChekedItems,
  sortedField,
  setSortedField,
}) => {
  const [dragAndDrop, setDragAndDrop] = React.useState<{
    dragItemIndex: null | number;
    dragOverItemIndex: null | number;
  }>({ dragItemIndex: null, dragOverItemIndex: null });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDragStart = (event: any, position: number) => {
    setDragAndDrop({ ...dragAndDrop, dragItemIndex: position });
    event.dataTransfer.setData('text/html', '');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDragEnter = (event: any, position: number) => {
    event.preventDefault();
    setDragAndDrop({ ...dragAndDrop, dragOverItemIndex: position });
  };

  const onDrop = () => {
    const copyListItems = [...columnList];
    const dragItemContent = copyListItems[dragAndDrop.dragItemIndex as number];
    copyListItems.splice(dragAndDrop.dragItemIndex as number, 1);
    copyListItems.splice(
      dragAndDrop.dragOverItemIndex as number,
      0,
      dragItemContent
    );
    setDragAndDrop({ dragItemIndex: null, dragOverItemIndex: null });
    setColumnList?.(copyListItems);
  };

  const handleCheck = (item: BlotterColumn, e?: React.MouseEvent) => {
    if (
      (e?.target as HTMLElement)?.tagName === 'svg' ||
      (e?.target as HTMLElement)?.tagName === 'path'
    ) {
      return;
    }
    const isCheckedItem = chekedItems?.find((el) => el.key === item.key);
    setChekedItems?.(
      isCheckedItem
        ? chekedItems.filter((el) => el.key !== item.key)
        : [...chekedItems, item]
    );
  };

  const handleSortClick = (item: BlotterColumn) => {
    setSortedField?.({
      sortField: item.name as keyof Ticket,
      sortType:
        item.name === sortedField?.sortField && sortedField?.sortType === 'desc'
          ? 'asc'
          : 'desc',
    });
  };

  return (
    <ul
      className={classNames(styles.wrapper, {
        [styles.settings]: isSettingsMode,
      })}
    >
      {columnList.map((item, index) => {
        return (
          <li
            key={index}
            draggable
            onDragStart={(event) => onDragStart(event, index)}
            onDragEnter={(event) => onDragEnter(event, index)}
            onDragEnd={onDrop}
            onClick={(e) => handleCheck(item, e)}
          >
            <div className={styles.checkSection}>
              <Checkbox
                checked={!!chekedItems?.find((el) => el.key === item.key)}
              />
              <div className={styles.title}>{item.title}</div>
            </div>
            <div className={styles.iconSection}>
              <div className={styles.drag}>
                <Icon icon="dragIndicator" />
              </div>
              <div
                className={classNames(styles.sort, {
                  [styles.sorted]: item.name === sortedField?.sortField,
                  [styles.sortDown]:
                    item.name === sortedField?.sortField &&
                    sortedField?.sortType === 'asc',
                })}
              >
                <Icon
                  fill={
                    item.name === sortedField?.sortField ? '#F2CD40' : '#EDEEF2'
                  }
                  icon="arrowDropDownCircle"
                  handleClick={() => handleSortClick(item)}
                />
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
