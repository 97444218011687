import TextareaAutosize from '@mui/base/TextareaAutosize';
import LinearProgress from '@mui/material/LinearProgress';
// import Divider from '@mui/material/Divider';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { Button } from 'src/components/atoms/Button';
import { IconButton } from 'src/components/atoms/IconButton';
import { LinkText } from 'src/components/atoms/LinkText';
import { FileInfo } from 'src/components/molecules/FileInfo';
import { Bell } from 'src/components/organisms/Bell';
import { UploadPannel } from 'src/components/organisms/UploadPannel';
import { ModalStateContext } from 'src/components/providers/ModalProvider';
import { ReduxState } from 'src/reducers';
import {
  Chat,
  ChatInstance,
  ChatStatus,
  ChatType,
  CurrentMessage,
} from 'src/types';

import styles from 'src/components/organisms/ChatFooter/styles.module.css';

function formatBytes(bytes: any, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export type Props = {
  chatId: Chat['chatId'] | Chat['chatId'][];
  type: 'main' | 'group';
  blast: boolean;
  activeChatId: ChatInstance['activeChatId'];
  toggleEditMode?: () => void;
  setAlertType?: (
    value: React.SetStateAction<'error' | 'success' | 'warning'>
  ) => void;
  setAlertMessage?: (value: React.SetStateAction<string>) => void;
  setVisible?: (value: React.SetStateAction<boolean>) => void;
  status?: ChatStatus['userStatus'];
  chatType?: ChatType['code'];
  currentMessage?: CurrentMessage['message'];
  file?: File;
  blastFile?: File;
  currentBlastMessage?: string;
};

export const ChatFooter: React.FC<Props> = ({
  chatId,
  type,
  blast,
  activeChatId,
  toggleEditMode,
  setAlertType,
  setAlertMessage,
  setVisible,
  status,
  chatType,
  currentMessage,
  file,
  blastFile,
  currentBlastMessage,
}) => {
  const { setIsActiveTicket, setModalPlace, setDefaultValue } =
    useContext(ModalStateContext);

  const dispatch = useDispatch();
  const [isHover, setIsHover] = useState(false);
  const [isAreaFocused, setIsAreaFocused] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [percent, setPercent] = useState(0);

  const currentUserId = useSelector(
    (state: ReduxState) => state.access.authRecord?.userId
  );

  const { chat } = useSelector((state: ReduxState) => ({
    chat: state.chat.chats[activeChatId as never],
  }));

  const p2pUser = chat?.members.find((item) => item.userId !== currentUserId);

  const openModal = () => {
    if (chat?.chatType.code === 'P2P') {
      setDefaultValue?.({
        counterAgentTraderContactOrganization:
          p2pUser?.organization.organizationName,
        counterAgentTraderContactOrganizationId: p2pUser?.organization.id,
        counterAgentTraderContact: p2pUser?.name,
        counterAgentTraderContactId: p2pUser?.userId,
      });
    }
    setModalPlace?.('chat');
    setIsActiveTicket?.(true);
  };

  const changeCurrentMessage = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length >= 500) {
      dispatch(
        actions.ui.chat.updateErrorMessage({
          message: 'Максимальная длина сообщения: 500 символов',
        })
      );
    } else {
      activeChatId &&
        dispatch(
          actions.ui.chat.changeCurrentMessage({
            id: activeChatId,
            message: e.target.value,
          })
        );
    }
  };

  const changeBlastCurrentMessage = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length >= 500) {
      dispatch(
        actions.ui.chat.updateErrorMessage({
          message: 'Максимальная длинна сообщения: 500 символов',
        })
      );
    } else {
      activeChatId &&
        dispatch(actions.ui.chat.changeBlastCurrentMessage(e.target.value));
    }
  };

  useEffect(() => {
    activeChatId &&
      !blast &&
      dispatch(
        actions.ui.chat.changeCurrentMessage({
          id: activeChatId,
          message: currentMessage || '',
        })
      );
  }, [activeChatId]);

  const onSubmit = () => {
    if (currentMessage || currentBlastMessage) {
      dispatch(
        actions.ws.send({
          type: 'sendMessage',
          message: {
            room: typeof chatId === 'string' ? [chatId] : chatId,
            message: JSON.stringify({
              message: blast
                ? currentBlastMessage?.trimStart().trimEnd()
                : currentMessage?.trimStart().trimEnd(),
              type: type === 'main' ? 'message' : 'blast',
            }),
          },
        })
      );
    }
    if (type === 'main' && file) {
      dispatch(
        actions.api.media.upload.started({
          file: file,
          extra: { chatId: chatId },
        })
      );
    }
    if (type === 'group' && blastFile) {
      dispatch(
        actions.api.media.upload.started({
          file: blastFile,
          extra: { chatId: chatId },
        })
      );
    }
    clearFileFromStore();
  };

  const { loaded, total } = useSelector((state: ReduxState) => ({
    loaded: state.media.loaded,
    total: state.media.total,
  }));

  const onHovered = () => setIsHover((isHover) => !isHover);
  const clearMessage = () => {
    blast
      ? dispatch(actions.ui.chat.changeBlastCurrentMessage(''))
      : activeChatId &&
        dispatch(
          actions.ui.chat.changeCurrentMessage({
            id: activeChatId,
            message: '',
          })
        );
  };
  const submitMessage = (
    event:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (!blast && !currentMessage?.trim().length && !file && !blastFile) {
      setAlertMessage?.('Сообщение не может быть пустым!');
      setAlertType?.('warning');
      setVisible?.(true);
    } else if (typeof chatId !== 'string' && chatId.length === 0) {
      setAlertMessage?.('Должен быть выбран получатель рассылки!');
      setAlertType?.('error');
      setVisible?.(true);
    } else if (type === 'group') {
      setAlertMessage?.('Успешная отправка Бласта!');
      setAlertType?.('success');
      setVisible?.(true);
      onSubmit();
      clearMessage();
      toggleEditMode && toggleEditMode();
    } else {
      onSubmit();
      clearMessage();
      if (status === 'OFFLINE' && chatType === 'P2P') {
        dispatch(
          actions.ui.chat.updateErrorMessage({
            message: `Вы отправляете сообщение пользователю в статусе оффлайн. Пользователь ${p2pUser?.name} ${p2pUser?.organization.organizationName} будет проинформирован об этом, получив информационное письмо на свой email`,
          })
        );
      }
    }
  };

  const focusedColor = getComputedStyle(document.documentElement)
    .getPropertyValue('--COLOR_SECONDARY')
    .trim();

  const unfocusedColor = getComputedStyle(document.documentElement)
    .getPropertyValue('--COLOR_ICON_SECONDARY')
    .trim();

  const color = isHover || isAreaFocused ? focusedColor : unfocusedColor;

  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!event.shiftKey && event.key === 'Enter') {
      submitMessage(event);
    }
    if (event.key === 'Escape') {
      clearMessage();
    }
  };

  const clearFileFromStore = () => {
    if (type === 'main') {
      activeChatId &&
        dispatch(
          actions.ui.media.prepareToSendTemp({
            file: undefined,
            id: activeChatId,
          })
        );
    }
    if (type === 'group') {
      dispatch(
        actions.ui.media.prepareToSendBlast({
          file: undefined,
        })
      );
    }
  };

  useEffect(() => {
    if (currentBlastMessage !== '' || file || blastFile) {
      if ((type === 'main' && file) || currentBlastMessage) {
        setIsDisable(false);
      }
      if ((type === 'group' && blastFile) || currentBlastMessage) {
        setIsDisable(false);
      }
    } else {
      setIsDisable(true);
    }
  }, [currentBlastMessage, file, blastFile]);

  useEffect(() => {
    setPercent(loaded !== total ? Math.round((loaded / total) * 100) : 0);
  }, [loaded, total]);

  return (
    <>
      <form
        tabIndex={0}
        // className={
        //   status === 'OFFLINE' && chatType === 'P2P'
        //     ? styles.disabled
        //     : styles.container
        // }
        className={styles.container}
        onMouseEnter={onHovered}
        onMouseLeave={onHovered}
      >
        <div className={styles.fieldchat}>
          <div className={styles.chattools}>
            {/* this block hidden in css */}
            <div className={styles.tool}>
              {chat?.chatType.code === 'P2P' ||
              chat?.chatType.code === 'DEAL' ? (
                <LinkText
                  text="TKT"
                  isLeftIcon={false}
                  isRightIcon={false}
                  color={color}
                  underline="none"
                  onClick={openModal}
                />
              ) : null}
              {/* <LinkText
                text="РТТ"
                isLeftIcon={false}
                isRightIcon={false}
                color={color}
              />
              <LinkText
                text="СРГ"
                isLeftIcon={false}
                isRightIcon={false}
                color={color}
              />
              <LinkText
                text="ОПП"
                isLeftIcon={false}
                isRightIcon={false}
                color={color}
              />
              <IconButton icon="DEAL" color={color} /> */}
            </div>
            {/* {isSettingsText && (
            <div className={styles.textsettings}>
              <IconButton icon="bold" onClick={onClick} />
              <IconButton icon="italic" onClick={onClick} />
              <IconButton icon="strikethtough" onClick={onClick} />
              <Divider
                className={styles.devider}
                orientation="vertical"
                variant="middle"
                flexItem
              />
              <IconButton icon="listbullet" onClick={onClick} />
              <IconButton icon="listnumbered" onClick={onClick} />
            </div>
          )} */}
          </div>
          <div className={styles.chatcontent}>
            {percent !== 0 ? (
              <div>
                <LinearProgress variant="determinate" value={percent} />
              </div>
            ) : (
              <div className={styles.upload} />
            )}
            {file && type === 'main' && (
              <FileInfo
                fileName={file.name}
                fileSize={formatBytes(file.size)}
                onClick={clearFileFromStore}
                prepared={true}
              />
            )}
            {blastFile && type === 'group' && (
              <FileInfo
                fileName={blastFile.name}
                fileSize={formatBytes(blastFile.size)}
                onClick={clearFileFromStore}
                prepared={true}
              />
            )}
            <UploadPannel
              type="drop"
              chatId={chatId}
              typeFooter={type}
              toggleEditMode={toggleEditMode}
              setAlertType={setAlertType}
              setAlertMessage={setAlertMessage}
              setVisible={setVisible}
              activeChatId={activeChatId}
            >
              <TextareaAutosize
                // style={
                //   !!(status === 'OFFLINE' && chatType === 'P2P')
                //     ? {
                //         cursor: 'not-allowed',
                //       }
                //     : undefined
                // }
                minRows={4}
                maxRows={10}
                className={
                  type === 'main' ? styles.areacont : styles.areacontgroup
                }
                placeholder="Введите сообщение…"
                onBlur={() => setIsAreaFocused(false)}
                onFocus={() => setIsAreaFocused(true)}
                onChange={
                  blast
                    ? // ? (e) => setMessage(e.target.value)
                      changeBlastCurrentMessage
                    : changeCurrentMessage
                }
                value={blast ? currentBlastMessage : currentMessage}
                onKeyDown={onKeyDown}
                // disabled={!!(status === 'OFFLINE' && chatType === 'P2P')}
              />
            </UploadPannel>
          </div>
          <div className={styles.toolsone}>
            <div className={styles.tooltwo}>
              {/* {/* <IconButton icon="add" onClick={onClick} />
            <Divider
              className={styles.devider}
              orientation="vertical"
              variant="middle"
              flexItem
            />
            <IconButton icon="videocam" onClick={onClick} />
            <IconButton icon="microphone" onClick={onClick} />
            <Divider
              className={styles.devider}
              orientation="vertical"
              variant="middle"
              flexItem
            /> */}
              {type === 'main' && (
                <Bell
                  activeChatId={activeChatId}
                  // disabled={!!(status === 'OFFLINE' && chatType === 'P2P')}
                />
              )}

              {(type === 'main' || type === 'group') && (
                <UploadPannel
                  type="click"
                  chatId={chatId}
                  typeFooter={type}
                  toggleEditMode={toggleEditMode}
                  setAlertType={setAlertType}
                  setAlertMessage={setAlertMessage}
                  setVisible={setVisible}
                  // disabled={!!(status === 'OFFLINE' && chatType === 'P2P')}
                  activeChatId={activeChatId}
                />
              )}
              {/* <IconButton icon="sentsatisf" onClick={onClick} /> */}
              {/* <IconButton
              icon="textfield"
              onClick={onTextSettingsClick}
              color={isSettingsText ? 'white' : '#6D7385'}
            /> */}
            </div>
            {!blast && (
              <IconButton
                icon="send"
                onClick={submitMessage}
                color={!isDisable ? focusedColor : '#6D7385'}
                // disabled={!!(status === 'OFFLINE' && chatType === 'P2P')}
              />
            )}
            {blast && (
              <Button size="s" onClick={submitMessage} disabled={isDisable}>
                Бласт
              </Button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};
