import { combineReducers } from 'redux';

import { State as StateAccess, access } from 'src/reducers/access';
import { State as StateBlotter, blotter } from 'src/reducers/blotter';
import { State as StateChat, chat } from 'src/reducers/chat';
import { State as StateContacts, contacts } from 'src/reducers/contacts';
import { State as StateMedia, media } from 'src/reducers/media';
import { State as StateMonitor, monitor } from 'src/reducers/monitor';

export interface ReduxState {
  access: StateAccess;
  chat: StateChat;
  contacts: StateContacts;
  media: StateMedia;
  monitor: StateMonitor;
  blotter: StateBlotter;
}

export const initialState = {
  access: access.initialState,
  chat: chat.initialState,
  contacts: contacts.initialState,
  media: media.initialState,
  monitor: monitor.initialState,
  blotter: blotter.initialState,
};

export const rootReducer = combineReducers<ReduxState>({
  access: access.reducer,
  chat: chat.reducer,
  contacts: contacts.reducer,
  media: media.reducer,
  monitor: monitor.reducer,
  blotter: blotter.reducer,
});
