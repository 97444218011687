import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { Portal } from 'src/components/atoms/Portal';
import { Ticket } from 'src/components/organisms/modals/Ticket';
import { TicketNotification } from 'src/components/organisms/modals/TicketNotification';
import { ReduxState } from 'src/reducers';
import { ModalPlaces, Ticket as TypeTicket } from 'src/types';

type ModalState = {
  isActiveTicket: boolean;
  setIsActiveTicket?: Dispatch<SetStateAction<boolean>>;
  isActiveNotification: boolean;
  setIsActiveNotification?: Dispatch<SetStateAction<boolean>>;
  setModalPlace?: Dispatch<SetStateAction<ModalPlaces>>;
  modalPlace?: ModalPlaces;
  defaultNotification?: Partial<TypeTicket>[] | null;
  setDefaultNotification?: Dispatch<
    SetStateAction<Partial<TypeTicket>[] | null>
  >;
  defaulValue?: Partial<TypeTicket> | null;
  setDefaultValue?: Dispatch<SetStateAction<Partial<TypeTicket> | null>>;
  clearDefaulValue?: () => void;
  clearDefaultNotification?: () => void;
};

type Props = {
  children: ReactNode;
};

export const ModalStateContext = createContext<ModalState>({
  isActiveTicket: false,
  isActiveNotification: false,
  defaultNotification: null,
});

export const ModalProvider: React.FC<Props> = ({ children }) => {
  const { ticketNotifications } = useSelector((state: ReduxState) => ({
    ticketNotifications: state.blotter.ticketNotifications,
  }));
  const [isActiveTicket, setIsActiveTicket] = useState<boolean>(false);
  const [isActiveNotification, setIsActiveNotification] =
    useState<boolean>(false);
  const [defaultNotification, setDefaultNotification] = useState<
    Partial<TypeTicket>[] | null
  >(null);
  const [modalPlace, setModalPlace] = useState<ModalPlaces>('chat');
  const [defaulValue, setDefaultValue] = useState<Partial<TypeTicket> | null>(
    null
  );

  const clearDefaulValue = () => setDefaultValue(null);
  const clearDefaultNotification = () => setDefaultNotification(null);

  useEffect(() => {
    if (ticketNotifications?.length) {
      setIsActiveNotification?.(true);
      setModalPlace?.('chat');
      setDefaultNotification?.(ticketNotifications);
    }
  }, [ticketNotifications]);

  return (
    <ModalStateContext.Provider
      value={{
        isActiveTicket,
        isActiveNotification,
        setIsActiveTicket,
        setIsActiveNotification,
        setModalPlace,
        modalPlace,
        defaulValue,
        setDefaultValue,
        clearDefaulValue,
        defaultNotification,
        setDefaultNotification,
        clearDefaultNotification,
      }}
    >
      {children}
      {isActiveTicket && (
        <Portal nodeId={modalPlace}>
          <Ticket defaulValue={defaulValue} />
        </Portal>
      )}
      {isActiveNotification && defaultNotification && (
        <>
          {defaultNotification.map((ticket, index) => (
            <Portal nodeId={modalPlace} order={index} key={ticket.id}>
              <TicketNotification defaultValue={ticket as TypeTicket} />
            </Portal>
          ))}
        </>
      )}
    </ModalStateContext.Provider>
  );
};
