import { AccordionPeople } from 'src/components/molecules/AccordionPeople';
import { GroupChatPeopleSection } from 'src/components/molecules/GroupChatPeopleSection';
import { DropItem } from 'src/components/organisms/DropItemList';
import { ID, User } from 'src/types';

type Props = {
  people: User[];
  showUserInfo?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string
  ) => void;
  currentUserId?: ID;
  isShown: boolean;
  dropItemsHeader: DropItem[];
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>;
  isChatInfo?: boolean;
  title: string;
  handleMembers?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string
  ) => void;
  dropItemsForCurrentUser?: DropItem[];
};

export const SectionEditingGroupChat: React.FC<Props> = ({
  people,
  showUserInfo,
  currentUserId,
  isShown,
  dropItemsHeader,
  setIsShown,
  isChatInfo,
  title,
  handleMembers,
  dropItemsForCurrentUser,
}) => {
  return (
    <AccordionPeople
      people={people}
      totalItems={people.length}
      isActive={true}
      title={title}
    >
      <GroupChatPeopleSection
        contacts={people}
        showUserInfo={showUserInfo}
        currentUserId={currentUserId}
        isShown={isShown}
        dropItemsHeader={dropItemsHeader}
        setIsShown={setIsShown}
        isChatInfo={isChatInfo}
        handleMembers={handleMembers}
        dropItemsForCurrentUser={dropItemsForCurrentUser}
      />
    </AccordionPeople>
  );
};
