import classNames from 'classnames';
import React, { useRef, useState } from 'react';

import { IconButton } from 'src/components/atoms/IconButton';
import { Param } from 'src/components/organisms/SearchMessageMainPanel';

import styles from 'src/components/molecules/Chip/styles.module.css';

export type Props = {
  tag: Param;
  endIconAction: (event: React.MouseEvent<HTMLElement>) => void;
};

export const Chip: React.FC<Props> = ({ tag, endIconAction }) => {
  const [isEnabled, setIsEnabled] = useState(false);

  const divRef = useRef<HTMLDivElement>(null);
  const spanRef = useRef<HTMLSpanElement>(null);

  const chipClass = classNames(styles.wrapper, { [styles.enabled]: isEnabled });

  const handleEnable = (e: React.MouseEvent<HTMLElement>) => {
    if (e.target === divRef.current || e.target === spanRef.current) {
      setIsEnabled((prevState) => !prevState);
    }
  };

  const getRightIcon = () => {
    switch (tag.type) {
      case 'chat':
        return 'PEOPLE';
      case 'contact':
        return 'P2P';
      case 'tool':
        return 'ruble';
      default:
        return 'none';
    }
  };

  return (
    <div
      role="button"
      ref={divRef}
      onClick={handleEnable}
      className={chipClass}
    >
      {tag.type && tag.type !== 'queryString' && (
        <IconButton icon={getRightIcon()} size="s" color="#EDEEF2" />
      )}

      <span ref={spanRef} className={styles.text}>
        {tag.name}
      </span>

      <IconButton icon="close" onClick={endIconAction} size="s" />
    </div>
  );
};
