/* eslint-disable @typescript-eslint/no-explicit-any */
import { compile } from 'path-to-regexp';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';

import { actions } from 'src/actions';
import { ERROR_401, ERROR_432 } from 'src/constants';
import { ReduxState } from 'src/reducers';
import { AuthRecord } from 'src/types';
import { registerAsyncActions } from 'src/utils/factories';
import { makeAPIUrl, makeFormData } from 'src/utils/lib';
import { store } from 'src/utils/store';

const { events, eventsToActionCreators } = registerAsyncActions(actions.api);

function* ajaxGenerator(action: Action<any>): Generator {
  const actionCreator = eventsToActionCreators[action.type];
  const params = action.payload;

  const patchedParams = { ...params, extra: undefined };

  const isFileUpload =
    actionCreator.options &&
    actionCreator.options.headers &&
    actionCreator.options.headers['Content-Type'] === 'multipart/form-data';

  const authRecord = (yield select(
    (state: ReduxState) => state.access.authRecord
  )) as AuthRecord | null;

  try {
    const response: any = yield call(
      actionCreator.handler,
      isFileUpload ? makeFormData(patchedParams) : patchedParams,
      {
        baseURL: makeAPIUrl('https', actionCreator.options.extra.service),
        url: compile(actionCreator.options.url)(params?.extra),
        headers: {
          localization: 'BY', // TODO: detect lang code
          ...(authRecord
            ? { Authorization: `Bearer ${authRecord.accessToken}` }
            : {}),
        },
        onUploadProgress: isFileUpload
          ? (progressEvent) => {
              store.dispatch(
                actions.ui.media.progress({
                  total: progressEvent.total,
                  loaded: progressEvent.loaded,
                })
              );
            }
          : undefined,
      }
    );

    yield put(
      actionCreator.done({
        params,
        result: response.data,
      })
    );
  } catch (rawError: any) {
    const apiError =
      rawError?.response?.data &&
      (rawError.response.data?.error || rawError.response.data?.message);

    const error = apiError || 'Unknown Error';

    yield put(actions.ui.chat.updateErrorMessage({ message: apiError }));

    yield put(actionCreator.failed({ params, error }));

    if (apiError === ERROR_401 || apiError === ERROR_432) {
      yield put(actions.ui.access.signOut());
    }
  }
}

export function* ajaxSaga(): Generator {
  yield takeEvery(events, ajaxGenerator);
}
