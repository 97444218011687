import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { Button } from 'src/components/atoms/Button';
import { List } from 'src/components/organisms/List';
import { BlotterColumn, SortType, Ticket } from 'src/types';

import styles from 'src/components/organisms/BlotterFilterHeader/styles.module.css';

import { ReduxState } from '../../../reducers';

export type Props = {
  columns: BlotterColumn[];
  toggleFilter: (value: boolean) => void;
}; //TODO: edit when will be received correct data

export const BlotterFilterHeader: FC<Props> = ({ columns, toggleFilter }) => {
  const { sortField, sortType } = useSelector(
    (state: ReduxState) => state.blotter
  );
  const dispatch = useDispatch();

  const [columnList, setColumnList] = useState(columns);
  const [checkedItems, setChekedItems] = useState<BlotterColumn[]>(
    columns.filter((item) => item.isChecked)
  );
  const [sortedField, setSortedField] = useState<{
    sortField: keyof Ticket;
    sortType: SortType;
  }>({ sortField, sortType });

  const handleApply = () => {
    const checkedIndex = checkedItems.map((item) => item.key);
    const updateList = columnList.map((item) => ({
      ...item,
      isChecked: checkedIndex.includes(item.key),
      isSort:
        sortedField.sortField === item.name ? sortedField.sortType : undefined,
    }));
    dispatch(actions.ui.blotter.changeSort({ ...sortedField }));
    dispatch(actions.ui.blotter.changeColumns({ columns: updateList }));
    toggleFilter(false);
  };

  const handleCancel = () => {
    toggleFilter(false);
  };

  const handleReset = () => {
    dispatch(actions.ui.blotter.resetColumns());
    toggleFilter(false);
  };

  useEffect(() => {
    document.addEventListener('click', (e) => {
      if (
        e.target instanceof HTMLElement &&
        !e.target.closest(`.${styles.wrapper}`)
      ) {
        toggleFilter(false);
      }
    });
  }, [toggleFilter]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.list}>
        <List
          columnList={columnList}
          setColumnList={setColumnList}
          isSettingsMode
          setChekedItems={setChekedItems}
          chekedItems={checkedItems}
          setSortedField={setSortedField}
          sortedField={sortedField}
        />
      </div>
      <div className={styles.footer}>
        <div className={styles.start}>
          <Button size="m" variant="text" onClick={handleReset}>
            Сбросить
          </Button>
        </div>
        <div className={styles.end}>
          <Button size="m" variant="text" onClick={handleCancel}>
            Отмена
          </Button>
          <Button size="m" onClick={handleApply}>
            Применить
          </Button>
        </div>
      </div>
    </div>
  );
};
