import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { Chat } from 'src/types';

import styles from 'src/components/atoms/Checkbox/styles.module.css';

export type Props = {
  disabled?: boolean;
  checked?: boolean;
  id?: string;
  label?: React.ReactNode;
  icon?: 'check' | 'minus';
  toggleSelectedLine?: () => void;
  isParentAccordeon?: boolean;
  chatsFromAccordeon?: Chat['chatId'][];
  handleSelectedChatFromArray?: (arrayOfIds?: Chat['chatId'][]) => void;
};

export const Checkbox: React.FC<Props> = ({
  disabled = false,
  id,
  label,
  icon = 'check',
  toggleSelectedLine,
  isParentAccordeon,
  checked = false,
  chatsFromAccordeon,
  handleSelectedChatFromArray,
}) => {
  const [isChecked, setChecked] = useState<boolean | undefined>(false);
  const inputClass = classNames(styles.input, styles[icon]);

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  useEffect(() => {
    if (
      (isParentAccordeon && isChecked) ||
      (!isParentAccordeon && !isChecked)
    ) {
      return;
    }
    setChecked(isParentAccordeon);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isParentAccordeon]);

  const toggleCheckbox = () => {
    setChecked((prevState) => !prevState);
    if (toggleSelectedLine) {
      toggleSelectedLine();
    }
    if (handleSelectedChatFromArray) {
      handleSelectedChatFromArray(isChecked ? [] : chatsFromAccordeon);
    }
  };

  return (
    <div className={styles.wrapper}>
      <input
        id={id}
        type="Checkbox"
        disabled={disabled}
        checked={isChecked}
        onChange={toggleCheckbox}
        className={inputClass}
      />
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
    </div>
  );
};
