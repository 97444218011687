import dayjs from 'dayjs';
import { all, put, select, takeEvery } from 'redux-saga/effects';

import { actions } from 'src/actions';
import { ReduxState } from 'src/reducers';
import { AuthRecord } from 'src/types';
import { normalizeDateForSearch } from 'src/utils/date';

function* startGenerator() {
  const authRecord: AuthRecord | null = yield select(
    (state: ReduxState) => state.access.authRecord
  );

  if (authRecord) {
    yield all([
      put(
        actions.api.chat.groupsList.started({
          userId: authRecord.userId,
        })
      ),
    ]);
    yield put(
      actions.api.blotter.settings.started({
        timezone: dayjs().utcOffset().toString(),
      })
    );

    yield put(
      actions.api.blotter.contact.started({
        id: authRecord.userId,
        extra: { isUser: true },
      })
    );

    yield put(actions.api.blotter.organization.started());
    yield put(actions.api.blotter.contact.started());
    yield put(actions.api.dictionaries.currencyPair.started());

    yield put(
      actions.ui.blotter.changeFilter({
        filterField: 'date',
        filterValue: [
          normalizeDateForSearch(dayjs().toString(), 'toBack', false, true),
          normalizeDateForSearch(dayjs().toString(), 'toBack', true, true),
        ],
        type: 'date',
      })
    );
    yield put(
      actions.ui.blotter.changeFilter({
        filterField: 'traderTickets',
        filterValue: authRecord.userId,
        type: 'name',
      })
    );

    yield fetchDictionaries();
  }
}

function* fetchDictionaries() {
  yield put(
    actions.api.dictionaries.activeClass.started({
      withIncludes: false,
    })
  );
  yield put(
    actions.api.dictionaries.instrument.started({
      withIncludes: false,
    })
  );
  yield put(
    actions.api.dictionaries.calculationBase.started({
      withIncludes: false,
    })
  );
  yield put(
    actions.api.dictionaries.currency.started({
      withIncludes: false,
    })
  );
  yield put(
    actions.api.dictionaries.transactionStatus.started({
      withIncludes: false,
    })
  );
  yield put(
    actions.api.dictionaries.transactionDirection.started({
      withIncludes: false,
    })
  );
  yield put(
    actions.api.dictionaries.tenor.started({
      withIncludes: false,
    })
  );
}

function* setBlotterColumns() {
  const { blotterColumns } = yield select((state: ReduxState) => state.blotter);
  yield put(
    actions.api.blotter.settings.started({
      filter: blotterColumns,
    })
  );
}

export function* blotterSaga() {
  yield takeEvery(
    [actions.api.access.signUp.done.type, actions.api.access.signIn.done.type],
    startGenerator
  );
  yield takeEvery(
    [actions.ui.blotter.changeColumns, actions.ui.blotter.resetColumns],
    setBlotterColumns
  );
}
