import {
  API_AUTH_HOST,
  API_CHAT_HOST,
  API_MEDIA_HOST,
  API_MONITOR_HOST,
  PUBLIC_ENV,
  Service,
} from 'src/constants/env';

const serviceToHost: Record<Service, string> = {
  auth: API_AUTH_HOST,
  chat: API_CHAT_HOST,
  media: API_MEDIA_HOST,
  monitor: API_MONITOR_HOST,
};

export const makeAPIUrl = (protocol: 'https' | 'wss', service: Service) => {
  // const API_PROTOCOL =
  //   PUBLIC_ENV === 'local' ? protocol.slice(0, -1) : protocol;
  const API_PROTOCOL = !['production', 'beta', 'alpha'].includes(PUBLIC_ENV)
    ? protocol.slice(0, -1)
    : protocol;

  return `${API_PROTOCOL}://${serviceToHost[service]}`;
};

export const makeFormData = (params: Record<string, string>): FormData => {
  const formData = new FormData();
  const keys = Object.keys(params);

  keys.forEach((key) => {
    const value = params[key];

    if (value !== undefined) {
      formData.append(
        key,
        Array.isArray(value) || (typeof value === 'object' && key !== 'file')
          ? JSON.stringify(value)
          : value
      );
    }
  });

  return formData;
};

export const isHeadless = (() => {
  try {
    return window.self !== window.top;
  } catch (e) {
    // ignore
  }

  return false;
})();
