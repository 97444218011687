import { Close } from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';

import { Chip } from 'src/components/molecules/Chip';
import { Param } from 'src/components/organisms/SearchMessageMainPanel';
import { GlobalSearchRequest } from 'src/types';

import styles from 'src/components/molecules/Input/styles.module.css';

export type Props = {
  autoFocus: boolean;
  message: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLFormElement>) => void;
  setMessage: (value: React.SetStateAction<string>) => void;
  closeSearch?: () => void;
  setTagsOut?: (param: Param[]) => void;
  setIsSearch?: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  setAnchorEl?: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  setIsSearchPanelShow?: React.Dispatch<React.SetStateAction<boolean>>;
  isChips?: boolean;
  chipWithIcon?: Param;
  andOrOr?: {
    title: string;
    type: GlobalSearchRequest['operator'];
  };
  setEndOrOr?: React.Dispatch<
    React.SetStateAction<{
      title: string;
      type: GlobalSearchRequest['operator'];
    }>
  >;
};

export const Input: React.FC<Props> = ({
  autoFocus,
  setMessage,
  closeSearch,
  message,
  setTagsOut,
  setIsSearch,
  isChips = false,
  chipWithIcon,
  andOrOr = { title: 'ИЛИ', type: 'or' },
  setEndOrOr,
}) => {
  const [tags, setTags] = useState<Param[]>([]);

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && isChips) {
      event.preventDefault();
      const newTagTitle = (event.target as HTMLInputElement).value.trim();
      if (!newTagTitle.length) {
        return;
      }
      const isExist = tags.find((tag: Param) => tag.name === newTagTitle);
      if (!isExist) {
        setTags([...tags, { name: newTagTitle, type: 'queryString' }]);
        setMessage('');
      }
    } else if (event.key === 'Enter') {
      event.preventDefault();
    }
  };
  const handleCloseSearch = () => {
    setTags([]);
    setMessage('');
    setIsSearch?.(false);
    if (closeSearch) {
      closeSearch();
    }
  };
  const handleDelete = (tagTitle: string) => {
    setTags((prevState) => prevState.filter((tag) => tag.name !== tagTitle));
  };

  const handleClick = () => {
    setEndOrOr?.((prevState) =>
      prevState.title === 'ИЛИ'
        ? { title: 'И', type: 'and' }
        : { title: 'ИЛИ', type: 'or' }
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage?.(event.target.value);
  };

  useEffect(() => {
    if (setTagsOut) setTagsOut(tags);
  }, [tags]);

  useEffect(() => {
    if (chipWithIcon && Object.keys(chipWithIcon).length) {
      const isExist = tags.find((tag: Param) => tag.name === chipWithIcon.name);
      if (!isExist) {
        setTags([...tags, chipWithIcon]);
        setMessage('');
      }
    }
  }, [chipWithIcon]);

  return (
    <div className={styles.wrapper}>
      <TextField
        sx={{
          '& fieldset': { border: 'none' },
        }}
        type="text"
        autoComplete="off"
        placeholder={
          tags.length
            ? undefined
            : isChips
            ? 'Имя, сообщение, сделка…'
            : 'Поиск...'
        }
        className={styles.input}
        size="small"
        autoFocus={autoFocus}
        fullWidth
        onChange={handleChange}
        onKeyDown={onKeyDown}
        value={message}
        InputProps={{
          startAdornment: tags.map((tag) => (
            <Chip
              endIconAction={() => handleDelete(tag.name ?? '')}
              tag={tag}
              key={tag.name}
            />
          )),
          endAdornment: isChips ? (
            <div
              className={styles.type}
              onClick={handleClick}
              role="presentation"
            >
              {andOrOr?.title}
            </div>
          ) : (
            <Close onClick={handleCloseSearch} className={styles.close} />
          ),
        }}
      />
    </div>
  );
};
