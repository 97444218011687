import React from 'react';

/*import { useDispatch } from 'react-redux';

import { actions } from 'src/actions';
import { IconButton } from 'src/components/atoms/IconButton';*/
import { normalizeDate } from 'src/utils/date';

import styles from 'src/components/molecules/Divider/styles.module.css';

export type Props = {
  date: string;
  activeChatId: string;
  isLastMessage: boolean;
  messageCreateAt: string;
};

export const Divider: React.FC<Props> = ({
  date,
  /*activeChatId,
  isLastMessage,
  messageCreateAt,*/
}) => {
  /*const dispatch = useDispatch();

  const getHistory = () => {
    dispatch(
      actions.api.chat.messagesList.started({
        chat: [activeChatId],
        dateBeetwen: {
          startedDate: DATE_NOW,
          endDate: subtractOneDay(messageCreateAt),
        },
      })
    );
  };*/
  return (
    <div className={styles.content}>
      {normalizeDate(date, 'short')}
      {/*isLastMessage && (
        <div className={styles.tools}>
          <IconButton icon="history" size="m" onClick={getHistory} />
        </div>
      )*/}
    </div>
  );
};
