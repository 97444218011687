import React, { createContext, useState } from 'react';

import { ChatContactsPanel } from 'src/components/organisms/ChatContactsPanel';
import { ChatHeader } from 'src/components/organisms/ChatHeader';
import { TypeDialogPanel } from 'src/components/organisms/TypeDialogPanel';
import { Chat, ChatGroup, ChatInstance as ChatInstanceProps } from 'src/types';

import styles from 'src/components/organisms/ChatInstance/styles.module.css';

export type Props = ChatInstanceProps;
export type Position = {
  x: number;
  y: number;
};

export type ChatInstanceDataProps = {
  activeGroupId?: ChatGroup['chatGroupId'];
  activeChatId?: Chat['chatId'];
};

export const ChatInstanceData = createContext<ChatInstanceDataProps>({});
export type TypeDialogPanelProp = 'chat' | 'infoAboutUser' | 'infoAboutChat';

export const ChatInstance: React.FC<Props> = ({
  id,
  panels,
  activeGroupId,
  activeChatId,
}) => {
  const [typePanel, setTypePanel] = useState<TypeDialogPanelProp>('chat');
  const [isShownChatMenu, setIsShownChatMenu] = useState(false);
  const [position, setPosition] = useState<Position>({ x: 0, y: 30 });
  const showContextChatMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsShownChatMenu(false);
    const newPosition = {
      x: e.pageX,
      y: e.pageY,
    };
    setPosition(newPosition);
    setIsShownChatMenu(true);
  };
  const [isShownFolderMenu, setIsShownFolderMenu] = useState(false);
  const showContextFolderMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const newPosition = {
      x: e.pageX,
      y: e.pageY,
    };
    setPosition(newPosition);
    setIsShownFolderMenu(true);
  };

  return (
    <div id={id} className={styles.root}>
      <ChatInstanceData.Provider value={{ activeChatId, activeGroupId }}>
        <ChatHeader
          instanceId={id}
          activeGroupId={activeGroupId}
          showContextMenu={showContextFolderMenu}
        />

        <div className={styles.body}>
          {panels.includes('contacts') && (
            <ChatContactsPanel
              setIsShownChatMenu={setIsShownChatMenu}
              instanceId={id}
              activeGroupId={activeGroupId}
              activeChatId={activeChatId}
              isShown={isShownFolderMenu}
              setIsShown={setIsShownFolderMenu}
              showContextMenu={showContextChatMenu}
              position={position}
              setPosition={setPosition}
              setTypePanel={setTypePanel}
            />
          )}
          {panels.includes('dialog') && (
            <TypeDialogPanel
              activeChatId={activeChatId}
              activeGroupId={activeGroupId}
              isShown={isShownChatMenu}
              setIsShown={setIsShownChatMenu}
              position={position}
              setPosition={setPosition}
              typePanel={typePanel}
              setTypePanel={setTypePanel}
            />
          )}
        </div>
      </ChatInstanceData.Provider>
    </div>
  );
};
