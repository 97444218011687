import React, { Dispatch, SetStateAction } from 'react';

import { Chat } from 'src/types';

import styles from 'src/components/atoms/Select/styles.module.css';

export type chatType = {
  title: string;
  type?: Chat['chatType']['code'];
};

type Props = {
  type: chatType;
  setType: Dispatch<SetStateAction<chatType>>;
};

export const chatTypes: chatType[] = [
  { title: 'Все', type: undefined },
  { title: 'Контакты', type: 'P2P' },
  { title: 'Групповые чаты', type: 'PEOPLE' },
  { title: 'Инструменты', type: 'DEAL' },
];

export const SelectType: React.FC<Props> = ({ setType }) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = event;
    setType(chatTypes.find((type) => type.title === value) as chatType);
  };

  return (
    <div>
      <select name="select" className={styles.select} onChange={handleChange}>
        {chatTypes.map((type) => (
          <option className={styles.option} key={type.title} value={type.title}>
            {type.title}
          </option>
        ))}
      </select>
    </div>
  );
};
