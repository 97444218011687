import { Ticket } from 'src/types';

export const CLASSES = { DP: 'DP', FX: 'FX' };
export const INSTRUMENTS = {
  SPOT: 'SPOT',
  FORWARD: 'FORWARD',
  SWAP: 'SWAP',
  CREDIT: 'CREDIT',
};
export const DIRECTIONS = {
  BUY: 'BUY',
  SELL: 'SELL',
  LEND: 'LEND',
  BORROW: 'BORROW',
};
export const LEGS = { SHORTLEG: 'shortLeg', LONGLEG: 'longLeg' };

export const ticketInfo: Ticket = {
  id: '12sqawd',
  activeClassId: '12sqawd',
  activeClass: 'FX', // Класс актива
  activeClassDescription: 'Валюты',
  instrumentId: '12sqawd',
  instrument: 'Спот', // Инструмент
  instrumentDescription: 'Спот',
  transactionDirectionId: '12sqawd',
  transactionDirection: 'Продажа', // Направление сделки,
  transactionDirectionDescription: 'Покупка',
  currencyPairId: '12sqawd',
  currencyPair: 'CNY/RUB', // Валютная пара
  dealStatus: 'Отправлена',
  firstCurrencyId: '12sqawd',
  firstCurrency: 'CNY', // Первая валюта сделки
  secondCurrencyId: '12sqawd',
  secondCurrency: 'RUB', // Вторая валюта сделки
  transactionVolume: '100 000', // Объем сделки
  valueDate: '01.12.2022', // Дата валютирования
  fullPrice: '9.893245', // Полная цена
  swapPoints: '0.34', // Своп-поинты
  creditSum: '100 000 000', // Сумма кредита
  dateFrom: '01.12.2022', // Дата валютирования по представлению кредита
  dateTo: '30.12.2022', // Дата валютирования по возврату кредита
  interestPaymentDate: '30.12.2022', // Даты уплаты процентов
  interestRate: '1.1', // Процентная ставка
  interestSum: '12.2', // Сумма процентов в валюте сделки (?)
  calculationBaseId: '12sqawd',
  // eslint-disable-next-line no-octal-escape
  calculationBase: 'АКТ/365', // База расчета
  createdAt: '30.12.2022', // Дата создания тикета
  date: '30.12.2022', // Дата заключени сделки(?)
  traderContactId: '12sqawd',
  traderContact: 'Третьяков Пётр', // Контактный лицо
  traderContactOrganizationId: '12sqawd',
  traderContactOrganization: 'Росбанк', // Организация контактного лица
  counterAgentTraderContactId: '12sqawd',
  counterAgentTraderContact: 'Сергей Иванов', // Контактное лицо контрагента
  counterAgentTraderContactOrganizationId: '12sqawd',
  counterAgentTraderContactOrganization: 'Райффайзенбанк', // Организация контактного лица контрагента
  ticketStatusId: '12sqawd',
  ticketStatus: 'Заключена', // Статус сделки
  ticketStatusDescription: 'Отменен',
  dealCode: 'NOTK', // Код сделки
  shortLegTransactionDirection: 'Продажа',
  shortLegTransactionDirectionId: '1298723987e',
  shortLegTransactionVolume: '100.000',
  shortLegInstrumentId: '92873423847283',
  shortLegInstrument: 'Спот',
  shortLegSpotPrice: '23947324823094',
  shortLegForwardPoints: '234.34',
  shortLegFullPrice: '9.893245',
  shortLegValueDate: '30.12.2022',
  transactionNumber: 'FX5966_2/1/2023',
  shortLegTenor: '2W',
  shortLegTenorId: '4907433d-b75b-4606-8fb7-9206b74878ff',
  shortLegInstrumentDescription: 'Спот',
  shortLegTransactionDirectionDescription: 'Покупка',
  longLegTransactionDirection: 'Покупка',
  longLegTransactionDirectionId: '2423432423432424',
  longLegTransactionVolume: '100.000',
  longLegInstrumentId: '23432432432432114',
  longLegInstrument: 'Форвард',
  longLegSpotPrice: '654.34',
  longLegForwardPoints: '234.34',
  longLegFullPrice: '7.893245',
  longLegValueDate: '06.01.2023',
  longLegTenor: '1M',
  longLegTenorId: 'acfb99af-d7af-40f8-96f2-9730e7e6a337',
  longLegInstrumentDescription: 'Форвард',
  longLegTransactionDirectionDescription: 'Продажа',
  forwardPoints: '19.89',
  spotPrice: '109.89',
};

export const notificationMessages = {
  acceptedRecipient: 'Получен тикет',
  declined: 'Контрагент отклонил Ваш Тикет',
  canceled:
    'Ваш Тикет был отменен, так как в течение 30 минут не получено подтверждение о принятии',
  acceptedSender: 'Контрагент принял Ваш Тикет',
};
