import React from 'react';
import { useDispatch } from 'react-redux';

import { actions } from 'src/actions';
import { IconButton } from 'src/components/atoms/IconButton';
import { ChatInstance } from 'src/types';

export type Props = {
  activeChatId: ChatInstance['activeChatId'];
  disabled?: boolean;
};

export const Bell: React.FC<Props> = ({ activeChatId, disabled }) => {
  const dispatch = useDispatch();

  const colorItem = getComputedStyle(document.documentElement)
    .getPropertyValue('--COLOR_OUTLINE')
    .trim();

  const onClick = () =>
    activeChatId &&
    dispatch(
      actions.ws.send({
        type: 'sendAlert',
        message: { room: [activeChatId] },
      })
    );

  return (
    <IconButton
      icon="bell"
      onClick={onClick}
      color={colorItem}
      disabled={disabled}
    />
  );
};
