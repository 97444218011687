import React from 'react';

import { IconType } from 'src/components/atoms/Icon';

// import { IconButton } from 'src/components/atoms/IconButton';
import styles from 'src/components/molecules/TicketSubHeader/styles.module.css';

export type Props = {
  title?: string;
  handleClose?: () => void;
  firstIcon?: IconType;
  secondIcon?: IconType;
  thirdIcon?: IconType;
  lastIcon?: IconType;
};

export const TicketSubHeader: React.FC<Props> = ({ title }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.start}>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.end}>
        {/* <IconButton icon="fileUpload" />
        <IconButton icon="print" />
        <IconButton icon="chatUnread" />
        <IconButton icon="morevert" /> */}
        {/* TODO uncomment after add functionality */}
      </div>
    </div>
  );
};
