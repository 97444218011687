import classNames from 'classnames';
import React, { useState } from 'react';

import { Icon } from 'src/components/atoms/Icon';
import { IconButton } from 'src/components/atoms/IconButton';
import { UserStatus } from 'src/components/molecules/UserStatus';
import { Position } from 'src/components/organisms/ChatInstance';
import { DropDownMenu } from 'src/components/organisms/DropDownMenu';
import { DropItem } from 'src/components/organisms/DropItemList';
import { ID, User } from 'src/types';
import { isIdContainsChecking } from 'src/utils/chat';

import styles from 'src/components/molecules/GroupChatContactsSection/styles.module.css';

export type Props = {
  contacts: User[];
  showUserInfo?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string
  ) => void;
  members?: User[];
  admins?: User[];
  currentUserId?: ID;
  handleMembers?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string,
    isDelete?: boolean
  ) => void;
  isChatInfo?: boolean;
  setIsShown?: React.Dispatch<React.SetStateAction<boolean>>;
  deleteFromGroup?: (id: ID) => void;
  isShown?: boolean;
  dropItemsHeader?: DropItem[];
};

export const GroupChatContactsSection: React.FC<Props> = ({
  contacts,
  members,
  admins,
  currentUserId,
  handleMembers,
  isChatInfo = false,
  setIsShown,
  deleteFromGroup,
  isShown = false,
  dropItemsHeader,
  showUserInfo,
}) => {
  const [position, setPosition] = useState<Position>({ x: 0, y: 0 });
  const openMenu = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    const newPosition = {
      x: e.pageX - 200,
      y: e.pageY + 15,
    };
    setPosition(newPosition);
    setIsShown?.((prevState) => !prevState);
  };

  return (
    <div className={styles.wrapperContact}>
      {contacts?.map((contact) => (
        <div
          role="presentation"
          key={contact.userId}
          className={classNames([
            { [styles.contactItem]: !isChatInfo },
            { [styles.contactChatInfoItem]: isChatInfo },
            {
              // [styles.isActive]: isActiveId === contact.userId,
            },
          ])}
          onClick={(e) => showUserInfo?.(e, contact.userId)}
        >
          <UserStatus status={contact?.status} />
          <p>{contact.name}</p>
          <div className={styles.contactItemOrg}>
            {/* <span>{contact.organizationName}</span> */}
          </div>
          <div
            role="presentation"
            key={contact.userId}
            className={styles.icon}
            onClick={(e) => handleMembers?.(e, contact.userId, true)}
          >
            {isChatInfo ? (
              <>
                <IconButton
                  icon="burger"
                  onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                    openMenu(e)
                  }
                />
                <IconButton
                  icon="close"
                  onClick={() => deleteFromGroup?.(contact.userId)}
                />
              </>
            ) : members && currentUserId !== contact.userId ? (
              <Icon
                icon={
                  isIdContainsChecking(members, contact.userId) ||
                  (admins && isIdContainsChecking(admins, contact.userId))
                    ? 'remove'
                    : 'add'
                }
                size="m"
                fill="#EDEEF2"
              />
            ) : currentUserId === contact.userId ? null : (
              <Icon icon="remove" fill="#EDEEF2" />
            )}
          </div>
          {isShown && (
            <DropDownMenu
              dropItemsHeader={dropItemsHeader ? dropItemsHeader : []}
              position={position}
            />
          )}
        </div>
      ))}
    </div>
  );
};
