import React, { useEffect, useState } from 'react';

import { IconButton } from 'src/components/atoms/IconButton';

import styles from 'src/components/organisms/SearchNavigator/styles.module.css';

export type Props = {
  data: HTMLElement[] | ((el: HTMLElement) => number);
};

export const SearchNavigator: React.FC<Props> = ({ data }) => {
  const [selectNum, setSelectNum] = useState(0);
  const [dataFrom, setDateFrom] = useState<
    HTMLElement[] | ((el: HTMLElement) => number)
  >();

  const preRemoveHighlight = (index: number) => {
    if (dataFrom) {
      if (dataFrom[0 as keyof typeof dataFrom] !== undefined) {
        removeHighlight(dataFrom[index as keyof typeof dataFrom]);
      }
    }
  };

  const removeHighlight = (element: HTMLElement) => {
    element.removeAttribute('style');
  };

  const dataSetToWalk = (index: number) => {
    if (dataFrom) {
      mouseSelectAndScrollImulation(dataFrom[index as keyof typeof dataFrom]);
    }
  };

  const mouseSelectAndScrollImulation = (element: HTMLElement) => {
    element.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
    });
    element.style.backgroundColor = '#516EC8';
  };
  const next = () => {
    if (dataFrom !== undefined) {
      if (selectNum < dataFrom.length - 1) {
        preRemoveHighlight(selectNum);
        setSelectNum(selectNum + 1);
      }
    }
  };

  const prev = () => {
    if (selectNum > 0) {
      preRemoveHighlight(selectNum);
      setSelectNum(selectNum - 1);
    }
  };

  useEffect(() => {
    if (dataFrom) {
      if (dataFrom[0 as keyof typeof dataFrom] !== undefined) {
        dataSetToWalk(0);
      }
    }
  }, [dataFrom]);

  useEffect(() => {
    setDateFrom(data);
    preRemoveHighlight(selectNum);
    setSelectNum(0);
  }, [data]);

  useEffect(() => {
    dataSetToWalk(selectNum);
  }, [selectNum]);

  return (
    <>
      {dataFrom?.length !== 0 && (
        <div className={styles.subtitle}>
          <p className={styles.number}>
            {selectNum + 1}/{dataFrom?.length}
          </p>
          <IconButton icon="arrowUp" color="#ADB0BC" size="m" onClick={prev} />
          <IconButton
            icon="arrowDown"
            color="#ADB0BC"
            size="m"
            onClick={next}
          />
        </div>
      )}
    </>
  );
};
