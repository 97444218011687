import Divider from '@mui/material/Divider';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Icon } from 'src/components/atoms/Icon';
import { SearchRow } from 'src/components/molecules/SearchRow';
import { TypeDialogPanelProp } from 'src/components/organisms/ChatInstance';
import { Param } from 'src/components/organisms/SearchMessageMainPanel';
import { ReduxState } from 'src/reducers';
import { searchResponse } from 'src/types';
import { getRightEnding } from 'src/utils/chat';

import styles from 'src/components/molecules/SearchGroup/styles.module.css';

export type Props = {
  message: string;
  closeSearch: () => void;
  setTypePanel?: (value: React.SetStateAction<TypeDialogPanelProp>) => void;
  addParamToGlobalSearch?: (param: Param) => void;
};

export const SearchGroup: React.FC<Props> = ({
  message,
  closeSearch,
  addParamToGlobalSearch,
}) => {
  const [data, setData] = useState<searchResponse>({
    chats: [],
    tools: [],
    contacts: [],
    result: true,
  });

  const dataSearch = useSelector((state: ReduxState) => {
    return state.chat.searchGroupData;
  });

  useEffect(() => {
    const sortChats = dataSearch.chats?.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
    const sortContacts = dataSearch.contacts?.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
    const sortTools = dataSearch.tools?.sort((a, b) =>
      a.code.toLowerCase() > b.code.toLowerCase() ? 1 : -1
    );
    setData({
      ...data,
      tools: sortTools,
      chats: sortChats,
      contacts: sortContacts,
    });
  }, [dataSearch]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.searchlist}>
          <div className={styles.content}>
            <div className={styles.main}>
              <div className={styles.block}>
                <div className={styles.header}>
                  <Icon icon="P2P" />
                  <p className={styles.text}>Контакты</p>
                </div>
                {data.contacts.map((item, index) => (
                  <SearchRow
                    key={item.id + index.toString()}
                    centerText={item.position}
                    filter={message}
                    downText={item.organization?.title}
                    firstText={item.name}
                    rightText=""
                    itemId={item.id}
                    closeSearch={closeSearch}
                    type="contact"
                    name={item.name}
                    addParamToGlobalSearch={addParamToGlobalSearch}
                  />
                ))}
              </div>
              <Divider className={styles.devider} variant="middle" />
              <div className={styles.block}>
                <div className={styles.header}>
                  <Icon icon="peopleAlt" />
                  <p className={styles.text}>Групповые чаты</p>
                </div>
                {data.chats.map((item, index) => (
                  <SearchRow
                    key={item.id + index.toString()}
                    centerText=""
                    filter={message}
                    downText={getRightEnding(item.membersCount)}
                    firstText={item.name}
                    rightText=""
                    itemId={item.id}
                    closeSearch={closeSearch}
                    type="chat"
                    addParamToGlobalSearch={addParamToGlobalSearch}
                    name={item.name}
                  />
                ))}
              </div>
              {addParamToGlobalSearch && (
                <>
                  <Divider className={styles.devider} variant="middle" />
                  <div className={styles.block}>
                    <div className={styles.header}>
                      <Icon icon="ruble" />
                      <p className={styles.text}>Инструменты</p>
                    </div>
                    {data.tools.map((item, index) => (
                      <SearchRow
                        key={item.code + index.toString()}
                        centerText=""
                        filter={message}
                        downText={item.code}
                        firstText={item.ticker ? item.ticker : ''}
                        rightText=""
                        itemId={item.code}
                        closeSearch={closeSearch}
                        type="tool"
                        name={item.code}
                        addParamToGlobalSearch={addParamToGlobalSearch}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
