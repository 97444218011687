import { fork, put, takeEvery } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';

import { actions } from 'src/actions';
import { Actions, AuthRecord } from 'src/types';
import { isHeadless } from 'src/utils/lib';
import { getItem } from 'src/utils/storage';

function* notHeadlessGenerator() {
  if (!isHeadless) {
    const authRecord = getItem<AuthRecord | null>('token', null);

    if (authRecord && authRecord.accessToken) {
      yield put(actions.iframe.signIn(authRecord));
    }
  }
}

function* initGenerator(action: Action<Actions.iframe.signIn>) {
  yield put(
    actions.api.access.signIn.done({
      params: {
        login: '',
        password: '',
        authMethod: 'LOGIN_PASSWORD',
      },
      result: action.payload,
    })
  );
}

function* signOutGenerator() {
  yield localStorage.removeItem('token');
  yield put(actions.ui.access.signOut());
}

export function* initSaga() {
  yield takeEvery(actions.iframe.signIn.type, initGenerator);
  yield takeEvery(actions.iframe.signOut.type, signOutGenerator);
  yield fork(notHeadlessGenerator);
}
