import { Socket, io } from 'socket.io-client';

import { Service } from 'src/constants/env';
import { makeAPIUrl } from 'src/utils/lib';

export class WebSocketConstructor {
  public socket: Socket | null;
  public connectionNumber: number;
  public url: string;
  public service: Service;

  constructor(service: Service) {
    this.service = service;
    this.socket = null;
    this.connectionNumber = 0;
    this.url = makeAPIUrl('wss', service);
  }

  public incConnectionNumber() {
    const n = this.connectionNumber;
    this.connectionNumber = n > 4 ? n : n + 1;
  }

  public resetConnectionNumber() {
    this.connectionNumber = 0;
  }

  public connect(token: string) {
    if (!this.socket) {
      this.socket = io(this.url, {
        auth: { token: `Bearer ${token}` },
        query: {
          target:
            this.service === 'chat' ? 'ChatController' : 'TicketController',
        },
        reconnectionDelayMax: 10000,
        transports: ['websocket'],
        withCredentials: true,
        upgrade: false, // Added for test flood requests
      });
    }

    return this.socket;
  }

  public reset() {
    this.socket = null;
  }

  public close() {
    if (this.socket) {
      this.socket.close();
    }
    this.reset();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public send(message: any) {
    if (this.socket) {
      this.socket.send(JSON.stringify(message));
    }
  }
}
