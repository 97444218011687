import React from 'react';

import { IconType } from 'src/components/atoms/Icon';
import { IconButton } from 'src/components/atoms/IconButton';

import styles from 'src/components/molecules/TicketHeader/styles.module.css';

export type Props = {
  title?: string;
  handleClose: () => void;
  leftIcon?: IconType;
};

export const TicketHeader: React.FC<Props> = ({
  title = 'Тикет',
  handleClose,
  leftIcon,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.start}>
        {leftIcon && (
          <IconButton icon={leftIcon} color="var(--COLOR_OUTLINE)" />
        )}
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.end}>
        {/* <IconButton icon="openInFull" /> */}
        <IconButton icon="close" onClick={handleClose} />
        {/* <IconButton icon="morevert" /> */}
      </div>
    </div>
  );
};
