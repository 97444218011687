import { CLASSES, INSTRUMENTS } from 'src/constants/blotter';
import { ID, ITicketFormValues } from 'src/types';
import { normalizeDate } from 'src/utils/date';

export const prepearFormData = (
  formData: ITicketFormValues,
  activeClass?: string,
  instrument?: string,
  userId?: ID
) => {
  if (activeClass === CLASSES.DP) {
    return {
      active_class_id: formData.class?.id,
      instrument_id: formData.instrument?.id,
      transaction_direction_id: formData.direction?.id,
      base_currency_id: formData.base_currency?.id,
      counter_currency_id: formData.counter_currency?.id,
      date_from: normalizeDate(
        formData.date_from?.toISOString() as never,
        'toBack',
        true
      ),
      date_to: normalizeDate(
        formData.date_to?.toISOString() as never,
        'toBack',
        true
      ),
      credit_sum: formData.credit_sum,
      interest_payment_date: formData.interest_payment_date,
      interest_rate: formData.interest_rate,
      calculation_base_id: formData.calculation_base?.id,
      trader_contact_id: userId,
      counter_agent_trader_id: formData.contact?.id,
    };
  }
  if (activeClass === CLASSES.FX && instrument === INSTRUMENTS.SPOT) {
    return {
      active_class_id: formData.class?.id,
      instrument_id: formData.instrument?.id,
      transaction_direction_id: formData.direction?.id,
      currency_pair_id: formData.currencyPair?.id,
      transaction_volume: formData.transactionVolume,
      value_date: normalizeDate(
        formData.valueDate.toISOString(),
        'toBack',
        true
      ),
      all_in_price: formData.fullPrice,
      trader_contact_id: userId,
      counter_agent_trader_id: formData.contact?.id,
    };
  }

  if (activeClass === CLASSES.FX && instrument === INSTRUMENTS.FORWARD) {
    return {
      active_class_id: formData.class?.id,
      instrument_id: formData.instrument?.id,
      transaction_direction_id: formData.direction?.id,
      currency_pair_id: formData.currencyPair?.id,
      transaction_volume: formData.transactionVolume,
      value_date: normalizeDate(
        formData.valueDate.toISOString(),
        'toBack',
        true
      ),
      all_in_price: formData.fullPrice,
      trader_contact_id: userId,
      counter_agent_trader_id: formData.contact?.id,
      spot_price: formData.spot_price,
      forward_points: formData.forward_points,
    };
  }

  if (activeClass === CLASSES.FX && instrument === INSTRUMENTS.SWAP) {
    return {
      active_class_id: formData.class?.id,
      instrument_id: formData.instrument?.id,
      // transaction_direction_id: formData.direction?.id,
      currency_pair_id: formData.currencyPair?.id,
      trader_contact_id: userId,
      counter_agent_trader_id: formData.contact?.id,
      swap_points: formData.swap_points,
      shortLeg: {
        instrument_id: formData.shortLeg?.instrument?.id,
        transaction_direction_id: formData.shortLeg?.transaction_direction?.id,
        tenor_id: formData.shortLeg?.tenor?.id,
        volume: formData.shortLeg?.volume,
        full_price: formData.shortLeg?.full_price,
        date_value: normalizeDate(
          formData.shortLeg!.date_value!.toISOString(),
          'toBack',
          true
        ),
        spot_price:
          formData.shortLeg?.instrument?.code === INSTRUMENTS.SPOT
            ? null
            : formData.shortLeg?.spot_price,
        forward_points:
          formData.shortLeg?.instrument?.code === INSTRUMENTS.SPOT
            ? null
            : formData.shortLeg?.forward_points,
      },
      longLeg: {
        instrument_id: formData.longLeg?.instrument?.id,
        transaction_direction_id: formData.longLeg?.transaction_direction?.id,
        tenor_id: formData.longLeg?.tenor?.id,
        volume: formData.longLeg?.volume,
        full_price: formData.longLeg?.full_price,
        date_value: normalizeDate(
          formData.longLeg!.date_value!.toISOString(),
          'toBack',
          true
        ),
        spot_price: formData.longLeg?.spot_price,
        forward_points: formData.longLeg?.forward_points,
      },
    };
  }
};
