import React from 'react';
import { UseFormRegister } from 'react-hook-form';

import { Icon, IconType } from 'src/components/atoms/Icon';
import { REGEXP } from 'src/constants/regexp';
import { ITicketFormValues } from 'src/types';

import styles from 'src/components/atoms/InputModal/styles.module.css';

export type Props = {
  icon?: IconType;
  onClick?: () => void;
  register?: UseFormRegister<ITicketFormValues>;
  name: keyof ITicketFormValues | string;
  label?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  regx?: RegExp;
};

export const InputModal: React.FC<Props> = ({
  icon,
  onClick,
  register,
  name,
  label,
  placeholder,
  onChange,
  regx = REGEXP.NUM2,
}) => {
  return (
    <label htmlFor={name} className={styles.wrapper}>
      <div className={styles.label}>{label}</div>
      <div className={styles.inputWrapper}>
        <input
          id={name}
          type="text"
          placeholder={placeholder}
          className={styles.input}
          {...register?.(name as keyof ITicketFormValues, {
            required: true,
            pattern: regx,
          })}
          onChange={(e) => onChange?.(e.target.value)}
        />
        <div className={styles.icon}>
          {icon && <Icon icon={icon} handleClick={() => onClick?.()} />}
        </div>
      </div>
    </label>
  );
};
