import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Link from '@mui/material/Link';
import React, { MouseEventHandler } from 'react';

import styles from 'src/components/atoms/LinkText/styles.module.css';

export type Props = {
  href?: string;
  underline?: 'always' | 'hover' | 'none';
  text: string;
  color?: string;
  isSubtitle?: boolean;
  isLeftIcon?: boolean;
  isRightIcon?: boolean;
  onClick?: () =>
    | void
    | MouseEventHandler<HTMLAnchorElement>
    | MouseEventHandler<HTMLSpanElement>;
};

export const LinkText: React.FC<Props> = ({
  href,
  underline = 'hover',
  text = 'Link',
  isLeftIcon = true,
  isRightIcon = true,
  onClick,
  color,
  isSubtitle = false,
}) => (
  <div className={styles.wrapper}>
    {isLeftIcon && <CheckIcon className={styles.leftIcon} />}
    <Link
      href={href}
      underline={underline}
      onClick={onClick}
      className={isSubtitle ? styles.subtitle : styles.text}
      color={color}
    >
      {text}
    </Link>
    {isRightIcon && <CloseIcon className={styles.rightIcon} />}
  </div>
);
