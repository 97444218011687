import React, { useState } from 'react';

import { IconButton } from 'src/components/atoms/IconButton';
import { SearchPanel } from 'src/components/organisms/SearchPanel';

import styles from 'src/components/molecules/GroupChatSectionHeader/styles.module.css';

export type Props = {
  title: string;
  icons?: boolean;
  handleMembers?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string
  ) => void;
};

export const GroupChatSectionHeader: React.FC<Props> = ({
  title,
  icons,
  handleMembers,
}) => {
  const [isSearch, setIsSearch] = useState(false);
  const onSearchClick = () => {
    setIsSearch((prevState) => !prevState);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.chatHeader}>
        <p>{title}</p>
        {icons && (
          <>
            <div className={styles.tools}>
              <IconButton
                icon="zoomIn"
                onClick={onSearchClick}
                color={isSearch ? '#f2cd40' : '#ADB0BC'}
              />
              {/* <IconButton icon="morevert" color="#ADB0BC" /> */}
              {/* TODO commented unused buttons. Dont delete */}
            </div>
          </>
        )}
      </div>
      {isSearch ? (
        <div className={styles.search}>
          <SearchPanel
            autoFocus
            setIsSearch={setIsSearch}
            withoutPadding={true}
            isContactSearch={true}
            handleMembers={handleMembers}
          />
        </div>
      ) : null}
    </div>
  );
};
