import React from 'react';

import { normalizeTime } from 'src/utils/date';

import styles from 'src/components/molecules/MessageDeal/styles.module.css';

export type Props = {
  company: string;
  date: string;
  currency: string;
  code: string;
  ask: string;
  askSize: string;
  bid: string;
  bidSize: string;
};

export const MessageDeal: React.FC<Props> = ({
  company,
  date,
  currency,
  code,
  ask,
  askSize,
  bid,
  bidSize,
}) => {
  const normalizedDate = normalizeTime(date);

  const lengthBid = bid.length;
  const lengthAsk = ask.length;
  const lengthAskSize = askSize.length;
  const lengthBidSize = bidSize.length;

  const renderCost = () => {
    if (
      lengthAsk < 6 &&
      lengthAskSize < 6 &&
      lengthBid < 6 &&
      lengthBidSize < 6
    ) {
      return (
        <div className={styles.content}>
          <div className={styles.row}>
            <p className={styles.textBottom}>Рзмр Бид</p>
            <p className={styles.textBottomSize}>
              {bidSize !== '0' ? bidSize : ''}
            </p>
          </div>
          <div className={styles.row}>
            <p className={styles.textBottom}>Бид</p>
            <p className={styles.textBottomSize}>{+bid !== 0 ? bid : ''}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.textBottom}>Аск</p>
            <p className={styles.textBottomSize}>{+ask !== 0 ? ask : ''}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.textBottom}>Рзмр Аск</p>
            <p className={styles.textBottomSize}>
              {askSize !== '0' ? askSize : ''}
            </p>
          </div>
        </div>
      );
    } else if (
      lengthAsk > 12 ||
      lengthAskSize > 12 ||
      lengthBid > 12 ||
      lengthBidSize > 12
    ) {
      return (
        <>
          <div className={styles.content}>
            <div className={styles.row}>
              <p className={styles.textBottom}>Рзмр Бид</p>
              <p className={styles.textBottomSize}>
                {bidSize !== '0' ? bidSize : ''}
              </p>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.row}>
              <p className={styles.textBottom}>Бид</p>
              <p className={styles.textBottomSize}>{+bid !== 0 ? bid : ''}</p>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.row}>
              <p className={styles.textBottom}>Аск</p>
              <p className={styles.textBottomSize}>{+ask !== 0 ? ask : ''}</p>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.row}>
              <p className={styles.textBottom}>Рзмр Аск</p>
              <p className={styles.textBottomSize}>
                {askSize !== '0' ? askSize : ''}
              </p>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className={styles.content}>
            <div className={styles.row}>
              <p className={styles.textBottom}>Бид</p>
              <p className={styles.textBottomSize}>{+bid !== 0 ? bid : ''}</p>
            </div>
            <div className={styles.row}>
              <p className={styles.textBottom}>Аск</p>
              <p className={styles.textBottomSize}>{+ask !== 0 ? ask : ''}</p>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.row}>
              <p className={styles.textBottom}>Рзмр Бид</p>
              <p className={styles.textBottomSize}>
                {bidSize !== '0' ? bidSize : ''}
              </p>
            </div>
            <div className={styles.row}>
              <p className={styles.textBottom}>Рзмр Аск</p>
              <p className={styles.textBottomSize}>
                {askSize !== '0' ? askSize : ''}
              </p>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <div className={styles.messageDeal}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>
            <p className={styles.currency}>{currency}</p>
            <p className={styles.code}>{code}</p>
          </div>
          <div className={styles.subtitle}>
            <p className={styles.textCompany}>{company}</p>
          </div>
        </div>
        {renderCost()}
      </div>
      <div className={styles.date}>
        <p className={styles.textDateTime}>{normalizedDate}</p>
      </div>
    </div>
  );
};
