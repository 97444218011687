import React from 'react';

import { Icon } from 'src/components/atoms/Icon';
import { ChatMember } from 'src/types';

export type Props = {
  status?: ChatMember['status'];
};

const statusColor = {
  ONLINE: '#66BF1D',
  idle: '#F2CD40',
  invisible: '#ADB0BC',
  OFFLINE: '#BD0000',
  doNotDistrub: '#BD0000',
};

export const UserStatus: React.FC<Props> = ({ status }) => {
  return <Icon icon="status" fill={statusColor[status ? status : 'OFFLINE']} />;
};
