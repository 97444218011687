import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from 'src/actions';
import { IconButton } from 'src/components/atoms/IconButton';
import { FileInfo } from 'src/components/molecules/FileInfo';
import { ID, InternalFile } from 'src/types';
import {
  DATE_NOW_MINUS_WEEK,
  checkDeleteTime,
  compareLaterDates,
  normalizeDate,
  normalizeTime,
} from 'src/utils/date';

import styles from 'src/components/molecules/FileMessage/styles.module.css';

type ToolsProps = {
  isOwnerMessage?: boolean;
  handleDownload: () => void;
  handleDelete: () => void;
  date: string;
};

const Tools: React.FC<ToolsProps> = ({
  isOwnerMessage,
  handleDownload,
  handleDelete,
  date,
}) => (
  <>
    <IconButton icon="download" onClick={handleDownload} />
    {isOwnerMessage && checkDeleteTime(date) && (
      <IconButton icon="close" onClick={handleDelete} />
    )}
    {/*    <IconButton icon="moreHoriz" />*/}
  </>
);

export type Props = {
  user?: string;
  company?: string;
  date: string;
  fileId: InternalFile['id'];
  fileName: InternalFile['file_name'];
  fileSize: InternalFile['size'];
  isOwnerMessage?: boolean;
  setAlertMessage?: (value: React.SetStateAction<string>) => void;
  setVisible?: (value: React.SetStateAction<boolean>) => void;
  messageId?: ID;
  message?: InternalFile;
};

export const FileMessage: React.FC<Props> = ({
  user,
  company,
  fileName,
  isOwnerMessage = false,
  fileSize,
  date,
  fileId,
  setAlertMessage,
  setVisible,
  messageId,
  message,
}) => {
  const dispatch = useDispatch();

  const [isHover, setIsHover] = useState(false);
  const normalizedTime = normalizeTime(date);
  const normalizedDate = normalizeDate(date);

  const showBanner = () => {
    setAlertMessage?.('Файл больше недоступен для скачивания');
    setVisible?.(true);
  };

  const handleDownload = () => {
    !compareLaterDates(DATE_NOW_MINUS_WEEK, normalizedDate)
      ? dispatch(
          actions.api.media.download.started({
            id: fileId,
            extra: { name: fileName },
          })
        )
      : showBanner();
  };

  const handleDelete = () => {
    messageId &&
      dispatch(
        actions.ws.send({
          type: 'changeMessage',
          message: {
            messageId: messageId,
            message: JSON.stringify({ ...message, isDeleted: true }),
          },
        })
      );
  };

  return (
    <>
      <div className={styles.user}>
        {user && (
          <div className={styles.header}>
            <span className={styles.user}>{user}</span>
            {!!company && (
              <span className={styles.company}>
                {company.length > 10 ? `${company.slice(0, 10)}...` : company}
              </span>
            )}
          </div>
        )}
      </div>
      <div
        role="button"
        tabIndex={0}
        className={styles.wrapper}
        onMouseEnter={() => setIsHover((prevState) => !prevState)}
        onMouseLeave={() => setIsHover((prevState) => !prevState)}
      >
        <div className={styles.leftBlock}>
          <FileInfo
            fileName={fileName}
            fileSize={fileSize}
            onClick={handleDownload}
          />
        </div>
        <div className={styles.rightBlock}>
          {isHover ? (
            <span className={styles.tools}>
              <Tools
                isOwnerMessage={isOwnerMessage}
                handleDownload={handleDownload}
                handleDelete={handleDelete}
                date={date}
              />
            </span>
          ) : (
            <span className={styles.date}>{normalizedTime}</span>
          )}
        </div>
      </div>
    </>
  );
};
