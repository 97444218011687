import Divider from '@mui/material/Divider';
import React from 'react';

import { Position } from 'src/components/organisms/ChatInstance';
import { DropItem, DropItemList } from 'src/components/organisms/DropItemList';
import { Functionality } from 'src/components/organisms/SectionHeader';

import styles from 'src/components/organisms/DropDownMenu/styles.module.css';

export type Props = {
  dropItemsHeader: DropItem[];
  dropItemsBody?: DropItem[];
  dropItemsFooter?: DropItem[];
  setFunctionality?: (f: Functionality[]) => void;
  functionality?: Functionality[];
  position: Position;
  memberId?: string;
  ownerId?: string;
};

export const DropDownMenu: React.FC<Props> = ({
  dropItemsHeader,
  dropItemsBody,
  dropItemsFooter,
  setFunctionality,
  functionality,
  position,
  memberId,
  ownerId,
}) => {
  return (
    <div
      className={styles.container}
      style={{ top: position.y, left: position.x }}
    >
      <div className={styles.borderWrapper}>
        <div className={styles.itemList}>
          <DropItemList
            dropItems={dropItemsHeader}
            setFunctionality={setFunctionality}
            functionality={functionality}
            memberId={memberId}
            ownerId={ownerId}
          />

          {dropItemsBody && (
            <>
              <Divider className={styles.devider} orientation="horizontal" />
              <DropItemList
                dropItems={dropItemsBody}
                setFunctionality={setFunctionality}
                functionality={functionality}
                memberId={memberId}
                ownerId={ownerId}
              />
            </>
          )}

          {dropItemsFooter && (
            <>
              <Divider className={styles.devider} orientation="horizontal" />
              <DropItemList
                dropItems={dropItemsFooter}
                setFunctionality={setFunctionality}
                functionality={functionality}
                memberId={memberId}
                ownerId={ownerId}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
