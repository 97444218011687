import { AllEffect, CallEffect, all, call } from 'redux-saga/effects';

import { ajaxSaga } from 'src/sagas/ajax';
import { blotterSaga } from 'src/sagas/blotter';
import { chatSaga } from 'src/sagas/chat';
import { initSaga } from 'src/sagas/init';
import { mediaSaga } from 'src/sagas/media';
import { webSocketSaga } from 'src/sagas/webSocket';
import { webSocketSagaMonitor } from 'src/sagas/webSocketMonitor';
import { windowMessageSaga } from 'src/sagas/windowMessage';

const sagas = [
  ajaxSaga,
  chatSaga,
  initSaga,
  mediaSaga,
  webSocketSaga,
  windowMessageSaga,
  webSocketSagaMonitor,
  blotterSaga,
];

export function* rootSaga(): Generator<
  AllEffect<CallEffect<unknown>>,
  void,
  unknown
> {
  yield all(sagas.map((saga) => call(saga)));
}
