import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { SearchRow } from 'src/components/molecules/SearchRow';
import { ReduxState } from 'src/reducers';
import { serachContactResponse } from 'src/types';

import styles from 'src/components/molecules/SearchContact/styles.module.css';

export type Props = {
  message: string;
  closeSearch: () => void;
  handleMembers?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string
  ) => void;
};

export const SearchContact: React.FC<Props> = ({
  message,
  closeSearch,
  handleMembers,
}) => {
  const [contacts, setContacts] = useState<serachContactResponse[]>([]);

  const searchContacts = useSelector((state: ReduxState) => {
    return state.chat.searchGroupData.contacts;
  });
  useEffect(() => {
    const sortedContacts = searchContacts?.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
    setContacts(sortedContacts);
  }, [searchContacts]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.searchlist}>
          <div className={styles.content}>
            <div className={styles.main}>
              <div className={styles.block}>
                {!!contacts.length &&
                  contacts.map((item, index) => (
                    <SearchRow
                      key={item.id + index.toString()}
                      centerText={item.position}
                      filter={message}
                      downText={item.organization?.title}
                      firstText={item.name}
                      rightText=""
                      itemId={item.id}
                      closeSearch={closeSearch}
                      type="contact"
                      isContactSearch={true}
                      handleMembers={handleMembers}
                    />
                  ))}
                {!contacts.length && (
                  <div className={styles.noSearch}>Пользователи не найдены</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
