import classNames from 'classnames';
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Checkbox } from 'src/components/atoms/Checkbox';
import { Icon, IconType } from 'src/components/atoms/Icon';
import { selectableContact } from 'src/components/organisms/ChatContactsPanel';
import { ReduxState } from 'src/reducers';
import { Chat } from 'src/types';

import styles from 'src/components/molecules/Accordion/styles.module.css';

export type Props = {
  children: ReactElement | ReactElement[];
  title?: string;
  icon?: IconType;
  isEditMode?: boolean;
  totalItems: string | number;
  isReceivedNewMessage?: boolean;
  isActive?: boolean;
  nestedChatsIds: Chat['chatId'][];
  addSelectedChatsFromAccordeon: (
    selectedChatsAccordeon: Chat['chatId'][]
  ) => void;
  deleteChatsFromAccordeon: (chatId: Chat['chatId'] | Chat['chatId'][]) => void;
  favoriteChats?: Chat[];
};

export const Accordion: React.FC<Props> = ({
  isEditMode = false,
  title,
  children,
  icon,
  totalItems,
  isReceivedNewMessage,
  isActive: isDefaultActive,
  nestedChatsIds = [],
  addSelectedChatsFromAccordeon,
  deleteChatsFromAccordeon,
  favoriteChats,
}) => {
  const [isActive, setIsActive] = useState(isDefaultActive);
  const [isCheckedAccordeon, setCheckedAccordeon] = useState(false);
  const [isPartialSelection, setPartialSelection] = useState(false);
  const [
    isCheckedAccordeonWithoutChildren,
    setCheckedAccordeonWithoutChildren,
  ] = useState(false);
  const [selectedChats, setSelectedChats] = useState<string[]>([]);

  const handleSelectedChatsClick = (chatId: string, selectable: boolean) => {
    if (!selectable) {
      return;
    }
    let arr;
    if (selectedChats.includes(chatId)) {
      arr = selectedChats.filter((n) => n !== chatId);
      deleteChatsFromAccordeon(chatId);
    } else {
      arr = [chatId, ...selectedChats];
    }
    setSelectedChats(arr);
  };

  const handleSelectedChatFromArray = (arrayOfIds?: Chat['chatId'][]) => {
    if (!arrayOfIds?.length) {
      deleteChatsFromAccordeon(selectedChats);
      setSelectedChats([]);
    } else if (arrayOfIds) {
      setSelectedChats(
        favoriteChats
          ? favoriteChats
              .filter((i) =>
                selectableContact(i.chatType.code, i.status?.userStatus)
              )
              .map((i) => i.chatId)
          : arrayOfIds
      );
    }
  };

  const toggleCheckedAccordeon = () => {
    setCheckedAccordeon((prevState) => !prevState);
    setCheckedAccordeonWithoutChildren(false);
  };

  const verifyCheckedAccordeonWithoutChildren = (boolean: boolean) => {
    setCheckedAccordeonWithoutChildren(boolean);
  };

  useEffect(() => {
    isEditMode
      ? handleSelectedChatFromArray(nestedChatsIds)
      : handleSelectedChatFromArray([]);
    isEditMode && setCheckedAccordeonWithoutChildren(true);
  }, [isEditMode]);

  useEffect(() => {
    selectedChats.length === 0 && setCheckedAccordeonWithoutChildren(false);
  }, [selectedChats]);

  useEffect(() => {
    setPartialSelection(
      selectedChats.length > 0 && selectedChats.length < nestedChatsIds.length
    );
    selectedChats.length > 0 && addSelectedChatsFromAccordeon(selectedChats);
    selectedChats.length === 0 && addSelectedChatsFromAccordeon([]);
  }, [selectedChats]);

  useEffect(() => {
    if (!isPartialSelection && !isCheckedAccordeon) {
      setCheckedAccordeon(isCheckedAccordeonWithoutChildren);
    }
  }, [
    isCheckedAccordeonWithoutChildren,
    isPartialSelection,
    isCheckedAccordeon,
  ]);

  const handleActive = (e: React.MouseEvent<HTMLElement>) => {
    const { tagName } = e.target as HTMLButtonElement;
    if (tagName !== 'INPUT') {
      setIsActive(!isActive);
    }
  };

  const arrowClass = classNames(styles.accordionArrow, {
    [styles.isActive]: isActive,
    [styles.newMessage]: isReceivedNewMessage,
  });
  const headerClass = classNames(styles.header, {
    [styles.newMessage]: isReceivedNewMessage,
  });

  const chats = useSelector((state: ReduxState) =>
    nestedChatsIds.map((id: string) => state.chat.chats[id])
  );

  const activeChatsCount = chats.reduce(
    (count, current) =>
      count + (current?.status?.userStatus === 'ONLINE' ? 1 : 0),
    0
  );

  return (
    <>
      <button className={headerClass} onClick={handleActive}>
        {isEditMode && (
          <Checkbox
            icon={isPartialSelection ? 'minus' : 'check'}
            toggleSelectedLine={toggleCheckedAccordeon}
            chatsFromAccordeon={nestedChatsIds}
            handleSelectedChatFromArray={handleSelectedChatFromArray}
            checked={
              (isPartialSelection || isCheckedAccordeonWithoutChildren) &&
              isCheckedAccordeonWithoutChildren
            }
          />
        )}

        <span className={arrowClass}>
          <Icon icon="accordionArrow" size="m" />
        </span>
        {icon ? <Icon icon={icon} /> : null}

        <div className={styles.title}>{title}</div>
        <div
          className={styles.count}
        >{`${activeChatsCount} / ${totalItems}`}</div>
      </button>

      {isActive && (
        <div className={styles.content}>
          {React.Children.map(children, (child) =>
            React.cloneElement(child as ReactElement, {
              isSelectedLine: isCheckedAccordeon,
              verifyCheckedAccordeon: verifyCheckedAccordeonWithoutChildren,
              handleSelectedChatsClick: handleSelectedChatsClick,
              selectedChats: selectedChats,
            })
          )}
          <hr className={styles.divider} />
        </div>
      )}
    </>
  );
};
