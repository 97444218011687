import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from 'src/actions';
import { Input } from 'src/components/molecules/Input';
import { SearchContact } from 'src/components/molecules/SearchContact';
import { SearchGroup } from 'src/components/molecules/SearchGroup';
import { TypeDialogPanelProp } from 'src/components/organisms/ChatInstance';
import { SearchNavigator } from 'src/components/organisms/SearchNavigator';
import { useDebounce } from 'src/hooks/useDebounce';

import styles from 'src/components/organisms/SearchPanel/styles.module.css';

export type Props = {
  autoFocus: boolean;
  setIsSearch: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  setSearchMessage?: React.Dispatch<React.SetStateAction<string>>;
  setTextSearchRefsArray?: HTMLElement[] | ((el: HTMLElement) => number);
  comingSearchString?: string;
  withoutPadding?: boolean;
  isContactSearch?: boolean;
  handleMembers?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string
  ) => void;
  setTypePanel?: (value: React.SetStateAction<TypeDialogPanelProp>) => void;
};

export const SearchPanel: React.FC<Props> = ({
  autoFocus,
  setIsSearch,
  setSearchMessage,
  setTextSearchRefsArray,
  comingSearchString,
  withoutPadding,
  isContactSearch,
  handleMembers,
  setTypePanel,
}) => {
  const [message, setMessage] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isSearchPanelShow, setIsSearchPanelShow] = useState(false);
  const debouncedValue = useDebounce<string>(message, 500);
  const dispatch = useDispatch();

  const onKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    event.stopPropagation();

    if (event.key === 'Escape') {
      if (setSearchMessage) setSearchMessage('');
      setIsSearch(false);
    }
    setAnchorEl(event.currentTarget);
  };

  const closeSearch = () => {
    if (setSearchMessage) setSearchMessage('');
    setIsSearchPanelShow(false);
    setMessage('');
  };

  useEffect(() => {
    setSearchMessage
      ? setSearchMessage(message)
      : setIsSearchPanelShow(!!message);
  }, [message, setSearchMessage]);

  useEffect(() => {
    message &&
      dispatch(
        actions.api.chat.searchCategory.started({
          name: message,
        })
      );
  }, [debouncedValue]);

  useEffect(() => {
    if (comingSearchString) setMessage(comingSearchString);
  }, [comingSearchString]);

  return (
    <>
      <form
        className={styles.container}
        onKeyDown={onKeyDown}
        style={withoutPadding ? { padding: '0' } : undefined}
      >
        <div className={styles.wrapper}>
          <Input
            autoFocus={autoFocus}
            setMessage={setMessage}
            closeSearch={closeSearch}
            message={message}
            setIsSearch={setIsSearch}
            setAnchorEl={setAnchorEl}
            setIsSearchPanelShow={setIsSearchPanelShow}
          />
        </div>
        {setTextSearchRefsArray && (
          <SearchNavigator data={setTextSearchRefsArray} />
        )}
      </form>
      {isSearchPanelShow && !isContactSearch && (
        <Popper
          placement="bottom-start"
          open={isSearchPanelShow}
          anchorEl={anchorEl}
          transition
          sx={{ zIndex: '1100', inset: '-7px auto auto 0px !important' }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Box>
                <SearchGroup
                  closeSearch={closeSearch}
                  message={message}
                  setTypePanel={setTypePanel}
                />
              </Box>
            </Fade>
          )}
        </Popper>
      )}
      {!setSearchMessage && isContactSearch && (
        <Popper
          placement="bottom-start"
          open={isSearchPanelShow}
          anchorEl={anchorEl}
          transition
          sx={{
            zIndex: '1500',
            '&.MuiPopper-root': { top: '5px' },
          }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Box>
                <SearchContact
                  closeSearch={closeSearch}
                  message={message}
                  handleMembers={handleMembers}
                />
              </Box>
            </Fade>
          )}
        </Popper>
      )}
    </>
  );
};
