import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from '@mui/material/styles';
import React, { ReactNode } from 'react';

const theme = createTheme({
  palette: {
    primary: { main: '#f2cd40' },
    secondary: { main: '#1C1B1F' },
  },
});

type Props = {
  children: ReactNode;
};

export const MaterialProvider: React.FC<Props> = ({ children }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>{children} </ThemeProvider>
  </StyledEngineProvider>
);
