import React from 'react';

import { Icon } from 'src/components/atoms/Icon';
import { IconButton } from 'src/components/atoms/IconButton';

import styles from 'src/components/molecules/FileInfo/styles.module.css';

export type Props = {
  fileName: string;
  fileSize: string;
  onClick: () => void;
  prepared?: boolean;
};

export const FileInfo: React.FC<Props> = ({
  fileName,
  fileSize,
  onClick,
  prepared,
}) => (
  <div className={styles.fileInfoBox} role="button">
    <div className={styles.container}>
      <div className={styles.wrapperIcon}>
        <div>
          <Icon icon="textSnippet" size="m" />
        </div>
        <span className={styles.fileName}>{fileName}</span>
      </div>
      <div>
        <span className={styles.fileSize}>{fileSize}</span>
      </div>
    </div>
    {prepared && (
      <div className={styles.prepared}>
        <IconButton icon="close" size="m" onClick={onClick} />
      </div>
    )}
  </div>
);
