import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actions } from 'src/actions';
import { BlotterHeaderFilterType, Dictionaries, MonitorUser } from 'src/types';

export type State = {
  user: MonitorUser;
  dictionaries: Dictionaries;
  tableFilter: BlotterHeaderFilterType;
};

const initialState: State = {
  user: {},
  dictionaries: {},
  tableFilter: {},
};

const reducer = reducerWithInitialState<State>(initialState)
  .case(actions.api.dictionaries.activeClass.done, (state, payload) => {
    const filterData = payload.result.ActiveClass?.map((item) => ({
      id: item.id,
      name: item.description,
    }));

    return {
      ...state,
      dictionaries: {
        ...state.dictionaries,
        activeClass: payload.result.ActiveClass,
      },
      tableFilter: {
        ...state.tableFilter,
        activeClass: filterData,
      },
    };
  })
  .case(actions.api.dictionaries.instrument.done, (state, payload) => {
    const filterData = payload.result.Instrument?.map((item) => ({
      id: item.id,
      name: item.description,
    }));

    return {
      ...state,
      dictionaries: {
        ...state.dictionaries,
        instrument: payload.result.Instrument,
      },
      tableFilter: {
        ...state.tableFilter,
        instrument: filterData,
      },
    };
  })
  .case(actions.api.dictionaries.calculationBase.done, (state, payload) => {
    const filterData = payload.result.CalculationBase?.map((item) => ({
      id: item.id,
      name: item.description,
    }));

    return {
      ...state,
      dictionaries: {
        ...state.dictionaries,
        calculationBase: payload.result.CalculationBase,
      },
      tableFilter: {
        ...state.tableFilter,
        calculationBase: filterData,
      },
    };
  })
  .case(actions.api.dictionaries.currency.done, (state, payload) => {
    const filterData = payload.result.Currency?.map((item) => ({
      id: item.id,
      name: item.code,
    }));

    return {
      ...state,
      dictionaries: {
        ...state.dictionaries,
        currency: payload.result.Currency,
      },
      tableFilter: {
        ...state.tableFilter,
        currency: filterData,
      },
    };
  })
  .case(actions.api.dictionaries.currencyPair.done, (state, payload) => {
    return {
      ...state,
      dictionaries: {
        ...state.dictionaries,
        currencyPair: payload.result.CurrencyPair,
      },
    };
  })
  .case(actions.api.dictionaries.transactionStatus.done, (state, payload) => {
    const filterData = payload.result.TicketStatus?.map((item) => ({
      id: item.id,
      name: item.description,
    }));

    return {
      ...state,
      dictionaries: {
        ...state.dictionaries,
        ticketStatus: payload.result.TicketStatus,
      },
      tableFilter: {
        ...state.tableFilter,
        ticketStatus: filterData,
      },
    };
  })
  .case(
    actions.api.dictionaries.transactionDirection.done,
    (state, payload) => {
      const filterData = payload.result.TransactionDirection?.map((item) => ({
        id: item.id,
        name: item.description,
      }));

      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          transactionDirection: payload.result.TransactionDirection,
        },
        tableFilter: {
          ...state.tableFilter,
          transactionDirection: filterData,
        },
      };
    }
  )
  .case(actions.api.dictionaries.tenor.done, (state, payload) => {
    return {
      ...state,
      dictionaries: {
        ...state.dictionaries,
        tenor: payload.result.Tenor,
      },
    };
  })
  .case(actions.api.blotter.contact.done, (state, payload) => {
    if (payload.params?.extra?.isUser) {
      return {
        ...state,
        user: {
          userId: payload.result.Contact![0].id,
          orgId: payload.result.Contact![0].organization_id,
          userName: `${payload.result.Contact![0].first_name} ${
            payload.result.Contact![0].last_name
          }`,
        },
      };
    }

    return state;
  });
export const monitor = { initialState, reducer };
