//TODO need to hardcore CloseIcon as rightIcon in future
import classNames from 'classnames';
import React from 'react';

import { Icon, IconType } from 'src/components/atoms/Icon';
import { ChatGroup, ChatGroupStatus } from 'src/types';

import styles from 'src/components/atoms/Tab/styles.module.css';

export type Props = {
  label?: React.ReactNode;
  leftIcon?: null | IconType;
  rightIcon?: null | IconType;
  underline?: boolean;
  popper?: boolean;
  handleClose?: (id: ChatGroup['chatGroupId']) => void;
  leftIconClick?: (
    event: React.MouseEvent<HTMLElement>
  ) => void | React.MouseEventHandler<HTMLDivElement>;
  handleOpenPopper?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  ref?: React.RefObject<HTMLDivElement>;
  short?: boolean;
  handleActive?: (id: ChatGroup['chatGroupId']) => void;
  selected: boolean;
  id: string;
  status?: ChatGroupStatus;
};

export const Tab: React.FC<Props> = ({
  label,
  leftIcon,
  rightIcon,
  underline = false,
  handleClose,
  leftIconClick,
  handleOpenPopper,
  ref,
  short,
  handleActive,
  selected,
  id,
  status,
  popper,
}) => {
  const tabStyles = classNames(
    { [styles.underline]: underline },
    { [styles.popper]: popper },
    { [styles.tab]: !popper }
  );

  const containerStyles = classNames(
    { [styles.underline]: underline },
    { [styles.selected]: selected },
    { [styles.container]: !selected },
    {
      [styles.newMessage]: status && status.isUnread,
    }
  );

  return (
    <div
      className={containerStyles}
      id={id}
      tabIndex={0}
      ref={ref}
      role="button"
      onClick={handleActive ? () => handleActive(id) : handleOpenPopper}
      style={short ? { maxWidth: '5.25rem' } : undefined}
    >
      <div className={tabStyles}>
        {leftIcon ? (
          <div
            className={styles.leftIcon}
            role="button"
            onClick={leftIconClick}
          >
            <Icon
              icon={leftIcon}
              size={popper ? 'xxs' : 'm'}
              fill={popper ? 'var(--COLOR_BACKGROUND)' : undefined}
            />
          </div>
        ) : null}
        {label ? (
          <div
            className={styles.label}
            style={short ? { textOverflow: 'ellipsis' } : undefined}
          >
            {label}
          </div>
        ) : null}
        {rightIcon ? (
          <div
            className={styles.rightIcon}
            role="button"
            onClick={() => handleClose && handleClose(id)}
          >
            <Icon icon={rightIcon} size="xs" fill="var(--COLOR_BACKGROUND)" />
          </div>
        ) : null}
      </div>
    </div>
  );
};
