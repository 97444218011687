import { Action } from 'redux';
import { END, TakeableChannel, eventChannel } from 'redux-saga';
import { call, put, take } from 'redux-saga/effects';

function subscribe() {
  return eventChannel((emitter) => {
    window.onmessage = (e) => {
      try {
        emitter(JSON.parse(e.data));
      } catch (e) {
        // ignore
      }
    };

    return () => {
      emitter(END);
    };
  });
}

export function* windowMessageSaga() {
  const channel: TakeableChannel<unknown> = yield call(subscribe);

  while (true) {
    const action: Action = yield take(channel);
    yield put(action);
  }
}
