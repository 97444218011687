import { Meta, Story } from '@storybook/react/types-6-0';

import { Icon as IconComponent, Props } from 'src/components/atoms/Icon';
import { decorators } from 'src/components/providers/StorybookProvider';

export default {
  title: 'Atoms',
  component: IconComponent,
  decorators,
} as Meta;

const Template: Story<Props> = (args) => <IconComponent {...args} />;

export const Icon = Template.bind({});

Icon.args = {
  icon: 'none',
};
