import React, { ReactNode } from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import { MaterialProvider } from 'src/components/providers/MaterialProvider';
import { ModalProvider } from 'src/components/providers/ModalProvider';
import { store } from 'src/utils/store';

import 'src/styles/global.css';
import 'src/styles/theme.css';

type Props = {
  children: ReactNode;
};

export const Provider: React.FC<Props> = ({ children }) => (
  <ReduxProvider store={store}>
    <MaterialProvider>
      <ModalProvider>{children}</ModalProvider>
    </MaterialProvider>
  </ReduxProvider>
);
