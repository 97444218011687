import { Autocomplete as AutocompleteMUI } from '@mui/material';
import TextField from '@mui/material/TextField';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { ControllerRenderProps } from 'react-hook-form';

import { DictionariesFormValues, ITicketFormValues } from 'src/types';

import './mui.css';

import styles from 'src/components/atoms/Autocomplete/styles.module.css';

export type Props = {
  label?: string;
  placeholder?: string;
  list?: DictionariesFormValues[];
  onChange: (data: DictionariesFormValues) => void;
  defaultValue?: DictionariesFormValues;
  controledInputValue?: string;
  setControledInputValue?: React.Dispatch<React.SetStateAction<string>>;
  field?: ControllerRenderProps<ITicketFormValues, any>;
  readOnly?: boolean;
  onFetch?: (value: string) => void;
};

export const Autocomplete: React.FC<Props> = ({
  label,
  placeholder,
  list = [],
  onChange,
  defaultValue,
  field,
  readOnly,
  onFetch,
  setControledInputValue,
  controledInputValue,
}) => {
  const [inputValueLocal, setInputValueLocal] = React.useState('');
  const [value, setValue] = React.useState<DictionariesFormValues | null>(null);

  useEffect(() => {
    if (defaultValue) {
      onChange(defaultValue);
      setValue(defaultValue);
      setInputValueLocal(defaultValue.title);
    }
  }, [defaultValue?.title, onChange]);

  return (
    <AutocompleteMUI
      isOptionEqualToValue={(option, value) => option?.title === value?.title}
      value={value}
      openOnFocus
      readOnly={readOnly}
      fullWidth
      noOptionsText=""
      inputValue={
        !!setControledInputValue ? controledInputValue : inputValueLocal
      }
      onInputChange={(_, newInputValue) => {
        !!setControledInputValue
          ? setControledInputValue(newInputValue)
          : setInputValueLocal(newInputValue);

        onFetch?.(newInputValue);
      }}
      className={styles.wrapper}
      id="autocomplete"
      clearIcon={null}
      options={list}
      selectOnFocus={true}
      getOptionLabel={(option) =>
        (option as DictionariesFormValues)?.title ?? ''
      }
      renderInput={(params) => (
        <label htmlFor="">
          {label}
          <TextField
            placeholder={placeholder}
            {...params}
            inputRef={field?.ref}
            className={classNames(styles.input, {
              [styles.readOnly]: readOnly,
            })}
            sx={{
              '& fieldset': { border: 'none' },
            }}
            value
          />
        </label>
      )}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option!.title, inputValue, {
          insideWords: true,
        });
        const parts = parse(option!.title, matches);

        return (
          <li {...props} className={styles.item} key={option?.id}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  className={classNames({
                    [styles.highlights]: part.highlight,
                  })}
                >
                  {part.text}
                </span>
              ))}
            </div>
            <span className={styles.additional}>
              {option?.organizationName}
            </span>
          </li>
        );
      }}
      onChange={(_, data) => {
        onChange(data as never);
        setValue(data as never);

        return data;
      }}
    />
  );
};
