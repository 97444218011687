import { NewChatRecord } from 'src/types';

export const defaultSettingsForUpdate: NewChatRecord['settings'] = [
  {
    chatSettingsId: '7c05899b-243d-46ff-91b8-4b912711d204',
    code: 'JOIN_ROOM',
    value: 'true',
  },
  {
    chatSettingsId: '531aa767-3705-48d2-96ff-56e3a8923ed8',
    code: 'IS_FAVORITE',
    value: 'false',
  },
  {
    chatSettingsId: 'ee4b581f-fbfd-4502-aba9-1353d43858ac',
    code: 'IS_MUTE',
    value: 'false',
  },
  {
    chatSettingsId: 'd3b3b578-b89d-449a-a431-15b80ca8887e',
    code: 'AUTOSTART',
    value: 'false',
  },
];
export const defaultSettingsForCreate: NewChatRecord['settings'] = [
  {
    chatSettingsId: '7c05899b-243d-46ff-91b8-4b912711d204',
    code: 'JOIN_ROOM',
    value: 'true',
  },
  {
    chatSettingsId: '531aa767-3705-48d2-96ff-56e3a8923ed8',
    code: 'IS_FAVORITE',
    value: 'false',
  },
  {
    chatSettingsId: 'ee4b581f-fbfd-4502-aba9-1353d43858ac',
    code: 'IS_MUTE',
    value: 'false',
  },
];
