import TabUnstyled from '@mui/base/TabUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import Popper from '@mui/material/Popper';
import React, { useEffect, useState } from 'react';

import { Icon, IconType } from 'src/components/atoms/Icon';
import { IconButton } from 'src/components/atoms/IconButton';
import { Tab } from 'src/components/atoms/Tab';
import { DropDownItem } from 'src/components/molecules/DropDownItem';
import { SearchMessageMainPanel } from 'src/components/organisms/SearchMessageMainPanel';
import { Functionality } from 'src/components/organisms/SectionHeader';
import { CHAT_DEFAULT_TAB } from 'src/constants';
import { ChatGroup, ChatGroupInternal, ChatInstance } from 'src/types';

import styles from 'src/components/organisms/TabBar/styles.module.css';

export type Props = {
  tabsData: ChatGroupInternal[];
  changeActiveGroup: (id: ChatGroup['chatGroupId']) => void;
  activeGroupId: ChatInstance['activeGroupId'];
  showContextMenu: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export const TabBar: React.FC<Props> = ({
  tabsData,
  changeActiveGroup,
  activeGroupId,
  showContextMenu,
}) => {
  const [activeModal, setActiveModal] = useState(false);
  const [functionality, setFunctionality] = useState<Functionality[]>([
    'forwardto',
  ]);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const defSizeTab = 80;
  const sizeOneTab = screenWidth / tabsData.length;
  const maxCountTabs = Math.floor(screenWidth / defSizeTab);
  const maxVisibleTabs = tabsData.slice(0, maxCountTabs);
  const menuItems = tabsData.slice(maxCountTabs);
  const iconMore = Boolean(anchorEl)
    ? ('arrowUp' as IconType)
    : ('arrowDown' as IconType);

  const handleOpenPopper = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    const currentTarget = event.currentTarget as HTMLDivElement;
    setAnchorEl(anchorEl ? null : currentTarget);
  };

  const closePopup = () => {
    setAnchorEl(null);
  };

  const handleScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect((): (() => void) => {
    window.addEventListener('resize', handleScreenWidth);

    return () => {
      window.removeEventListener('resize', handleScreenWidth);
    };
  }, [screenWidth]);

  const toggleSearchModal = () => {
    setActiveModal((prevState) => !prevState);
  };

  return (
    <>
      <TabsUnstyled onContextMenu={showContextMenu}>
        <TabsListUnstyled className={styles.wrapper}>
          {/* <IconButton
          icon="cable"
          onClick={() => console.log('cable')}
          color="#F2CD40"
        /> */}
          {sizeOneTab >= defSizeTab &&
            tabsData.map((item, index) => {
              if (index === 0 && item.groupType.code === CHAT_DEFAULT_TAB) {
                return (
                  <Tab
                    selected={item.chatGroupId === activeGroupId}
                    id={item.chatGroupId}
                    label={<Icon icon="question" />}
                    key={item + index.toString()}
                    handleActive={changeActiveGroup}
                    status={item.status}
                  />
                );
              }

              return (
                <TabUnstyled
                  className={styles.tabContainer}
                  key={item.toString() + index}
                >
                  <Tab
                    selected={item.chatGroupId === activeGroupId}
                    // label={screenWidth < 900 ? undefined : item.chatGroupName}
                    label={item.chatGroupName}
                    leftIcon={item.iconId as IconType}
                    id={item.chatGroupId}
                    // rightIcon={screenWidth < 900 ? undefined : item.rightIcon}
                    key={item + index.toString()}
                    handleActive={changeActiveGroup}
                    status={item.status}
                    //rightIcon={"close" as IconType}
                    //handleClose={handleDeleteGroup}
                  />
                </TabUnstyled>
              );
            })}

          {sizeOneTab < defSizeTab &&
            maxVisibleTabs.map((item, index) => {
              if (index === 0 && item.groupType.code === CHAT_DEFAULT_TAB) {
                return (
                  <Tab
                    selected={item.chatGroupId === activeGroupId}
                    id={item.chatGroupId}
                    label={<Icon icon="question" />}
                    key={item + index.toString()}
                    handleActive={changeActiveGroup}
                    status={item.status}
                  />
                );
              }

              return (
                <TabUnstyled
                  className={styles.tabContainer}
                  key={item.toString() + index}
                >
                  <Tab
                    selected={item.chatGroupId === activeGroupId}
                    // label={screenWidth < 900 ? undefined : item.chatGroupName}
                    label={item.chatGroupName}
                    leftIcon={item.iconId as IconType}
                    id={item.chatGroupId}
                    // rightIcon={screenWidth < 900 ? undefined : item.rightIcon}
                    key={item + index.toString()}
                    handleActive={changeActiveGroup}
                    status={item.status}
                    // rightIcon={<Icon icon="close" />}
                    // handleClose={handleDeleteGroup}
                  />
                </TabUnstyled>
              );
            })}

          {sizeOneTab < defSizeTab && (
            <Tab
              selected={Boolean(anchorEl)}
              label="Ещё"
              leftIcon={iconMore}
              id="more"
              popper
              handleOpenPopper={handleOpenPopper}
              // handleClose={handleDeleteGroup}
            />
          )}

          {/* <IconButton
          icon="add"
          onClick={() => console.log('add')}
          className={styles.add}
        /> */}

          {
            <div className={styles.rightTools}>
              <IconButton icon="search" onClick={() => toggleSearchModal()} />

              {/* <IconButton
            icon="openInFull"
            onClick={() => console.log('openInFull')}
          />/*/}
            </div>
          }

          {/* <IconButton icon="morevert" onClick={() => console.log('morevert')} /> */}
        </TabsListUnstyled>
        <Popper sx={{ zIndex: 1 }} open={Boolean(anchorEl)} anchorEl={anchorEl}>
          <div className={styles.borderWrapper}>
            {menuItems.map((item, index) => {
              return (
                <DropDownItem
                  handleActive={changeActiveGroup}
                  closePopup={closePopup}
                  functionality={functionality}
                  setFunctionality={setFunctionality}
                  key={item + index.toString()}
                  id={item.chatGroupId}
                  title={item.chatGroupName}
                  iconStart={item.iconId as IconType}
                />
              );
            })}
          </div>
        </Popper>
      </TabsUnstyled>
      {activeModal && (
        <SearchMessageMainPanel
          active={activeModal}
          setActive={setActiveModal}
        />
      )}
    </>
  );
};
