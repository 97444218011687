import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import { Dayjs } from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { DatepickerRange } from 'src/components/atoms/DatePickerRange';
import { Icon } from 'src/components/atoms/Icon/index.stories';
import { IconButton } from 'src/components/atoms/IconButton';
import { Modal } from 'src/components/atoms/Modal';
import { SelectType, chatType, chatTypes } from 'src/components/atoms/Select';
import { UserIdentifier } from 'src/components/atoms/UserIdentifier';
import { Input } from 'src/components/molecules/Input';
import { SearchGroup } from 'src/components/molecules/SearchGroup';
import { Hightlight } from 'src/components/organisms/Hightlight';
import { SearchMessageMessagesPanel } from 'src/components/organisms/SearchMessageMessagesPanel';
import { useDebounce } from 'src/hooks/useDebounce';
import { ReduxState } from 'src/reducers';
import { Chat, ChatMessage, FoundChat, GlobalSearchRequest } from 'src/types';
import { filterSearchParams } from 'src/utils/chat';
import {
  DATE_NOW,
  DATE_NOW_MINUS_60DAYS,
  normalizeDate,
  normalizeDateForSearch,
} from 'src/utils/date';

import styles from 'src/components/organisms/SearchMessageMainPanel/styles.module.css';

export type Props = {
  active: boolean;
  setActive: (e: boolean) => void;
};

export type Param = {
  id?: string;
  type?: 'chat' | 'contact' | 'tool' | 'queryString';
  name?: string;
};

export const SearchMessageMainPanel: React.FC<Props> = ({
  active,
  setActive,
}) => {
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState<RangeValue<Dayjs>>(null);
  const [typeOfChat, setTypeOfChat] = useState<chatType>(chatTypes[0]);
  const headerSearchRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState<string>('');
  const debouncedValue = useDebounce<string>(value, 500);
  const [searchParam, setSearchParam] = useState<Param[]>([]);
  const searchRequest = useRef<GlobalSearchRequest>({
    chat: [],
    contact: [],
    tool: [],
    operator: 'or',
  });

  const [isViewChat, setIsViewChat] = useState(false);
  const [isSearchPanelShow, setIsSearchPanelShow] = useState(false);
  const [selectedChat, setSelectedChat] = useState<FoundChat | null>(null);
  const [chipWithIcon, setChipWithIcon] = useState<Param>({});
  const [andOrOr, setEndOrOr] = useState<{
    title: string;
    type: GlobalSearchRequest['operator'];
  }>({
    title: 'ИЛИ',
    type: 'or',
  });
  const setGlobalSearchParams = (param: Param[]) => {
    setSearchParam(param);
  };
  const [isShowDatepicker, setIsShowDatepicker] = useState(false);

  const { foundChats, chats, groups } = useSelector((state: ReduxState) => ({
    foundChats: state.chat.temp.foundChats,
    chats: state.chat.chats,
    groups: state.chat.groups,
  }));

  const clearInput = () => {
    dispatch(actions.ui.chat.clearFoundChats());
    setIsSearchPanelShow(false);
  };

  useEffect(() => {
    if (searchParam.length) {
      searchRequest.current = {
        ...searchRequest.current,
        chat: filterSearchParams(searchParam, 'chat'),
        contact: filterSearchParams(searchParam, 'contact'),
        tool: filterSearchParams(searchParam, 'tool'),
        queryString: filterSearchParams(searchParam, 'queryString'),
        chatType: typeOfChat.type,
        operator: andOrOr.type,
        dateBetween:
          dateRange !== null
            ? {
                startedDate: normalizeDateForSearch(
                  dateRange[0]?.toString() ?? '',
                  'toBack'
                ),
                endDate: normalizeDateForSearch(
                  dateRange[1]?.toString() ?? '',
                  'toBack',
                  true
                ),
              }
            : {
                startedDate: DATE_NOW_MINUS_60DAYS,
                endDate: DATE_NOW,
              },
      };

      dispatch(actions.api.chat.globalSearch.started(searchRequest.current));
    } else {
      clearInput();
    }

    if (isViewChat) {
      toggleDialogPanel();
    }
  }, [searchParam, typeOfChat, andOrOr, dateRange]);

  const toggleDialogPanel = (chat?: FoundChat) => {
    setIsViewChat((prev) => !prev);
    if (!isViewChat) {
      setSelectedChat(chat ?? null);
    }
  };

  const closeSearch = () => {
    setSearchParam([]);
    setActive(false);
    clearInput();
  };

  const goToActiveChat = (chatId: Chat['chatId'] | undefined) => {
    if (typeof chatId !== 'undefined') {
      dispatch(
        actions.ui.chat.changeActiveChat({
          id: 'default',
          activeChatId: chatId,
        })
      );
    }
  };

  const goToActiveGroup = (group: string | undefined) =>
    dispatch(
      actions.ui.chat.changeActiveGroup({
        id: 'default',
        activeGroupId: group,
      })
    );

  const goToChat = () => {
    const chatExists = chats[selectedChat?.chatId as string];
    if (chatExists) {
      const groupId = groups.find((item) =>
        item.chats.includes(selectedChat?.chatId as string)
      )?.chatGroupId;
      goToActiveGroup(groupId);
      goToActiveChat(selectedChat?.chatId as string);
    } else if (selectedChat !== null) {
      dispatch(
        actions.api.chat.chatInfo.started({
          chatId: selectedChat?.chatId as string,
        })
      );
      goToActiveChat(selectedChat?.chatId);
    }
    closeSearch();
  };

  useEffect(() => {
    if (value.length) {
      setIsSearchPanelShow(true);
      dispatch(
        actions.api.chat.searchCategory.started({
          name: value,
        })
      );
    } else {
      setIsSearchPanelShow(false);
    }
  }, [debouncedValue, setIsSearchPanelShow]);

  const addParamToGlobalSearch = (param: Param) => {
    setChipWithIcon(param);
  };

  const getMessage = (message: ChatMessage['message']) => {
    try {
      const WSMessage = JSON.parse(message !== null ? message : '') as {
        message: string;
        type: string;
      };
      return WSMessage.message;
    } catch (error) {
      return message !== null ? message : '';
    }
  };

  useEffect(() => {
    if (dateRange === null) {
      setIsShowDatepicker(false);
    }
  }, [dateRange]);

  return (
    <Modal isOpen={active} handleClose={closeSearch}>
      <div className={styles.wrapper}>
        <div className={styles.leftBlock}>
          <div className={styles.title}>Поиск по сообщениям</div>
          <div ref={headerSearchRef} className={styles.input}>
            <div>
              <label className={styles.label}>Найти</label>
              <Input
                isChips
                autoFocus={true}
                message={value}
                setMessage={setValue}
                setTagsOut={setGlobalSearchParams}
                closeSearch={clearInput}
                chipWithIcon={chipWithIcon}
                andOrOr={andOrOr}
                setEndOrOr={setEndOrOr}
              />

              {isSearchPanelShow && (
                <Popper
                  placement="bottom-start"
                  open={isSearchPanelShow}
                  transition
                  anchorEl={headerSearchRef.current}
                  sx={{
                    zIndex: '1500',
                    inset: '-138px auto auto 6px !important',
                  }}
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps}>
                      <Box>
                        <SearchGroup
                          closeSearch={closeSearch}
                          message={value}
                          addParamToGlobalSearch={addParamToGlobalSearch}
                        />
                      </Box>
                    </Fade>
                  )}
                </Popper>
              )}
            </div>
            <div>
              <label className={styles.label}>Тип чата</label>
              <SelectType setType={setTypeOfChat} type={typeOfChat} />
            </div>
            <div>
              <label className={styles.label}>Период поиска</label>
              {!isShowDatepicker ? (
                <div
                  role="button"
                  onClick={() => setIsShowDatepicker(true)}
                  className={styles.defaultDate}
                >
                  <button>Последние 60 дней</button>
                  <Icon icon="today" size="m" fill="#EDEEF2" />
                </div>
              ) : (
                <DatepickerRange
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.rightBlock}>
          {isViewChat ? (
            <>
              <SearchMessageMessagesPanel
                activeChatId={selectedChat?.chatId}
                chat={selectedChat}
                comingSearchString={
                  searchRequest.current.queryString?.length &&
                  selectedChat?.messages?.length
                    ? searchRequest.current.queryString[0]
                    : ''
                }
                goToChat={() => goToChat()}
                backButton
                handleBackButtonClick={toggleDialogPanel}
                handleClose={closeSearch}
              />
            </>
          ) : (
            <>
              <div className={styles.title_right}>
                <IconButton icon="close" onClick={closeSearch} />
              </div>

              <div className={styles.wrapperFounds}>
                {foundChats?.map((chat, index) => {
                  let countMessages = 0;
                  chat.messages?.forEach((item) => {
                    countMessages = countMessages + +item.countMessages;
                  });
                  return (
                    <div
                      role="button"
                      onClick={() => toggleDialogPanel(chat)}
                      className={styles.chat}
                      key={chat.chatId + index.toString()}
                    >
                      <div>
                        <div className={styles.header}>
                          <div className={styles.chatTitle}>
                            <UserIdentifier
                              type={chat.chatType === 'P2P' ? 'P2P' : 'avatar'}
                              icon={
                                chat.chatType === 'P2P'
                                  ? chat.status
                                  : chat.chatType
                              }
                            />
                            <span className={styles.chatName}>
                              {chat.chatName}
                            </span>
                            <span className={styles.date}>
                              {chat.messages?.length
                                ? normalizeDate(chat.messages[0].createdAt)
                                : normalizeDate(chat.createdAt)}
                            </span>
                          </div>
                          {chat.chatType === 'PEOPLE' &&
                            chat.contact?.map((item) => (
                              <div
                                className={styles.contactName}
                                key={item.userName}
                              >
                                <Icon icon="PEOPLE" fill="#ADB0BC" size="xs" />
                                <span>{item.userName}</span>
                              </div>
                            ))}
                          {chat.chatType === 'DEAL' && (
                            <>
                              <div className={styles.contactName}>
                                <Icon icon="ruble" fill="#ADB0BC" size="xs" />
                                <span>{chat.chatName}</span>
                              </div>
                              <div className={styles.contactName}>
                                <Icon icon="today" fill="#ADB0BC" size="xs" />
                                <span>{normalizeDate(chat.createdAt)}</span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {chat.messages && (
                        <div className={styles.message}>
                          {chat.messages[0].message !== null ? (
                            <>
                              {chat.messages[0].memberName && (
                                <div className={styles.messageOwner}>
                                  {chat.messages[0].memberName}
                                </div>
                              )}

                              <span className={styles.foundMessage}>
                                <Hightlight
                                  str={getMessage(chat.messages[0].message)}
                                  filter={
                                    searchParam.find(
                                      (item) => item.type === 'queryString'
                                    )?.name ?? ''
                                  }
                                />
                              </span>
                            </>
                          ) : null}
                          <span className={styles.more}>
                            {countMessages ? `Еще ${countMessages}` : null}
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};
