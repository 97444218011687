import 'dayjs/locale/ru';

import { ConfigProvider } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import generatePicker from 'antd/es/date-picker/generatePicker';
import locale from 'antd/es/date-picker/locale/ru_RU';
import dayjs, { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import React from 'react';
import { ControllerRenderProps, UseFormSetValue } from 'react-hook-form';

import { ITicketFormValues } from 'src/types';

import 'src/components/atoms/DatePickerModal/styles.css';
import styles from 'src/components/atoms/DatePickerModal/styles.module.css';

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

export type Props = {
  onChange?: (date: string | null) => void;
  label?: string;
  field?: ControllerRenderProps<ITicketFormValues, any>;
  presets?: { label: React.ReactNode; value: Dayjs }[];
  dateFrom?: Dayjs | null;
  dateTo?: Dayjs | null;
  leg?: 'shortLeg' | 'longLeg';
  setValue?: UseFormSetValue<ITicketFormValues>;
};

export const DatePickerModal: React.FC<Props> = ({
  field,
  label,
  presets,
  dateFrom,
  dateTo,
}) => {
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    if (dateFrom && dateTo) {
      return (
        current < dayjs().startOf('day') ||
        current.day() === 0 ||
        current.day() === 6 ||
        current < dateFrom ||
        current > dateTo
      );
    }
    if (dateTo) {
      return (
        current < dayjs().startOf('day') ||
        current.day() === 0 ||
        current.day() === 6 ||
        current < dateTo
      );
    }
    if (dateFrom) {
      return (
        current < dayjs().startOf('day') ||
        current.day() === 0 ||
        current.day() === 6 ||
        current < dateFrom
      );
    }

    return (
      current < dayjs().startOf('day') ||
      current.day() === 0 ||
      current.day() === 6
    );
  };

  return (
    <label htmlFor="" className={styles.wrapper}>
      <div className={styles.label}>{label}</div>
      <ConfigProvider>
        <DatePicker
          locale={locale}
          disabledDate={disabledDate}
          format="DD/MM/YYYY"
          placeholder="Дата"
          size="large"
          onChange={(date) => {
            field?.onChange?.(date);
          }}
          showToday={false}
          className="DatePickerModal"
          presets={presets}
          {...field}
        />
      </ConfigProvider>
    </label>
  );
};
