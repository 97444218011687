import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actions } from 'src/actions';
import { AuthRecord } from 'src/types';
import { setItem } from 'src/utils/storage';

export type State = {
  isLoggedIn: null | boolean;
  authRecord: null | AuthRecord;
};

const initialState: State = {
  isLoggedIn: false,
  authRecord: null,
};

const reducer = reducerWithInitialState<State>(initialState)
  .cases(
    [actions.api.access.signUp.started, actions.api.access.signIn.started],
    (state): State => ({
      ...state,
      isLoggedIn: null,
      authRecord: null,
    })
  )
  .cases(
    [actions.api.access.signUp.done, actions.api.access.signIn.done],
    (state, payload): State => {
      setItem('token', payload.result);

      return {
        ...state,
        isLoggedIn: true,
        authRecord: payload.result,
      };
    }
  )
  .case(
    actions.ui.access.signOut,
    (state): State => ({
      ...state,
      isLoggedIn: false,
      authRecord: null,
    })
  );

export const access = { initialState, reducer };
