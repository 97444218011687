import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';

import { Checkbox } from 'src/components/atoms/Checkbox';

import styles from 'src/components/atoms/TableHeaderCell/styles.module.css';

export type Props = {
  columnName?: string;
  categoryName: string;
  itemList: ListItem[];
  filterTickets?: (
    filterField: string,
    filteredData: string[] | string,
    filterType: 'date' | 'select' | 'name'
  ) => void;
};

export type ListItem = {
  key: string;
  label: string;
  isChecked?: boolean;
};

export const TableHeaderCell: React.FC<Props> = ({
  columnName,
  categoryName,
  itemList,
  filterTickets,
}) => {
  const [filter, setFilter] = useState<string>('');
  const [list, setList] = useState<ListItem[]>([]);

  const handleChecked = (key: string) =>
    setList((prev) =>
      prev.map((item) =>
        item.key === key ? { ...item, isChecked: !item.isChecked } : item
      )
    );

  useEffect(() => {
    setList(itemList ?? []);
  }, [itemList]);

  useEffect(() => {
    list.some((item) => item.isChecked !== undefined) &&
      columnName &&
      filterTickets?.(
        columnName,
        [...list.filter((item) => item.isChecked).map((item) => item.key)],
        'select'
      );
  }, [columnName, list]);

  return (
    <Autocomplete
      sx={{
        '& .css-i4bv87-MuiSvgIcon-root': {
          color: 'var(--COLOR_BACKGROUND)',
        },
        '& input': {
          background: 'transparent',
          border: 'none',
          cursor: 'pointer',
          padding: 0,
          height: '32px',
        },
      }}
      options={list}
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <li
          {...props}
          className={styles.listItem}
          onClick={() => handleChecked(option.key)}
        >
          <span className={styles.labelOnList}>{option.label}</span>
          <Checkbox checked={option.isChecked} />
        </li>
      )}
      className={styles.wrapper}
      renderInput={(params) => (
        <div ref={params.InputProps.ref}>
          <TextField
            sx={{
              '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                padding: 0,
              },
              '& .MuiInputBase-root': {
                color: 'var(--COLOR_BACKGROUND)',
                fontSize: '14px',
                padding: '1px 2px',
                fontFamily: 'Roboto Flex, sans-serif',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
              },
              '& fieldset': {
                border: 'none',
              },
            }}
            type="text"
            placeholder={categoryName}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            {...params}
          />
        </div>
      )}
    />
  );
};
