import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { ContactsProfile } from 'src/components/molecules/ContactsProfile';
import { ChatDialogPanel } from 'src/components/organisms/ChatDialogPanel';
import { ChatInfo } from 'src/components/organisms/ChatInfo';
import {
  Position,
  TypeDialogPanelProp,
} from 'src/components/organisms/ChatInstance';
import { ReduxState } from 'src/reducers';
import { ChatInstance, ID } from 'src/types';

export type Props = {
  activeChatId: ChatInstance['activeChatId'];
  activeGroupId: ChatInstance['activeGroupId'];
  isShown: boolean;
  setIsShown: (value: React.SetStateAction<boolean>) => void;
  position: Position;
  setPosition: React.Dispatch<React.SetStateAction<Position>>;
  setTypePanel: (value: React.SetStateAction<TypeDialogPanelProp>) => void;
  typePanel: TypeDialogPanelProp;
};

export const TypeDialogPanel: React.FC<Props> = ({
  activeChatId = '',
  activeGroupId,
  isShown,
  setIsShown,
  position,
  setPosition,
  typePanel,
  setTypePanel,
}) => {
  const [memberId, setMemberId] = useState<ID>('');
  const currentChat = useSelector(
    (state: ReduxState) => state.chat.chats[activeChatId]
  );
  const currentUserId = useSelector(
    (state: ReduxState) => state.access.authRecord?.userId
  );

  if (typePanel === 'chat')
    return (
      <ChatDialogPanel
        activeChatId={activeChatId}
        activeGroupId={activeGroupId}
        isShown={isShown}
        setIsShown={setIsShown}
        position={position}
        setPosition={setPosition}
        setTypePanel={setTypePanel}
        setMemberId={setMemberId}
      />
    );

  if (typePanel === 'infoAboutUser')
    return (
      <ContactsProfile
        memberId={memberId}
        setTypePanel={setTypePanel}
        chatType={currentChat?.chatType.code ?? 'P2P'}
      />
    );

  return (
    <ChatInfo
      chatId={activeChatId}
      setTypePanel={setTypePanel}
      setMemberId={setMemberId}
      currentUserId={currentUserId}
    />
  );
};
