import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

export enum Format {
  full = 'DD.MM.YY HH:mm:ss',
  short = 'DD.MM.YY',
  toBack = 'YYYY-MM-DD HH:mm:ss',
  time = 'HH:mm:ss',
  shortRussian = 'DD.MM.YYYY',
  dateTime = 'DD.MM.YYYY / HH:mm:ss',
}

export const DATE_NOW = dayjs().endOf('day').format(Format['toBack']);
export const DATE_NOW_MINUS_DAY = dayjs()
  .startOf('day')
  .subtract(1, 'day')
  .format(Format['toBack']);
export const DATE_NOW_MINUS_60DAYS = dayjs()
  .startOf('day')
  .subtract(60, 'day')
  .format(Format['toBack']);

export const DATE_NOW_MINUS_WEEK = dayjs()
  .startOf('day')
  .subtract(7, 'day')
  .format(Format['full']);

export const compareLaterDates = (dateFirst: string, dateSecond: string) => {
  return dayjs(dateFirst).diff(dayjs(dateSecond)) > 0;
};

export const normalizeDate = (
  date: string | Dayjs,
  setting: keyof typeof Format = 'full',
  isUTS?: boolean
) => {
  if (isUTS) {
    dayjs.extend(utc);

    return typeof date === 'string'
      ? dayjs(date).utc().format(Format[setting])
      : date?.utc().format(Format[setting]);
  }

  return typeof date === 'string'
    ? dayjs(date).format(Format[setting])
    : date?.format(Format[setting]);
};

export const normalizeDateForSearch = (
  date: string,
  setting: keyof typeof Format = 'full',
  isEndDay = false,
  isUTS?: boolean
) => {
  if (isUTS) {
    dayjs.extend(utc);

    return isEndDay
      ? dayjs(date).utc().endOf('day').format(Format[setting])
      : dayjs(date).utc().startOf('day').format(Format[setting]);
  }

  return isEndDay
    ? dayjs(date).endOf('day').format(Format[setting])
    : dayjs(date).startOf('day').format(Format[setting]);
};

export const checkDeleteTime = (date: string) =>
  dayjs(Date.now()).diff(date, 'hour') < 24;

export const subtractOneDay = (date: string) =>
  dayjs(date).subtract(1, 'day').format(Format.toBack);

export const normalizeShortDate = (date: string) =>
  dayjs(date).format(Format.short);

export const normalizeTime = (date: string) => dayjs(date).format(Format.time);

export const isSameDate = (date1: string, date2: string) =>
  dayjs(date1).isSame(dayjs(date2), 'day');

export const convertCurrentDate = (
  date: string,
  setting: keyof typeof Format = 'full'
): string => {
  dayjs.extend(utc);
  return dayjs.utc(date).utcOffset(dayjs().utcOffset()).format(Format[setting]);
};
