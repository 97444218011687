import { saveAs } from 'file-saver';
import { takeEvery } from 'redux-saga/effects';
import { Action, Success } from 'typescript-fsa';

import { actions } from 'src/actions';
import { Actions } from 'src/types';

// eslint-disable-next-line require-yield
function* downloadGenerator(
  action: Action<
    Success<Actions.api.media.download.started, Actions.api.media.download.done>
  >
) {
  saveAs(action.payload.result, action.payload.params.extra.name);
}

export function* mediaSaga() {
  yield takeEvery(actions.api.media.download.done.type, downloadGenerator);
}
