// import classNames from 'classnames';
import { Modal as Modal_MUI } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';

// import styles from 'src/components/atoms/Button/styles.module.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}; //TODO: refactor
const modalStyle = {
  zIndex: '1200',
}; //TODO: refactor

export type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  handleClose: () => void;
  container?: HTMLDivElement | null;
};

export const Modal: React.FC<Props> = ({
  isOpen,
  handleClose,
  container,
  children,
}) => {
  return (
    <div>
      <Modal_MUI
        container={container}
        sx={modalStyle}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{children}</Box>
      </Modal_MUI>
    </div>
  );
};
