import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actions } from 'src/actions';
import { Temp } from 'src/types';

export type State = {
  loaded: number;
  total: number;
  file: File | undefined;
  blastFile: File | undefined;
  temp: Temp;
};

const initialState: State = {
  loaded: 0,
  total: 0,
  file: undefined,
  blastFile: undefined,
  temp: { users: [], tempFilesBeforeSending: [] },
};

const reducer = reducerWithInitialState<State>(initialState)
  .case(
    actions.ui.media.progress,
    (state, payload): State => ({
      ...state,
      loaded: payload.loaded,
      total: payload.total,
    })
  )
  .case(actions.ui.media.prepareToSendTemp, (state, payload) => {
    const tempFiles = state.temp.tempFilesBeforeSending;
    const tempFilesCopy = tempFiles && [...tempFiles];
    const equalIndex = tempFilesCopy?.findIndex(
      (tempFile) => tempFile.id === payload.id
    );
    if (equalIndex !== undefined && equalIndex !== -1 && tempFilesCopy) {
      tempFilesCopy[equalIndex].file = payload.file;
    } else if (payload.file === undefined && tempFilesCopy) {
      tempFilesCopy.slice(equalIndex, 1);
    } else {
      tempFilesCopy?.push(payload);
    }
    return {
      ...state,
      file: payload.file,
      temp: { ...state.temp, tempFilesBeforeSending: tempFilesCopy },
    };
  })
  .case(
    actions.ui.media.prepareToSendBlast,
    (state, payload): State => ({
      ...state,
      blastFile: payload.file,
    })
  );

export const media = { initialState, reducer };
