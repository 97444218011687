import 'dayjs/locale/ru';

import { ConfigProvider } from 'antd';
import generatePicker from 'antd/es/date-picker/generatePicker';
import locale from 'antd/es/date-picker/locale/ru_RU';
import dayjs, { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import React from 'react';

import { normalizeDateForSearch } from 'src/utils/date';

import 'src/components/atoms/DatePickerRange/styles.css';

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

export type Props = {
  dateRange: RangeValue<Dayjs>;
  setDateRange: React.Dispatch<React.SetStateAction<RangeValue<Dayjs>>>;
  isHeader?: boolean;
  filterTickets?: (
    filterField: string,
    filteredData: string[] | string,
    filterType: 'date' | 'select' | 'name'
  ) => void;
};

export const DatepickerRange: React.FC<Props> = ({
  dateRange,
  setDateRange,
  isHeader,
  filterTickets,
}) => {
  const [value, setValue] = React.useState<RangeValue<Dayjs>>(dateRange);
  const handleChange = (newValue: RangeValue<Dayjs>) => {
    setValue(newValue);
    setDateRange(newValue);
    isHeader &&
      filterTickets?.(
        'date',
        newValue && newValue[0] && newValue[1]
          ? [
              normalizeDateForSearch(newValue[0].toString(), 'toBack', false),
              normalizeDateForSearch(newValue[1].toString(), 'toBack', true),
            ]
          : [
              normalizeDateForSearch(dayjs().toString(), 'toBack', false, true),
              normalizeDateForSearch(dayjs().toString(), 'toBack', true, true),
            ],
        'date'
      );
  };

  return (
    <ConfigProvider>
      <DatePicker.RangePicker
        locale={locale}
        format="DD.MM.YYYY"
        value={value}
        onChange={(newValue) => handleChange(newValue)}
        separator=""
        placeholder={['с', 'по']}
        className={isHeader ? 'headerStyle' : ''}
      />
    </ConfigProvider>
  );
};
