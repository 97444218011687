import { BlotterColumn } from 'src/types';

export const deals = { all: 61, confirmed: 3, unconfirmed: 58 };
export const columns: BlotterColumn[] = [
  {
    title: 'Класс актива',
    key: 0,
    name: 'activeClassDescription',
    isChecked: true,
    columnType: 'autoComplete',
    data: 'activeClass',
    filterField: 'activeClassId',
    order: 0,
    width: 130,
  },
  {
    title: 'Инструмент',
    key: 1,
    name: 'instrumentDescription',
    isChecked: true,
    columnType: 'autoComplete',
    data: 'instrument',
    filterField: 'instrumentId',
    order: 1,
    width: 130,
  },
  {
    title: 'Дата & Время',
    key: 3,
    name: 'createdAt',
    isChecked: true,
    isSort: 'desc',
    columnType: 'dateTime',
    filterField: 'date',
    order: 3,
    width: 210,
  },
  {
    title: 'Направление',
    key: 4,
    name: 'transactionDirectionDescription',
    isChecked: true,
    columnType: 'autoComplete',
    data: 'transactionDirection',
    filterField: 'transactionDirectionId',
    order: 4,
    width: 130,
  },
  {
    title: 'Вал. пара',
    key: 5,
    name: 'currencyPair',
    isChecked: true,
    columnType: 'autoComplete',
    data: 'currencyPair',
    filterField: 'currencyPairId',
    order: 5,
    width: 130,
  },
  {
    title: 'Осн. валюта',
    key: 6,
    name: 'firstCurrency',
    isChecked: true,
    columnType: 'autoComplete',
    data: 'currency',
    filterField: 'firstCurrencyId',
    order: 6,
    width: 130,
  },
  {
    title: 'Контр. валюта',
    key: 7,
    name: 'secondCurrency',
    isChecked: true,
    columnType: 'autoComplete',
    data: 'currency',
    filterField: 'secondCurrencyId',
    order: 7,
    width: 130,
  },
  {
    title: 'Объем',
    key: 8,
    name: 'transactionVolume',
    isChecked: true,
    columnType: 'number',
    filterField: 'transactionVolumeSearch',
    order: 8,
    width: 130,
  },
  {
    title: 'Полная цена',
    key: 9,
    name: 'fullPrice',
    isChecked: true,
    columnType: 'number',
    filterField: 'fullPrice',
    order: 9,
    width: 130,
  },
  {
    title: 'Дата вал.',
    key: 10,
    name: 'valueDate',
    isChecked: true,
    columnType: 'date',
    order: 10,
    width: 130,
  },
  {
    title: 'Контрагент',
    key: 11,
    name: 'counterAgentTraderContactOrganization',
    isChecked: true,
    columnType: 'text',
    order: 11,
    width: 210,
  },
  {
    title: 'Своп пункты',
    key: 12,
    name: 'swapPoints',
    isChecked: false,
    columnType: 'number',
    filterField: 'swapPoints',
    order: 12,
    width: 130,
  },
  {
    title: 'Наименование валюты',
    key: 13,
    name: 'сurrencyName',
    isChecked: false,
    columnType: 'text',
    filterField: 'сurrencyName',
    order: 13,
    width: 130,
  },
  {
    title: 'Сумма кредита',
    key: 14,
    name: 'creditSum',
    isChecked: false,
    columnType: 'number',
    filterField: 'creditSum',
    order: 14,
    width: 130,
  },
  {
    title: 'Дата с',
    key: 15,
    name: 'dateFrom',
    isChecked: false,
    columnType: 'date',
    order: 15,
    width: 130,
  },
  {
    title: 'Дата по',
    key: 16,
    name: 'dateTo',
    isChecked: false,
    columnType: 'date',
    order: 16,
    width: 130,
  },
  {
    title: 'Дата уплаты процентов',
    key: 17,
    name: 'interestPaymentDate',
    isChecked: false,
    columnType: 'date',
    order: 17,
    width: 130,
  },
  {
    title: 'Процентная ставка',
    key: 18,
    name: 'interestRate',
    isChecked: false,
    columnType: 'number',
    filterField: 'interestRate',
    order: 18,
    width: 130,
  },
  {
    title: 'База расчета',
    key: 19,
    name: 'calculationBase',
    columnType: 'autoComplete',
    data: 'calculationBase',
    filterField: 'calculationBaseId',
    order: 19,
    width: 130,
  },
  {
    title: 'Трейдер',
    key: 20,
    name: 'traderContact',
    columnType: 'text',
    order: 20,
    width: 210,
  },
  {
    title: 'Статус',
    key: 21,
    name: 'ticketStatusDescription',
    columnType: 'autoComplete',
    data: 'ticketStatus',
    filterField: 'ticketStatusId',
    order: 21,
    width: 130,
  },

  {
    title: 'Трейдер контрагента',
    key: 22,
    name: 'counterAgentTraderContact',
    columnType: 'text',
    data: 'contact',
    order: 22,
    width: 210,
  },

  {
    title: 'Фирма трейдера',
    key: 23,
    name: 'traderContactOrganization',
    columnType: 'text',
    data: 'organization',
    order: 24,
    width: 210,
  },
  {
    title: 'Номер',
    key: 25,
    name: 'transactionNumber',
    columnType: 'text',
    filterField: 'dealCode',
    order: 25,
    width: 130,
  },
  {
    title: 'Спот',
    key: 26,
    name: 'spotPrice',
    columnType: 'number',
    filterField: 'spotPrice',
    order: 26,
    width: 130,
  },
  {
    title: 'Фрвд. пункты',
    key: 27,
    name: 'forwardPoints',
    columnType: 'number',
    order: 27,
    width: 130,
  },
  // -----------------------------------
  {
    title: 'Ближн. направл.',
    key: 28,
    name: 'shortLegTransactionDirection',
    columnType: 'text',
    order: 28,
    width: 130,
  },
  {
    title: 'Ближн. инстр.',
    key: 29,
    name: 'shortLegInstrument',
    columnType: 'text',
    order: 29,
    width: 130,
  },
  {
    title: 'Ближн. объем.',
    key: 30,
    name: 'shortLegTransactionVolume',
    columnType: 'number',
    order: 30,
    width: 130,
  },
  {
    title: 'Ближн. полн. цена',
    key: 31,
    name: 'shortLegFullPrice',
    columnType: 'number',
    order: 31,
    width: 130,
  },
  {
    title: 'Ближн. тенор',
    key: 32,
    name: 'shortLegTenor',
    columnType: 'text',
    order: 32,
    width: 130,
  },
  {
    title: 'Ближн. дата. вал.',
    key: 33,
    name: 'shortLegValueDate',
    columnType: 'date',
    order: 33,
    width: 130,
  },
  {
    title: 'Ближн. спот цена',
    key: 34,
    name: 'shortLegSpotPrice',
    columnType: 'number',
    order: 34,
    width: 130,
  },
  {
    title: 'Ближн. форв. пункты',
    key: 35,
    name: 'shortLegForwardPoints',
    columnType: 'number',
    order: 35,
    width: 130,
  },
  // ------------------------
  {
    title: 'Далн. направл.',
    key: 36,
    name: 'longLegTransactionDirection',
    columnType: 'text',
    order: 36,
    width: 130,
  },
  {
    title: 'Далн. инстр.',
    key: 37,
    name: 'longLegInstrument',
    columnType: 'text',
    order: 37,
    width: 130,
  },
  {
    title: 'Далн. объем.',
    key: 38,
    name: 'longLegTransactionVolume',
    columnType: 'number',
    order: 38,
    width: 130,
  },
  {
    title: 'Далн. полн. цена',
    key: 39,
    name: 'longLegFullPrice',
    columnType: 'number',
    order: 39,
    width: 130,
  },
  {
    title: 'Далн. тенор',
    key: 40,
    name: 'longLegTenor',
    columnType: 'text',
    order: 40,
    width: 130,
  },
  {
    title: 'Далн. дата. вал.',
    key: 41,
    name: 'longLegValueDate',
    columnType: 'date',
    order: 41,
    width: 130,
  },
  {
    title: 'Далн. спот цена',
    key: 42,
    name: 'longLegSpotPrice',
    columnType: 'number',
    order: 42,
    width: 130,
  },
  {
    title: 'Далн. форв. пункты',
    key: 43,
    name: 'longLegForwardPoints',
    columnType: 'number',
    order: 43,
    width: 130,
  },
];
