import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import ReactDOM from 'react-dom';

import { ModalPlaces } from 'src/types';

import styles from 'src/components/atoms/Portal/styles.module.css';

type Props = {
  children: ReactNode;
  nodeId: ModalPlaces;
  order?: number;
};

export const Portal: FC<Props> = ({ nodeId, children, order }) => {
  const node = document.querySelector(`#${nodeId}`);

  const selectedClass = classNames(styles.content, {
    [styles[`class${order}`]]: typeof order === 'number',
  });

  return (
    node &&
    ReactDOM.createPortal(
      <div className={styles.wrapper}>
        <div className={selectedClass}>{children}</div>
      </div>,
      node
    )
  );
};
