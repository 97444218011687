import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { App } from 'src/components/App';
import { Blotter } from 'src/components/organisms/Blotter';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: 'blotter',
        element: <Blotter />,
      },
    ],
  },
]);
