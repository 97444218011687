import classNames from 'classnames';
import React from 'react';

import { Checkbox } from 'src/components/atoms/Checkbox';

import styles from 'src/components/molecules/TableCell/styles.module.css';

type Size = 'm' | 's';
type Color = 'white' | 'gray';

export type Props = {
  id: string;
  size?: Size;
  children: React.ReactNode;
  handleCheckBox?: () => void;
  color?: Color;
};

export const TableCell: React.FC<Props> = ({
  id,
  size = 'm',
  handleCheckBox,
  color = 'white',
  children,
}) => {
  const wrapperClass = classNames(styles.wrapper, styles[size], styles[color]);

  return (
    <div className={wrapperClass}>
      {handleCheckBox && (
        <Checkbox id={id} toggleSelectedLine={handleCheckBox} />
      )}
      <div>{children}</div>
    </div>
  );
};
