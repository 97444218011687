import classNames from 'classnames';
import React, { ReactElement, useState } from 'react';

import { Icon } from 'src/components/atoms/Icon';
import { User } from 'src/types';

import styles from 'src/components/molecules/Accordion/styles.module.css';

export type Props = {
  people: User[];
  totalItems: string | number;
  children: ReactElement | ReactElement[];
  title?: string;
  isActive?: boolean;
};

export const AccordionPeople: React.FC<Props> = ({
  people,
  totalItems,
  children,
  title,
  isActive: isDefaultActive,
}) => {
  const [isActive, setIsActive] = useState(isDefaultActive);
  const handleActive = (e: React.MouseEvent<HTMLElement>) => {
    const { tagName } = e.target as HTMLButtonElement;
    if (tagName !== 'INPUT') {
      setIsActive(!isActive);
    }
  };
  const arrowClass = classNames(styles.accordionArrow, {
    [styles.isActive]: !isActive,
  });
  const activePeopleCount = people.reduce(
    (count, current) => count + (current.status === 'ONLINE' ? 1 : 0),
    0
  );

  return (
    <>
      <button className={styles.header} onClick={handleActive}>
        <span className={arrowClass}>
          <Icon icon="accordionArrow" size="m" />
        </span>

        <div className={styles.title}>{title}</div>
        <div className={styles.count}>
          {activePeopleCount} / {totalItems}
        </div>
      </button>

      {isActive && <div className={styles.content}>{children}</div>}
    </>
  );
};
