import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { EditingGroupChatPanel } from 'src/components/molecules/EditingGroupChatPanel';
import { TypeDialogPanelProp } from 'src/components/organisms/ChatInstance';
import { SectionHeader } from 'src/components/organisms/SectionHeader';
import { ReduxState } from 'src/reducers';
import { Chat, ChatMember, ID } from 'src/types';
import { parssRoles } from 'src/utils/chat';

import styles from 'src/components/organisms/ChatInfo/styles.module.css';

export type Props = {
  chatId: Chat['chatId'];
  setTypePanel: (value: React.SetStateAction<TypeDialogPanelProp>) => void;
  isDeal?: boolean;
  setMemberId: React.Dispatch<React.SetStateAction<string>>;
  currentUserId?: ID;
};

type userRoles = {
  admins: ChatMember[];
  members: ChatMember[];
  owners: ChatMember[];
};

export const ChatInfo: React.FC<Props> = ({
  chatId,
  setTypePanel,
  setMemberId,
  currentUserId,
}) => {
  const [userRoles, setUserRoles] = useState<userRoles>({
    admins: [],
    members: [],
    owners: [],
  });
  const currentChat = useSelector(
    (state: ReduxState) => state.chat.chats[chatId]
  );

  useEffect(() => {
    setUserRoles(parssRoles(currentChat));
  }, [currentChat?.members]);

  return (
    <div className={styles.wrapper}>
      <SectionHeader
        backButton
        closeButton
        title="Профиль"
        activeChatId={chatId}
        setTypePanel={setTypePanel}
      />
      <div className={styles.messagesWrapper}>
        <EditingGroupChatPanel
          value={currentChat?.name}
          isChatInfo
          setTypePanel={setTypePanel}
          activeGroupId={currentChat?.groupId}
          admins={userRoles.admins}
          members={userRoles.members}
          owners={userRoles.owners}
          currentChat={currentChat}
          setMemberId={setMemberId}
          currentUserId={currentUserId}
          isDeal={currentChat?.chatType.code === 'DEAL'}
        />
      </div>
    </div>
  );
};
