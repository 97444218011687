import { Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { ReduxState } from 'src/reducers';

import styles from 'src/components/organisms/AlertNotification/styles.module.css';

export const AlertNotification: React.FC = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string | undefined>('');

  const error = useSelector((state: ReduxState) => state.chat.error);

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setVisible(false);
        setAlertMessage('');
        dispatch(actions.ui.chat.updateErrorMessage({ message: '' }));
      }, 5000);
    }
  }, [visible]);

  useEffect(() => {
    alertMessage !== '' && setVisible(true);
  }, [alertMessage]);

  useEffect(() => {
    error !== '' && setAlertMessage(error);
  }, [error]);

  return (
    <>
      {visible && (
        <Alert
          className={styles.alertWrapper}
          onClose={() => {
            setVisible(false);
          }}
          variant="filled"
          severity="error"
        >
          {alertMessage}
        </Alert>
      )}
    </>
  );
};
