const AUTH_ENDPOINTS = {
  SIGN_IN: '/public/v1/auth/login',
  SIGN_UP: '/public/v1/auth/registration',
} as const;

const CHAT_ENDPOINTS = {
  CHAT_GROUPS_LIST: '/private/v1/chat-groups/list-autostart',
  CHAT_CONTACTS_LIST: '/private/v1/contact-directory/list',
  CHAT_MESSAGES_LIST: '/private/v1/message/user-messages',
  CHAT_DELETE: '/public/v1/chat-groups',
  CHAT_EDIT: '/private/v1/chat-groups',
  CHAT_SEND_TO_MAIL: '/private/v1/sender/send-mail-messages',
  CHAT_SEARCH_IN_CHATS_AND_MEMBERS: '/private/v1/cross-query',
  CHAT: '/private/v1/chat-groups',
  CHAT_CAHT_INFO: '/private/v1/chat-groups/get',
  CHAT_USERS_LIST: '/private/v1/chat-user/get',
  CHAT_MESSAGES_READ: '/private/v1/message/read',
  CHAT_GLOBAL_SEARCH: '/private/v1/chat/entities',
} as const;

const MEDIA_ENDPOINTS = {
  MEDIA_UPLOAD: '/private/v1/storage/upload',
  MEDIA_DOWNLOAD: '/private/v1/storage/download',
} as const;

const DICTIONARIES_ENDPOINTS = {
  DICTIONARY_ACTIVE_CLASS: '/private/v1/active-class/get',
  DICTIONARY_INSTRUMENT: '/private/v1/instrument/get',
  DICTIONARY_CALCULATION_BASE: '/private/v1/calculation-base/get',
  DICTIONARY_CURRENCY: '/private/v1/currency/get',
  DICTIONARY_CURRENCY_PAIR: '/private/v1/currency-pair',
  DICTIONARY_TICKET_STATUS: '/private/v1/ticket-status/get',
  DICTIONARY_TRANSACTION_DIRECTION: '/private/v1/transaction-direction/get',
  DICTIONARY_CONTACT: '/private/v1/contact/contacts',
  DICTIONARY_ORGANIZATION: '/private/v1/organization/organizations',
  DICTIONARY_TENOR: '/private/v1/tenor/get',
} as const;

const BLOTTER_ENDPOINTS = {
  SET_TIME_ZONE_DATA: '/private/v1/settings',
  BLOTTER_TICKETS_LIST: '/private/v1/ticket/tickets',
  BLOTTER_TICKETS_CREATE: '/private/v1/ticket',
  BLOTTER_TICKET_STATUS: '/private/v1/ticket/status',
  BLOTTER_TICKET: '/private/v1/ticket',
};

export const ENDPOINTS = {
  ...AUTH_ENDPOINTS,
  ...CHAT_ENDPOINTS,
  ...MEDIA_ENDPOINTS,
  ...DICTIONARIES_ENDPOINTS,
  ...BLOTTER_ENDPOINTS,
} as const;

export type Endpoints = typeof ENDPOINTS;
