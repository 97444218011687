import { Dictionaries } from 'src/types';

export const normalizeSimpleDictionary = (
  item: Dictionaries[
    | 'activeClass'
    | 'instrument'
    | 'calculationBase'
    | 'ticketStatus'
    | 'transactionDirection'
    | 'currency']
) =>
  item?.map((item) => ({
    id: item.id,
    title: item?.description ?? item.code,
    code: item.code,
  }));

export const normalizeCurrencyPairDictionary = (
  item: Dictionaries['currencyPair']
) =>
  item?.map((item) => ({
    id: item.id,
    title: item.name,
  }));

export const normalizeContactDictionary = (item: Dictionaries['contact']) =>
  item?.map((item) => ({
    id: item.id,
    title: `${item.first_name} ${item.last_name}`,
    organizationName: item.Organization.title,
  }));

export const normalizeOrganizationDictionary = (
  item: Dictionaries['organization']
) =>
  item?.map((item) => ({
    id: item.id,
    title: item.title,
  }));

export const normalizeCalculationBase = (
  item: Dictionaries['calculationBase']
) =>
  item?.map((item) => ({
    id: item.id,
    title: item.description,
  }));
