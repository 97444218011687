// import Popper from '@mui/material/Popper';
import { Alert } from '@mui/material';
import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { IconType } from 'src/components/atoms/Icon';
import { IconButton } from 'src/components/atoms/IconButton';
import {
  Position,
  TypeDialogPanelProp,
} from 'src/components/organisms/ChatInstance';
import { DropDownMenu } from 'src/components/organisms/DropDownMenu';
import { DropItem } from 'src/components/organisms/DropItemList';
import { SearchPanel } from 'src/components/organisms/SearchPanel';
import { ReduxState } from 'src/reducers';
import { ChatInstance, ChatType } from 'src/types';

import styles from 'src/components/organisms/SectionHeader/styles.module.css';

export type Functionality =
  | 'search'
  | 'add'
  | 'more'
  | 'blast'
  | 'volumeOff'
  | 'bell'
  | 'forwardto';

export type Props = {
  backButton?: boolean;
  handleBackButtonClick?: () => void;
  isEditMode?: boolean;
  title?: string;
  subTitle?: string;
  toggleEditMode?: () => void | (() => void);
  functionality?: Functionality[];
  activeChatId: ChatInstance['activeChatId'];
  dropItemsHeader?: DropItem[];
  dropItemsBody?: DropItem[];
  dropItemsFooter?: DropItem[];
  setFunctionality?: (f: Functionality[]) => void;
  isShown?: boolean;
  setIsShown?: (value: React.SetStateAction<boolean>) => void;
  position?: Position;
  setPosition?: React.Dispatch<React.SetStateAction<Position>>;
  setSearchMessageForSearchPanel?: React.Dispatch<React.SetStateAction<string>>;
  setTextSearchRefsArray?: HTMLElement[] | ((el: HTMLElement) => number);
  comingSearchString?: string;
  closeButton?: boolean;
  setTypePanel?: (value: React.SetStateAction<TypeDialogPanelProp>) => void;
  typePanelType?: TypeDialogPanelProp;
  chatType?: ChatType['code'];
  setMemberId?: React.Dispatch<React.SetStateAction<string>>;
  isModalOpen?: boolean;
  handleClose?: () => void;
};

export const SectionHeader: React.FC<Props> = ({
  backButton = false,
  handleBackButtonClick,
  isEditMode,
  title,
  subTitle,
  toggleEditMode,
  functionality = [],
  dropItemsHeader = [],
  dropItemsBody,
  dropItemsFooter,
  setFunctionality,
  activeChatId,
  isShown = false,
  setIsShown,
  position = { x: 0, y: 0 },
  setPosition,
  setSearchMessageForSearchPanel,
  setTextSearchRefsArray,
  closeButton,
  comingSearchString,
  setTypePanel,
  typePanelType,
  chatType,
  setMemberId,
  isModalOpen,
  handleClose,
}) => {
  const [isSearch, setIsSearch] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [visible, setVisible] = useState(false);

  const { currentChat, currentUserId } = useSelector((state: ReduxState) => ({
    currentChat: state.chat.chats[activeChatId ?? 'default'],
    currentUserId: state.access.authRecord?.userId,
  }));

  const colorItem = getComputedStyle(document.documentElement)
    .getPropertyValue('--COLOR_OUTLINE')
    .trim();

  const onSearchClick = () => {
    setIsShown?.(false);
    setIsClosed?.(false);
    if (setSearchMessageForSearchPanel) setSearchMessageForSearchPanel('');
    setIsSearch((prevState) => !prevState);
  };
  const dispatch = useDispatch();
  const forwardTo = () => {
    activeChatId &&
      dispatch(
        actions.api.chat.sendToEmail.started({
          roomId: activeChatId,
        })
      );
    setVisible(true);
    setIsShown?.(false);
  };

  const closeContextMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsShown?.(false);
    setIsClosed(false);
  };

  const showContextMenu = (e: React.MouseEvent<HTMLElement>) => {
    setIsSearch(false);
    if (!isClosed) {
      setIsShown?.(false);
      const newPosition = {
        x: e.pageX - 220,
        y: e.pageY + 15,
      };
      setPosition?.(newPosition);
      setIsShown?.(true);
      setIsClosed(!isClosed);
    } else {
      setIsClosed(!isClosed);
    }
  };

  const toggleBlast = () => {
    toggleEditMode && toggleEditMode();
    setIsShown?.(false);
    setIsClosed?.(false);
  };

  const handleEditChat = () => {
    if (currentChat) {
      const { chatId, settings, members } = currentChat;

      const membersFormat = members?.map(
        ({ name, organization, status, ...rest }) => {
          return rest;
        }
      );
      const membersFinal = membersFormat.map((member) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        member.roles = member.roles.map((role) => (role = role.roleName));
        return member;
      });

      dispatch(
        actions.api.chat.chatEdit.started({
          chatId,
          members: membersFinal,
          settings: settings.map((item) =>
            item.code === 'IS_MUTE'
              ? {
                  ...item,
                  value: item.value === 'true' ? 'false' : 'true',
                }
              : item
          ),
        })
      );
    }
  };

  const nameOfFunctions: Record<Functionality, () => void> = {
    search: onSearchClick,
    add: () => console.log('add'),
    blast: toggleBlast,
    more: () => console.log('morevert'),
    volumeOff: () => handleEditChat(),
    forwardto: forwardTo,
    bell: () => console.log(),
  };

  const updatedDropItemsHeader = dropItemsHeader?.map((item) => {
    const newItem = { ...item };
    if (newItem.iconStart) {
      newItem.action = nameOfFunctions[newItem.iconStart as Functionality];
    }
    return newItem;
  });

  const isMute = currentChat?.settings?.find(
    (item) => item.code === 'IS_MUTE'
  )?.value;
  const [volumeIcon, setVolumeIcon] = useState<IconType>(
    isMute === 'true' ? 'volumeOff' : 'unMute'
  );

  useEffect(() => {
    setVolumeIcon(isMute === 'true' ? 'volumeOff' : 'unMute');
  }, [isMute]);

  const nameToFunctionality: Record<Functionality, ReactNode> = {
    search: (
      <IconButton
        icon="search"
        onClick={onSearchClick}
        color={isSearch ? '#f2cd40' : colorItem}
      />
    ),
    add: (
      <IconButton
        icon="add"
        onClick={() => console.log('add')}
        color={colorItem}
      />
    ),
    more: (
      <IconButton
        icon="morevert"
        onClick={() => console.log('morevert')}
        color={colorItem}
      />
    ),
    blast: (
      <IconButton
        icon="blast"
        onClick={toggleBlast}
        active={isEditMode}
        color={colorItem}
      />
    ),
    volumeOff: (
      <IconButton
        icon={volumeIcon}
        size="m"
        onClick={() => handleEditChat()}
        color="#ADB0BC"
      />
    ),

    forwardto: (
      <IconButton
        icon="forwardto"
        onClick={nameOfFunctions['forwardto']}
        color={colorItem}
      />
    ),
    bell: null,
  };

  useEffect(() => {
    if (comingSearchString) setIsSearch(true);
  }, [comingSearchString]);

  useEffect(() => {
    if (isModalOpen) {
      setIsShown?.(false);
      setIsClosed?.(false);
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setVisible(false);
      }, 5000);
    }
  }, [visible]);

  return (
    <div className={styles.container}>
      {isShown && (
        <div>
          <div
            className={styles.blocker}
            role="presentation"
            onClick={closeContextMenu}
          ></div>
          <DropDownMenu
            dropItemsHeader={updatedDropItemsHeader}
            dropItemsBody={dropItemsBody}
            dropItemsFooter={dropItemsFooter}
            setFunctionality={setFunctionality}
            functionality={functionality}
            position={position}
          />
        </div>
      )}

      <div className={styles.header}>
        {backButton && (
          <div className={styles.back}>
            <IconButton
              size="xs"
              icon="arrowback"
              onClick={() =>
                handleBackButtonClick
                  ? handleBackButtonClick()
                  : setTypePanel?.(
                      typePanelType && chatType !== 'P2P'
                        ? typePanelType
                        : 'chat'
                    )
              }
              color={colorItem}
            />
          </div>
        )}
        <div className={styles.content}>
          <div className={styles.headertext}>
            {title && (
              <p
                style={{ cursor: chatType ? 'pointer' : 'default' }}
                className={styles.text}
                onClick={
                  chatType
                    ? chatType !== 'P2P'
                      ? () => setTypePanel?.('infoAboutChat')
                      : () => {
                          setMemberId?.(
                            currentChat?.members.find(
                              (member) => member.userId !== currentUserId
                            )?.userId ?? 'default'
                          );
                          setTypePanel?.('infoAboutUser');
                        }
                    : undefined
                }
              >
                {title}
              </p>
            )}
            <div className={styles.tools}>
              {functionality.map((name) => (
                <Fragment key={name}>{nameToFunctionality[name]}</Fragment>
              ))}

              {closeButton ? (
                <IconButton
                  icon="close"
                  onClick={() =>
                    handleClose ? handleClose() : setTypePanel?.('chat')
                  }
                  color={colorItem}
                />
              ) : (
                <IconButton
                  icon="morevert"
                  onClick={(e) => showContextMenu(e)}
                  color={colorItem}
                />
              )}
            </div>
          </div>
          <div className={styles.subtitle}>{subTitle && <p>{subTitle}</p>}</div>
        </div>
        {isSearch ? (
          <SearchPanel
            autoFocus
            setIsSearch={setIsSearch}
            setSearchMessage={setSearchMessageForSearchPanel}
            setTextSearchRefsArray={setTextSearchRefsArray ?? undefined}
            comingSearchString={comingSearchString ?? ''}
            setTypePanel={setTypePanel}
          />
        ) : null}
      </div>
      {visible && (
        <Alert
          className={styles.alertWrapper}
          onClose={() => {
            setVisible(false);
          }}
          variant="filled"
          security="success"
        >
          Операция успешно выполнена!
        </Alert>
      )}
    </div>
  );
};
