import classNames from 'classnames';
import React from 'react';

import * as icons from 'src/components/atoms/Icon/icons';

import styles from 'src/components/atoms/Icon/styles.module.css';

export type IconType = keyof typeof icons;

export type IconSize = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'full';

export type Props = {
  fill?: string;
  icon?: IconType;
  size?: IconSize;
  handleClick?: () => void;
};

const sizeToClassName: Record<IconSize, string> = {
  xxs: styles.iconContainerXXS,
  xs: styles.iconContainerXS,
  s: styles.iconContainerS,
  m: styles.iconContainerM,
  l: styles.iconContainerL,
  xl: styles.iconContainerXL,
  full: styles.iconContainerFull,
};

export const Icon: React.FC<Props> = ({
  icon = 'none',
  fill,
  size = 'm',
  handleClick,
}) => {
  const additionalProps = fill ? { fill: fill } : null;
  const Component = icons[Object.keys(icons).includes(icon) ? icon : 'none'];

  return (
    <div className={classNames(sizeToClassName[size])}>
      <div className={styles.wrapper}>
        {icon && (
          <Component
            onClick={handleClick}
            {...additionalProps}
            className={styles.wrapperSvg}
          />
        )}
      </div>
    </div>
  );
};
