import React from 'react';

import styles from 'src/components/molecules/DropDownSubItem/styles.module.css';

import { Icon } from '../../atoms/Icon';

export type DropSubItem = {
  id: string;
  title: string;
  sortKey?: 'date' | 'name' | 'none';
  action: () => void;
};

export type Props = {
  subItem: DropSubItem;
};

export const DropDownSubItem: React.FC<Props> = ({ subItem }) => {
  const selectedType = localStorage.getItem('filterType');

  return (
    <div className={styles.wrapper} key={subItem.id}>
      <div
        className={styles.content}
        onClick={subItem.action}
        role="presentation"
      >
        <p className={styles.title}>{subItem.title}</p>
        {subItem.sortKey === selectedType && <Icon icon="check" />}
      </div>
    </div>
  );
};
