import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { IconButton } from 'src/components/atoms/IconButton';
import {
  ChatInstanceData,
  TypeDialogPanelProp,
} from 'src/components/organisms/ChatInstance';
import { SectionHeader } from 'src/components/organisms/SectionHeader';
import { ReduxState } from 'src/reducers';
import { Chat, ChatType, ID, Organization, User } from 'src/types';
import { CreatingChat, createChat } from 'src/utils/chat';

import styles from 'src/components/molecules/ContactsProfile/styles.module.css';

export type Props = {
  memberId?: ID;
  setTypePanel?: (value: React.SetStateAction<TypeDialogPanelProp>) => void;
  contact?: User;
  members?: User[];
  handleAdmins?: (id: string) => void;
  admins?: User[];
  handleMembers?: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    id: string
  ) => void;
  chatType?: ChatType['code'];
};

export const ContactsProfile: React.FC<Props> = ({
  memberId,
  setTypePanel,
  chatType,
}) => {
  const dispatch = useDispatch();
  const [contact, setContact] = useState<User>();
  const users = useSelector((state: ReduxState) => state.chat.temp.users);

  const goToActiveChat = (chatId: Chat['chatId'] | undefined) => {
    if (typeof chatId !== 'undefined') {
      dispatch(
        actions.ui.chat.changeActiveChat({
          id: 'default',
          activeChatId: chatId,
        })
      );
    }
  };

  const goToActiveGroup = (group: string | undefined) =>
    dispatch(
      actions.ui.chat.changeActiveGroup({
        id: 'default',
        activeGroupId: group,
      })
    );

  useEffect(() => {
    dispatch(
      actions.api.chat.chatUsersList.started({
        userId: memberId,
        organization: 'true',
      })
    );
  }, []);

  useEffect(() => {
    if (users && users[0]?.userId === memberId) setContact(users[0]);
    return () => setContact(undefined);
  }, [users]);

  const { userId, chats, group } = useSelector((state: ReduxState) => ({
    chats: state.chat.chats,
    userId: state.access.authRecord?.userId,
    group: state.chat.groups,
  }));

  const handleClick = (newChat: CreatingChat) => {
    const existChat = Object.values(chats).find((item) => {
      if (
        item?.members.some((i) => i.userId === memberId) &&
        item?.members.some((i) => i.userId === userId) &&
        item?.chatType.code === 'P2P'
      ) {
        return item;
      }
    });
    if (existChat !== undefined) {
      const existGroup = group.find((item) =>
        item.chats.some((i) => i === existChat.chatId)
      );
      goToActiveGroup(existGroup?.chatGroupId);
      goToActiveChat(existChat?.chatId);
    } else {
      dispatch(
        actions.api.chat.newChat.started({
          ...createChat(newChat),
          extra: { update: true },
        })
      );
    }
  };

  const { activeGroupId } = useContext(ChatInstanceData);

  return (
    <div className={styles.wrapper}>
      <SectionHeader
        backButton
        closeButton
        title="Профиль"
        setTypePanel={setTypePanel}
        activeChatId={activeGroupId}
        typePanelType="infoAboutChat"
        chatType={chatType}
      />
      <div className={styles.messagesWrapper}>
        {contact ? (
          <div className={styles.wrapperContact}>
            <div className={styles.contactHeader}>
              {/* <UserStatus status={contact.status} /> */}
              <h1>{contact?.name}</h1>
              <div className={styles.iconSelection}>
                <div className={styles.iconMargin}>
                  <IconButton
                    icon="question"
                    onClick={() => {
                      if (memberId && setTypePanel) {
                        handleClick({
                          members: [
                            {
                              userId: userId ?? 'default',
                              roles: ['Owner', 'ChatAdmin'],
                              name: 'Отправитель',
                            },
                            {
                              userId: memberId,
                              roles: ['ChatMember'],
                              name: 'default',
                            },
                          ],
                          groups: [{ chatGroupId: activeGroupId }],
                        });
                        setTypePanel('chat');
                      }
                    }}
                  />
                </div>
                {/* <Icon icon="starBorder" fill="#ADB0BC" /> */}
              </div>
            </div>
            <div className={styles.fields}>
              <div>
                <span>Должность</span>
                <p>{contact?.position ?? ''}</p>
              </div>
              <div>
                <span>Организация</span>
                <p>{(contact.organization as Organization).title ?? ''}</p>
              </div>
              {/* <div>
                <span>Местоположение</span>
                <p>
                  {contact?.country === 'null, null' || !contact?.country
                    ? ''
                    : contact?.country}
                </p>
              </div> */}

              {/*<div>*/}
              {/*  <span>Местное время</span>*/}
              {/*  <p>{contact?.locationTime ?? ''}</p>*/}
              {/*</div>*/}
            </div>
            <div className={styles.fields}>
              <div>
                <span>Email</span>
                <p>{contact?.mail ?? ''}</p>
              </div>
              <div>
                <span>Телефон</span>
                <p>{contact?.phone ?? ''}</p>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
