import classNames from 'classnames';
import React from 'react';

import { Icon, IconSize, IconType } from 'src/components/atoms/Icon';

import styles from 'src/components/atoms/IconButton/styles.module.css';

export type Props = {
  color?: string;
  icon: IconType;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  size?: IconSize;
  active?: boolean;
  disabled?: boolean;
};

export const IconButton: React.FC<Props> = ({
  icon = 'none',
  color,
  onClick,
  size,
  active,
  disabled,
}) => (
  <button
    type="button"
    disabled={disabled}
    onClick={onClick}
    className={classNames([styles.wrapper, { [styles.disabled]: disabled }])}
  >
    <Icon
      fill={active ? 'var(--COLOR_SECONDARY)' : color}
      icon={icon}
      size={size}
    />
  </button>
);
