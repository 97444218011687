import classNames from 'classnames';
import { FC } from 'react';

import { Icon } from 'src/components/atoms/Icon';
import { Ticket } from 'src/types';

import styles from 'src/components/molecules/TicketContentRow/styles.module.css';

type Keys = keyof Ticket;
export type Values = Ticket[Keys];

export type Props = {
  field: string;
  value: Values;
};

export const TicketContentRow: FC<Props> = ({ field, value }) => {
  return (
    <li className={styles.itemWrapper}>
      <div className={styles.field}>{field}</div>
      <div
        className={classNames(styles.value, {
          [styles.accepted]: value === 'Заключена',
        })}
      >
        {value}
        {field === 'Направление' && (
          <Icon
            icon={
              value === 'Покупка' || value === 'Занять' ? 'dropUp' : 'dropDown'
            }
          />
        )}
      </div>
    </li>
  );
};
