import IconButton from '@mui/material/IconButton';
import imageCompression from 'browser-image-compression';
import classNames from 'classnames';
import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from 'src/actions';
import { Icon } from 'src/components/atoms/Icon';
import { Chat, ChatInstance } from 'src/types';

import styles from 'src/components/organisms/UploadPannel/styles.module.css';

const multiple = false;
const MAX_FILE_SIZE = 10000000;

export type Props = {
  type: 'click' | 'drop';
  children?: ReactElement | ReactElement[];
  chatId: Chat['chatId'] | Chat['chatId'][];
  toggleEditMode?: () => void;
  setAlertType?: (
    value: React.SetStateAction<'error' | 'success' | 'warning'>
  ) => void;
  setAlertMessage?: (value: React.SetStateAction<string>) => void;
  setVisible?: (value: React.SetStateAction<boolean>) => void;
  typeFooter?: 'main' | 'group';
  disabled?: boolean;
  activeChatId?: ChatInstance['activeChatId'];
};

const letters = {
  Ё: 'YO',
  Й: 'I',
  Ц: 'TS',
  У: 'U',
  К: 'K',
  Е: 'E',
  Н: 'N',
  Г: 'G',
  Ш: 'SH',
  Щ: 'SCH',
  З: 'Z',
  Х: 'H',
  Ъ: "'",
  ё: 'yo',
  й: 'i',
  ц: 'ts',
  у: 'u',
  к: 'k',
  е: 'e',
  н: 'n',
  г: 'g',
  ш: 'sh',
  щ: 'sch',
  з: 'z',
  х: 'h',
  ъ: "'",
  Ф: 'F',
  Ы: 'I',
  В: 'V',
  А: 'a',
  П: 'P',
  Р: 'R',
  О: 'O',
  Л: 'L',
  Д: 'D',
  Ж: 'ZH',
  Э: 'E',
  ф: 'f',
  ы: 'i',
  в: 'v',
  а: 'a',
  п: 'p',
  р: 'r',
  о: 'o',
  л: 'l',
  д: 'd',
  ж: 'zh',
  э: 'e',
  Я: 'Ya',
  Ч: 'CH',
  С: 'S',
  М: 'M',
  И: 'I',
  Т: 'T',
  Ь: "'",
  Б: 'B',
  Ю: 'YU',
  я: 'ya',
  ч: 'ch',
  с: 's',
  м: 'm',
  и: 'i',
  т: 't',
  ь: "'",
  б: 'b',
  ю: 'yu',
};
function transliterate(word: string): string {
  return word
    .split('')
    .map((item) => letters[item as keyof typeof letters] ?? item)
    .join('');
}

export const UploadPannel: React.FC<Props> = ({
  type,
  children,
  chatId,
  setAlertType,
  setAlertMessage,
  setVisible,
  typeFooter,
  disabled,
  activeChatId,
}) => {
  const dispatch = useDispatch();
  const [dragActive, setDragActive] = useState(false);

  const handleDrag = function (e: React.DragEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };
  const sizeControll = (fileList: FileList | null) => {
    if (fileList && fileList[0]) {
      if (
        typeFooter === 'group' &&
        typeof chatId !== 'string' &&
        chatId.length === 0
      ) {
        setAlertMessage?.('Должен быть выбран получатель рассылки!');
        setAlertType?.('error');
        setVisible?.(true);
      } else if (fileList[0].size > MAX_FILE_SIZE) {
        setAlertMessage?.('Файл слишком велик!');
        setAlertType?.('warning');
        setVisible?.(true);
      } else {
        handleFiles(fileList);
      }
    }
  };

  const handleDrop = function (e: React.DragEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    sizeControll(e.dataTransfer.files);
  };

  const handleChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    sizeControll(e.target.files);
    e.target.value = '';
  };

  const handleFiles = (items: FileList) => {
    const typeFile = items[0]?.name.split('.').pop();
    const newFile = new File([items[0]], transliterate(items[0]?.name), {
      type:
        typeFile &&
        ['jpeg', 'jpg'].find((item) => item === typeFile.toLowerCase())
          ? `image/${typeFile.toLowerCase()}`
          : typeFile?.toLowerCase(),
    });
    if (['image/jpeg', 'image/jpg'].find((item) => item === newFile.type)) {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
        fileType: 'image/jpeg',
      };
      imageCompression(newFile, options)
        .then((compressedFile) => {
          if (compressedFile.size < 500000) {
            if (typeFooter === 'main') {
              activeChatId &&
                dispatch(
                  actions.ui.media.prepareToSendTemp({
                    file: new File([compressedFile], newFile.name),
                    id: activeChatId,
                  })
                );
            }
            if (typeFooter === 'group') {
              dispatch(
                actions.ui.media.prepareToSendBlast({
                  file: new File([compressedFile], newFile.name),
                })
              );
            }
          } else {
            setAlertType?.('error');
            setAlertMessage?.('Размер файла превышает допустимый');
            setVisible?.(true);
          }
        })
        .catch(() => {
          setAlertType?.('error');
          setAlertMessage?.(
            'Неверный тип файла. Допустимые типы: jpeg, jpg, pdf, zip'
          );
          setVisible?.(true);
        });
    } else if (
      ['zip', 'pdf'].find((item) => item === newFile.type.toLowerCase()) &&
      newFile.size < MAX_FILE_SIZE
    ) {
      if (typeFooter === 'main') {
        activeChatId &&
          dispatch(
            actions.ui.media.prepareToSendTemp({
              file: newFile,
              id: activeChatId,
            })
          );
      }
      if (typeFooter === 'group') {
        dispatch(
          actions.ui.media.prepareToSendBlast({
            file: newFile,
          })
        );
      }
    } else {
      setAlertType?.('error');
      setAlertMessage?.(
        'Ошибка загрузки, проверьте тип или размер файла. Разрешено для загрузки zip, jpg/jpeg, pdf'
      );
      setVisible?.(true);
    }
  };

  return (
    <>
      {type === 'drop' && (
        <>
          {React.Children.map(children, (child) => {
            return React.cloneElement(child as ReactElement, {
              onDragEnter: handleDrag,
              onDragLeave: handleDrag,
              onDragOver: handleDrag,
              onDrop: handleDrop,
              className: classNames(
                child?.props.className,
                dragActive ? styles.drag : styles.nodrag
              ),
            });
          })}
        </>
      )}

      {type === 'click' && (
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
          size="small"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          className={styles.attach}
          disabled={disabled}
        >
          <input
            id="input-file-upload"
            type="file"
            hidden
            accept=".jpeg, .jpg, .pdf, .zip"
            multiple={multiple}
            onChange={handleChange}
          />
          <Icon fill={dragActive ? 'red' : '#6D7385'} icon="attach" />
        </IconButton>
      )}
    </>
  );
};
