import { Action } from 'redux';
import { END, TakeableChannel, Task, eventChannel } from 'redux-saga';
import {
  call,
  cancel,
  fork,
  put,
  select,
  spawn,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { Socket } from 'socket.io-client';
import { Action as FSAAction } from 'typescript-fsa';

import { actions } from 'src/actions';
import { ReduxState } from 'src/reducers';
import { Actions, AuthRecord } from 'src/types';
import { WebSocketConstructor } from 'src/utils/webSocket';

const webSocket = new WebSocketConstructor('chat');
let task: Task | null = null;

function subscribe(socket: Socket) {
  return eventChannel((emitter) => {
    socket.io.on('open', () => {
      webSocket.resetConnectionNumber();
      emitter(actions.ws.connected());
    });

    socket.io.on('close', (event) => {
      console.info(event);
      const resetWs = () => emitter(actions.ws.reset());
      const timeout = webSocket.connectionNumber * 1000;

      setTimeout(resetWs, timeout);
      webSocket.incConnectionNumber();
    });

    socket.io.on('error', (error) => {
      console.error('onerror', { error });
    });

    socket.onAny((event, data) => {
      if (event && data) {
        emitter(actions.ws.event(data));
      }
    });

    return () => {
      socket.close();
      emitter(END);
    };
  });
}

function* runWebSocket() {
  const authRecord: AuthRecord | null = yield select(
    (state: ReduxState) => state.access.authRecord
  );

  if (authRecord) {
    const socket: Socket = yield call(
      webSocket.connect.bind(webSocket, authRecord.accessToken)
    );

    const channel: TakeableChannel<unknown> = yield call(subscribe, socket);

    while (true) {
      const action: Action = yield take(channel);
      yield put(action);
    }
  }
}

function* startGenerator() {
  const userCanOpenWSConnection: boolean = yield select((state: ReduxState) =>
    Boolean(state.access.isLoggedIn)
  );

  if (!task && userCanOpenWSConnection) {
    task = yield fork(runWebSocket);
  }
}

function* stopGenerator() {
  if (task) {
    yield cancel(task);
    webSocket.close();
    task = null;
  }
}

function* resetGenerator() {
  if (task && !task.isRunning) {
    yield cancel(task);
    webSocket.reset();
    task = null;
    yield spawn(startGenerator);
  }
}

// eslint-disable-next-line require-yield
function* sendGenerator(action: FSAAction<Actions.ws.send>) {
  webSocket.socket?.emit(action.payload.type, action.payload.message);
}

export function* webSocketSaga() {
  yield takeEvery(
    [actions.ws.start.type, actions.api.chat.groupsList.done.type],
    startGenerator
  );

  yield takeEvery(
    [actions.ws.stop.type, actions.ui.access.signOut.type],
    stopGenerator
  );
  yield takeEvery(actions.ws.reset.type, resetGenerator);
  yield takeEvery(actions.ws.send.type, sendGenerator);
}
