import React from 'react';
import { useSelector } from 'react-redux';

import { ChatInstance } from 'src/components/organisms/ChatInstance';
import { ReduxState } from 'src/reducers';

import styles from 'src/components/organisms/Chat/styles.module.css';

export const Chat: React.FC = () => {
  const { instances, isLoggedIn } = useSelector((state: ReduxState) => ({
    instances: state.chat.instances,
    isLoggedIn: state.access.isLoggedIn,
  }));

  return (
    <div id="chat" className={styles.wrapper}>
      {isLoggedIn
        ? instances.map((instance) => (
            <ChatInstance key={instance.id} {...instance} />
          ))
        : null}
    </div>
  );
};
