import actionsFactory from 'typescript-fsa-factory';

import { ENDPOINTS, Endpoints } from 'src/constants/api';
import { Service } from 'src/constants/env';

export type ActionCreatorAsync = ReturnType<
  typeof actionCreatorAsyncWithHandler
>;

export const {
  actionCreator,
  actionCreatorAsyncWithHandler,
  registerAsyncActions,
} = actionsFactory<Endpoints, { service: Service }>(ENDPOINTS);
