import { Tooltip } from 'antd';
import Table from 'rc-table';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { DatepickerRange } from 'src/components/atoms/DatePickerRange';
import { Input } from 'src/components/atoms/Input/index';
import {
  ListItem,
  TableHeaderCell,
} from 'src/components/atoms/TableHeaderCell';
import { SimpleTabbar } from 'src/components/molecules/SimpleTabbar';
import { TableCell } from 'src/components/molecules/TableCell';
import { BlotterHeader } from 'src/components/organisms/BlotterHeader';
import { ModalStateContext } from 'src/components/providers/ModalProvider';
import { ReduxState } from 'src/reducers';
import {
  BlotterColumn,
  BlotterColumnHeaderFilterType,
  BlotterHeaderFilterType,
  Ticket,
} from 'src/types';
import { convertCurrentDate } from 'src/utils/date';

import styles from 'src/components/organisms/Blotter/index.module.css';

type columnOptionsType = {
  title: React.ReactNode;
  dataIndex: string;
  key: string;
  render: (value: string) => React.ReactNode;
}[];

const chooseColumnHeaderType = (
  item: BlotterColumn,
  dataList?: BlotterColumnHeaderFilterType[],
  filterTickets?: (
    filterField: string,
    filteredData: string[] | string,
    filterType: 'date' | 'select' | 'name'
  ) => void
): React.ReactNode => {
  if (!item.filterField)
    return <Input isHeader type="text" placeholder={item.title} disabled />;
  switch (item.columnType) {
    case 'autoComplete':
      return (
        <TableHeaderCell
          columnName={item.filterField}
          categoryName={item.title}
          itemList={
            dataList?.map((el) => ({
              key: el.id,
              label: el.name,
            })) as ListItem[]
          }
          filterTickets={filterTickets}
        />
      );
    case 'number':
      return (
        <Input
          filterTickets={filterTickets}
          headerColumnName={item.filterField}
          placeholder={item.title}
          type="text"
          isHeader
        />
      );
    case 'date':
      return (
        <DatepickerRange
          dateRange={null}
          setDateRange={() => console.log()}
          isHeader
          filterTickets={filterTickets}
        />
      );
    case 'dateTime':
      return (
        <DatepickerRange
          dateRange={null}
          setDateRange={() => console.log()}
          isHeader
          filterTickets={filterTickets}
        />
      );
    case 'text':
      return (
        <Input
          filterTickets={filterTickets}
          headerColumnName={item.filterField}
          placeholder={item.title}
          type="text"
          isHeader
        />
      );
    default:
      return null;
  }
};

export const Blotter: React.FC = () => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = React.useState<Ticket[]>([]);
  const [columnOptions, setColumnOptions] = React.useState<columnOptionsType>(
    []
  );

  const {
    setDefaultValue,
    setIsActiveTicket,
    setModalPlace,
    setIsActiveNotification,
    setDefaultNotification,
  } = useContext(ModalStateContext);

  const tickets = useSelector((state: ReduxState) => state.blotter.tickets);
  const { blotterColumns } = useSelector((state: ReduxState) => state.blotter);
  const dataFilter = useSelector(
    (state: ReduxState) => state.monitor.tableFilter
  );
  const sortField = useSelector((state: ReduxState) => state.blotter.sortField);
  const sortType = useSelector((state: ReduxState) => state.blotter.sortType);
  const filterObj = useSelector((state: ReduxState) => state.blotter.filterObj);
  const userId = useSelector(
    (state: ReduxState) => state.access.authRecord?.userId
  );

  const user = useSelector((state: ReduxState) => state.monitor.user);

  useEffect(() => {
    const result = Object.fromEntries(
      Object.entries(filterObj).filter(([, value]) => {
        return value.length !== 0 && value !== '';
      })
    );

    if (userId && Object.keys(result).length && user) {
      dispatch(
        actions.api.blotter.ticketsList.started({ ...result, extra: user })
      );
    }
  }, [filterObj, userId, user]);

  useEffect(() => {
    setTableData([...tickets]);
  }, [tickets, sortField, sortType]);

  const filterTickets = (
    filteredField: string,
    filteredData: string | string[],
    filterType: 'date' | 'select' | 'name'
  ) => {
    dispatch(
      actions.ui.blotter.changeFilter({
        filterField: filteredField,
        filterValue: filteredData,
        type: filterType,
      })
    );
  };

  useEffect(() => {
    const headerColumn = blotterColumns.filter((item) => item.isChecked);
    const options: columnOptionsType = headerColumn.map((item) => {
      const itemDataName = item.data as keyof BlotterHeaderFilterType;
      return {
        title: chooseColumnHeaderType(
          item,
          dataFilter[itemDataName] as BlotterColumnHeaderFilterType[],
          filterTickets
        ),
        dataIndex: item.name,
        className: styles[`wideColumn${item.width}` as never],
        key: item.name,
        render: (value: string) => (
          <TableCell id={value}>
            <Tooltip
              title={
                (item.columnType === 'date' &&
                  (value ? convertCurrentDate(value, 'shortRussian') : '-')) ||
                (item.columnType === 'dateTime' &&
                  (value ? convertCurrentDate(value, 'dateTime') : '-')) ||
                (value ?? '-')
              }
            >
              <span>
                {(item.columnType === 'date' &&
                  (value ? convertCurrentDate(value, 'shortRussian') : '-')) ||
                  (item.columnType === 'dateTime' &&
                    (value ? convertCurrentDate(value, 'dateTime') : '-')) ||
                  (value ?? '-')}
              </span>
            </Tooltip>
          </TableCell>
        ),
      };
    });

    setColumnOptions(options);
  }, [dataFilter, blotterColumns]);

  return (
    <div id="blotter" className={styles.wrapper}>
      <SimpleTabbar title="Блоттер" />
      <BlotterHeader />
      <Table
        className={styles.blotter}
        columns={columnOptions}
        emptyText=""
        rowClassName={(record) => {
          if (record.ticketStatus === 'RECEIVED')
            return styles.rowTicketReceive;
          if (record.ticketStatus === 'DECLINED')
            return styles.rowTicketDecline;
          if (
            record.ticketStatus === 'SENT' &&
            record.counterAgentTraderContactId !== userId
          )
            return styles.rowTicketSent;
          return styles.rowTicketDefault;
        }}
        data={
          filterObj.ticketStatusId?.some(
            (item) => item === 'd506957d-7d7a-40bd-a6bf-d15ab7c9e945' // Id of DECLINE ticket status
          )
            ? tableData
            : tableData.filter((item) => item.ticketStatus !== 'DECLINED')
        }
        rowKey="id"
        onRow={(record) => ({
          onClick: () => {
            if (
              record.ticketStatus === 'DECLINED' &&
              userId === record.traderContactId
            ) {
              setDefaultValue?.(record);
              setModalPlace?.('blotter');
              setIsActiveTicket?.(true);
            } else {
              setDefaultNotification?.([record]);
              setModalPlace?.('blotter');
              setIsActiveNotification?.(true);
            }
          },
        })}
      />
    </div>
  );
};
