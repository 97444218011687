import React from 'react';

import { Icon } from 'src/components/atoms/Icon';
import { ChatType, UserStatus } from 'src/types';

import styles from 'src/components/atoms/UserIdentifier/styles.module.css';

export type Props = {
  type: 'P2P' | 'avatar';
  icon?: ChatType['code'] | UserStatus | 'P2P';
};

export const UserIdentifier: React.FC<Props> = ({ icon, type }) => {
  return (
    <>
      {type !== 'P2P' ? (
        <div className={styles.wrapper}>
          <Icon icon={icon} />
        </div>
      ) : (
        <div className={styles.status}>
          <Icon icon={icon} size={icon === 'P2P' ? 's' : 'xxs'} />
        </div>
      )}
    </>
  );
};
