import React from 'react';

import { FileMessage } from 'src/components/molecules/FileMessage';
import { Message } from 'src/components/molecules/Message';
import { Hightlight } from 'src/components/organisms/Hightlight';
import { Chat, ChatMessage, ID, InternalFile } from 'src/types';

import styles from 'src/components/organisms/ChooseMessageType/styles.module.css';

import { MessageDeal } from '../../molecules/MessageDeal';

export type Props = {
  memberName?: string;
  viewName?: boolean;
  messageCreateAt: string;
  message: string | null;
  messageType: ChatMessage['type'];
  searchString: string;
  setRefsFunc?: HTMLElement[] | ((el: HTMLElement) => number);
  isNew?: boolean;
  chatId?: Chat['chatId'];
  messageId?: ID;
  company?: string;
  setAlertMessage?: (value: React.SetStateAction<string>) => void;
  setVisible?: (value: React.SetStateAction<boolean>) => void;
  isOwnerMessage?: boolean;
  isDeleted?: boolean;
};

export type DealMessageProp = {
  offer: {
    OFFER_ASK: string;
    OFFER_ASKSIZE: string;
    OFFER_BID: string;
    OFFER_BIDSIZE: string;
    OFFER_CREATE: string;
    OFFER_ID: string;
  };
  responsibleOrg: {
    ORG_ID: string;
    ORG_TITLE: string;
  };
  ticket: {
    TICKET_CODE: string;
    TICKET_ID: string;
    TICKET_TICKET: string;
  };
};

export type MessageObject = {
  message: string | null;
  type: 'blast' | 'message';
};

export type UserGroupChatType = {
  name: string;
  userId: string;
};

export type GroupChatFirstMessageType = {
  members: UserGroupChatType[];
  userCreate: UserGroupChatType;
};

const TryParseJson = (
  message: string | null
):
  | InternalFile
  | DealMessageProp
  | MessageObject
  | GroupChatFirstMessageType
  | string => {
  const msg = message ?? '';

  try {
    const parsed = JSON.parse(msg);
    return typeof parsed === 'object' ? parsed : msg;
  } catch (error) {
    // ignore
  }

  return msg;
};

const getMessage = (
  messageType: ChatMessage['type']
  // message: string | null
): string | undefined => {
  try {
    if (messageType === 'system.people') {
      // const msg = TryParseJson(message) as GroupChatFirstMessageType;
      // return `Создатель чата: \n${
      //   msg['userCreate']['name']
      // }\nУчастники чата: ${msg['members']?.map((user) => `\n${user.name} `)}`;
      return `Создан чат`;
    }
  } catch (error) {
    // ignore
  }
  return undefined;
};

export const ChooseMessageType: React.FC<Props> = ({
  memberName,
  viewName,
  messageCreateAt,
  message,
  messageType,
  searchString,
  setRefsFunc,
  isNew,
  chatId,
  messageId,
  company,
  setAlertMessage,
  setVisible,
  isOwnerMessage,
  // isDeleted,
}) => {
  const parsedMessage = TryParseJson(message);
  if ((parsedMessage as InternalFile).file_name) {
    if (!(parsedMessage as InternalFile).isDeleted) {
      return (
        <FileMessage
          isOwnerMessage={isOwnerMessage}
          user={viewName ? memberName : ''}
          company={company}
          fileName={(parsedMessage as InternalFile)['file_name']}
          fileSize={(parsedMessage as InternalFile)['size']}
          date={messageCreateAt}
          fileId={(parsedMessage as InternalFile)['id']}
          setAlertMessage={setAlertMessage}
          setVisible={setVisible}
          messageId={messageId}
          message={parsedMessage as InternalFile}
        />
      );
    } else {
      return (
        <Message
          date={messageCreateAt}
          messageType={messageType}
          message={`Пользователь ${memberName} удалил файл`}
        />
      );
    }
  }

  if ((parsedMessage as DealMessageProp).offer) {
    return (
      <div className={styles.dealMessageWrapper}>
        <MessageDeal
          company={
            (parsedMessage as DealMessageProp)['responsibleOrg']['ORG_TITLE']
          }
          date={(parsedMessage as DealMessageProp)['offer']['OFFER_CREATE']}
          currency={
            (parsedMessage as DealMessageProp)['ticket']['TICKET_TICKET']
          }
          code={(parsedMessage as DealMessageProp)['ticket']['TICKET_CODE']}
          ask={(parsedMessage as DealMessageProp)['offer'][
            'OFFER_ASK'
          ].toString()}
          askSize={(parsedMessage as DealMessageProp)['offer'][
            'OFFER_ASKSIZE'
          ].toString()}
          bid={(parsedMessage as DealMessageProp)['offer'][
            'OFFER_BID'
          ].toString()}
          bidSize={(parsedMessage as DealMessageProp)['offer'][
            'OFFER_BIDSIZE'
          ].toString()}
        />
      </div>
    );
  }

  return (
    <Message
      user={viewName ? memberName : ''}
      date={messageCreateAt || 'default'}
      company={company}
      messageType={messageType}
      isNew={isNew}
      chatId={chatId}
      messageId={messageId}
      isBlast={(parsedMessage as MessageObject)['type'] === 'blast'}
      message={
        <Hightlight
          setRefsFunc={setRefsFunc}
          filter={searchString}
          str={
            (parsedMessage as MessageObject)['message'] ??
            getMessage(messageType) ??
            message ??
            `${memberName} вызывает вас`
          }
        />
      }
    />
  );
};
