type PublicEnv =
  | 'local'
  | 'staging'
  | 'production'
  | 'beta'
  | 'alpha'
  | 'pre_alpha';
export type Service = 'auth' | 'chat' | 'media' | 'monitor';

export const PUBLIC_ENV = process.env.REACT_APP_PUBLIC_ENV as PublicEnv;
export const APP_VERSION = process.env.REACT_APP_VERSION || '';
export const INTEGRATIONS = {} as const;

const configs: Record<
  PublicEnv,
  {
    API_AUTH_HOST: string;
    API_CHAT_HOST: string;
    API_MEDIA_HOST: string;
    CLIENT_HOST: string;
    API_MONITOR_HOST: string;
  }
> = {
  local: {
    API_AUTH_HOST: '10.55.1.8:3390',
    API_CHAT_HOST: '10.55.1.8:3365',
    API_MEDIA_HOST: '10.55.1.8:4360',
    CLIENT_HOST: 'localhost:3000',
    API_MONITOR_HOST: '10.55.1.8:3367',
  },
  staging: {
    API_AUTH_HOST: '10.55.1.8:3390',
    API_CHAT_HOST: '10.55.1.8:3365',
    API_MEDIA_HOST: '10.55.1.8:4360',
    CLIENT_HOST: '10.55.1.8',
    API_MONITOR_HOST: '10.55.1.8:3367',
  },
  production: {
    API_AUTH_HOST: 'ftr-auth.dreamsoft.by',
    API_CHAT_HOST: 'beta-be.ftr-dealing.ru',
    API_MEDIA_HOST: 'beta-media.ftr-dealing.ru',
    CLIENT_HOST: 'beta-fe.ftr-dealing.ru',
    API_MONITOR_HOST: 'beta-media.ftr-dealing.ru',
  },
  beta: {
    API_AUTH_HOST: 'ftr-auth.dreamsoft.by',
    API_CHAT_HOST: 'beta-be.ftr-dealing.ru',
    API_MEDIA_HOST: 'beta-media.ftr-dealing.ru',
    CLIENT_HOST: 'beta-fe.ftr-dealing.ru',
    API_MONITOR_HOST: 'beta-monitor.ftr-dealing.ru',
  },
  alpha: {
    API_AUTH_HOST: 'ftr-auth.dreamsoft.by',
    API_CHAT_HOST: 'ftr-chat.dreamsoft.by',
    API_MEDIA_HOST: 'ftr-media.dreamsoft.by',
    CLIENT_HOST: 'ftr.dreamsoft.by',
    API_MONITOR_HOST: 'ftr-monitor.dreamsoft.by',
  },
  pre_alpha: {
    API_AUTH_HOST: '10.55.1.8:3390',
    API_CHAT_HOST: '10.55.1.8:3365',
    API_MEDIA_HOST: '10.55.1.8:3360',
    CLIENT_HOST: '10.55.1.8',
    API_MONITOR_HOST: '10.55.1.8:3367',
  },
};

export const {
  API_AUTH_HOST,
  API_CHAT_HOST,
  API_MEDIA_HOST,
  CLIENT_HOST,
  API_MONITOR_HOST,
} = configs[PUBLIC_ENV];
