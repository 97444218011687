import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { Button } from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import { IconButton } from 'src/components/atoms/IconButton';
import { BlotterFilterHeader } from 'src/components/organisms/BlotterFilterHeader';
import { ModalStateContext } from 'src/components/providers/ModalProvider';
import { ReduxState } from 'src/reducers';

import styles from 'src/components/organisms/BlotterHeader/styles.module.css';

export const BlotterHeader: React.FC = () => {
  const dispatch = useDispatch();
  const [isShowFilter, toggleFilter] = useState<boolean>(false);
  const [isMainDealsFilter, toggleMainDealsFilter] = useState<boolean>(true);

  const { setIsActiveTicket, setModalPlace } = useContext(ModalStateContext);

  const { headerConfig, user, filterObj, filterDate, blotterColumns } =
    useSelector((state: ReduxState) => ({
      headerConfig: state.blotter.headerConfig,
      user: state.monitor.user,
      filterField: state.blotter.filterField,
      filterObj: state.blotter.filterObj,
      filterDate: state.blotter.filterObj.date,
      blotterColumns: state.blotter.blotterColumns,
    }));

  const openModal = () => {
    setIsActiveTicket?.((prev) => !prev);
    setModalPlace?.('blotter');
  };

  useEffect(() => {
    if (filterObj.traderTickets?.length) {
      toggleMainDealsFilter(true);
    } else {
      toggleMainDealsFilter(false);
    }
  }, [filterObj]);

  const outputData = (): string => {
    if (filterDate && filterDate[0] && filterDate[1]) {
      const date1 = dayjs(filterDate[0]).format('DD.MM.YYYY');
      const date2 = dayjs(filterDate[1]).format('DD.MM.YYYY');
      if (date1 === date2) {
        return `Блоттер за ${date1}`;
      } else {
        return `Блоттер с ${date1} по ${date2}`;
      }
    } else {
      return `Блоттер за ${dayjs().format('DD.MM.YYYY')}`;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.leftBlock}>
        <div className={styles.time}>{outputData()}</div>
        <div className={styles.deals}>
          {`Всего сделок ${headerConfig?.allTickets}: 
          подтвержденных ${headerConfig?.acceptTickets} / 
          неподтвержденных ${headerConfig?.nonAcceptTickets}`}
        </div>
      </div>
      <div className={styles.rightBlock}>
        <div className={styles.toggle}>
          <button
            title="Мои сделки"
            type="button"
            className={classNames(styles.mainFilter, {
              [styles.isMainDealsFilter]: isMainDealsFilter,
            })}
            onClick={() => {
              toggleMainDealsFilter(true);
              if (user.userId) {
                dispatch(
                  actions.ui.blotter.setOwnerTicketsFilter({
                    userId: user.userId,
                  })
                );
              }
            }}
          >
            <Icon icon="P2P" />
          </button>
          <button
            title="Все сделки"
            type="button"
            className={classNames(styles.mainFilter, {
              [styles.isMainDealsFilter]: !isMainDealsFilter,
            })}
            onClick={() => {
              toggleMainDealsFilter(false);
              if (user.orgId) {
                if (user.userId) {
                  dispatch(
                    actions.ui.blotter.setOwnerTicketsFilter({
                      orgId: user.orgId,
                    })
                  );
                }
              }
            }}
          >
            <Icon icon="business" />
          </button>
        </div>
        <div className={styles.settings}>
          <IconButton
            icon="settings"
            onClick={() => toggleFilter((prev) => !prev)}
          />

          {isShowFilter && (
            <BlotterFilterHeader
              columns={blotterColumns}
              toggleFilter={toggleFilter}
            />
          )}
        </div>
        <Button size="m" iconStart="addBox" onClick={openModal}>
          TKT
        </Button>
      </div>
    </div>
  );
};
