import React from 'react';
import { Outlet } from 'react-router-dom';

import { AlertNotification } from 'src/components/organisms/AlertNotification/AlertNotification';
import { Chat } from 'src/components/organisms/Chat';
import { Provider } from 'src/components/providers/Provider';

import styles from 'src/components/index.module.css';

export const App: React.FC = () => (
  <Provider>
    <div data-testid="page" className={styles.app}>
      <Outlet />
      <Chat />
      <AlertNotification />
    </div>
  </Provider>
);
