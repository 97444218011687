import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { Checkbox } from 'src/components/atoms/Checkbox';
import { IconButton } from 'src/components/atoms/IconButton';
import { UserIdentifier } from 'src/components/atoms/UserIdentifier';
import { Bell } from 'src/components/organisms/Bell';
import { selectableContact } from 'src/components/organisms/ChatContactsPanel';
import {
  ChatInstanceData,
  TypeDialogPanelProp,
} from 'src/components/organisms/ChatInstance';
import { ReduxState } from 'src/reducers';
import { Chat, ChatInstance, ChatStatus, ChatType } from 'src/types';

import styles from 'src/components/molecules/Contact/styles.module.css';

export type Props = {
  name: string | string[];
  company?: string;
  type: ChatType['code'];
  status?: ChatStatus;
  isActive?: boolean;
  isCheckBox?: boolean;
  isSelectedLineProps?: boolean;
  chatId: Chat['chatId'];
  handleSelectedChatsClick: (
    chatId: Chat['chatId'],
    selectable: boolean
  ) => void;
  selectedChats: (Chat['chatId'] | undefined)[];
  instanceId: ChatInstance['id'];
  chatIds: Chat['chatId'][];
  setTypePanel: (value: React.SetStateAction<TypeDialogPanelProp>) => void;
  activeChatId: ChatInstance['activeChatId'];
};

export const Contact: React.FC<Props> = ({
  name,
  company,
  type,
  status,
  isActive,
  isCheckBox,
  chatId,
  handleSelectedChatsClick,
  isSelectedLineProps,
  selectedChats,
  instanceId,
  chatIds,
  setTypePanel,
}) => {
  const [isHover, setIsHover] = useState(false);
  const [isBurger, setIsBurger] = useState(false);
  const [isSelectedLine, setSelectedLine] = useState<boolean | undefined>(
    false
  );
  const [burgerMode, setBurgerMode] = useState(false);
  const { activeChatId } = useContext(ChatInstanceData);

  const dispatch = useDispatch();
  const currentChat = useSelector(
    (state: ReduxState) => state.chat.chats[chatId]
  );

  const handleEditChat = (
    event: React.MouseEvent<HTMLElement>,
    field: string
  ) => {
    if (currentChat) {
      const {
        chatId: chatIdEdit,
        chatType,
        groupId,
        iconId,
        isPrivate,
        name: nameEdit,
        settings,
        members,
      } = currentChat;

      const membersFormat = members?.map(
        ({ name, organization, status, ...rest }) => {
          return rest;
        }
      );
      const membersFinal = membersFormat.map((member) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        member.roles = member.roles.map((role) => (role = role.roleName));
        return member;
      });

      let updatedSettings = settings;

      if (field === 'IS_MUTE' || field === 'IS_FAVORITE') {
        updatedSettings = settings.map((item) =>
          item.code === field
            ? {
                ...item,
                value: item.value === 'true' ? 'false' : 'true',
              }
            : item
        );
      }
      if (field === 'JOIN_ROOM') {
        event.stopPropagation();
        updatedSettings = settings.map((item) => {
          if (item.code === 'JOIN_ROOM') {
            return { ...item, value: 'false' };
          } else if (item.code === 'AUTOSTART') {
            return { ...item, value: 'false' };
          }
          return item;
        });
        if (isActive && chatIds.length > 1) {
          dispatch(
            actions.ui.chat.changeActiveChat({
              id: instanceId,
              activeChatId: chatIds[0],
            })
          );
        } else if (isActive && chatIds.length === 1) {
          dispatch(
            actions.ui.chat.changeActiveChat({
              id: instanceId,
              activeChatId: undefined,
            })
          );
        }
      }

      dispatch(
        actions.api.chat.chatEdit.started({
          chatId: chatIdEdit,
          chatType: chatType?.code,
          chatGroupId: groupId,
          iconId,
          isPrivate,
          name: nameEdit,
          members: membersFinal,
          settings: updatedSettings,
        })
      );
    }
  };

  useEffect(() => {
    if (selectableContact(type, status?.userStatus)) {
      const isSelectedTest = selectedChats.find((item) => item === chatId);
      setSelectedLine(!!isSelectedTest);
    }
  }, [selectedChats, chatId]);

  const handleActiveChat = () => {
    if (activeChatId !== chatId) {
      dispatch(
        actions.ui.chat.changeActiveChat({
          id: instanceId,
          activeChatId: chatId,
        })
      );
      setTypePanel('chat');
    }
  };

  const contactClass = classNames(styles.contact, {
    [styles.newMessage]: status?.isUnread,
    [styles.isSelected]: !isCheckBox ? isActive : null,
  });

  const selectedClass = classNames(styles.contact, styles.selected);

  const isFavorite = currentChat?.settings.find(
    (item) => item.code === 'IS_FAVORITE'
  )?.value;
  const isMute = currentChat?.settings.find(
    (item) => item.code === 'IS_MUTE'
  )?.value;

  useEffect(() => {
    if (isActive && currentChat?.chatId === activeChatId && burgerMode) {
      setIsBurger(true);
    }
  }, [isActive, burgerMode, currentChat?.chatId, activeChatId]);

  return (
    <div
      role="button"
      tabIndex={0}
      className={isSelectedLine ? selectedClass : contactClass}
      onClick={
        isCheckBox
          ? () =>
              handleSelectedChatsClick(
                chatId,
                selectableContact(type, status?.userStatus)
              )
          : handleActiveChat
      }
      onMouseEnter={() => {
        setIsHover(() => true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
        setIsBurger(false);
        setBurgerMode(false);
      }}
    >
      {isCheckBox && (
        <Checkbox
          disabled={!selectableContact(type, status?.userStatus)}
          id={chatId}
          isParentAccordeon={
            selectableContact(type, status?.userStatus) && isSelectedLineProps
          }
          checked={
            selectableContact(type, status?.userStatus) && isSelectedLine
          }
        />
      )}
      {type === 'P2P' ? (
        <UserIdentifier type="P2P" icon={status?.userStatus ?? 'OFFLINE'} />
      ) : (
        <UserIdentifier type="avatar" icon={type} />
      )}

      {typeof name === 'string' ? (
        <div className={styles.title}>{name}</div>
      ) : (
        name?.map((item: string) => (
          <div key={item} className={styles.title}>
            {item}
          </div>
        ))
      )}

      {company ? <div className={styles.company}>{company}</div> : null}
      {isHover && !isBurger && (
        <div className={styles.hover}>
          <IconButton
            icon="burger"
            size="m"
            color="#EDEEF2"
            onClick={() => {
              setBurgerMode(true);
            }}
          />
        </div>
      )}
      {isHover && isBurger && (
        <div className={styles.hover}>
          <IconButton
            icon={isMute === 'true' ? 'volumeOff' : 'unMute'}
            onClick={(event) => handleEditChat(event, 'IS_MUTE')}
            color="#ADB0BC"
            size="m"
          />
          <Bell
            activeChatId={chatId}
            disabled={!!(status?.userStatus === 'OFFLINE' && type === 'P2P')}
          />
          {type !== 'DEAL' && (
            <IconButton
              icon={isFavorite === 'true' ? 'starFilled' : 'starBorder'}
              size="m"
              onClick={(event) => handleEditChat(event, 'IS_FAVORITE')}
            />
          )}

          {/* <IconButton
                size="m"
                color={isAuto ? '#EDEEF2' : '#ADB0BC'}
                icon="fontDownload"
                onClick={(event) => handleEditChat(event, 'AUTOSTART')}
              /> */}
          <IconButton
            icon="close"
            size="m"
            onClick={(event) => handleEditChat(event, 'JOIN_ROOM')}
          />
        </div>
      )}
    </div>
  );
};
