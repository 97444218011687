import { composeWithDevTools } from '@redux-devtools/extension';
import { Store, applyMiddleware, legacy_createStore } from 'redux';
import createSagaMiddleware, { END, Saga, Task } from 'redux-saga';

import { ReduxState, initialState, rootReducer } from 'src/reducers';
import { rootSaga } from 'src/sagas';

interface IExtendedStore extends Store<ReduxState> {
  runSaga?: <S extends Saga>(saga: S, ...args: Parameters<S>) => Task;
  close?: () => void;
}

const sagaMiddleware = createSagaMiddleware();
const enhancer = applyMiddleware(sagaMiddleware);

export const store = legacy_createStore(
  rootReducer,
  initialState,
  composeWithDevTools(enhancer)
) as IExtendedStore;

store.runSaga = sagaMiddleware.run;
store.close = () => store.dispatch(END);
store.runSaga(rootSaga);
