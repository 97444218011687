import dayjs from 'dayjs';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actions } from 'src/actions';
import {
  BlotterColumn,
  BlotterFilterType,
  BlotterTemp,
  SortType,
  Ticket,
} from 'src/types';
import {
  findStartAndEndDate,
  replaceTraider,
  sortTickets,
} from 'src/utils/blotter';

import { columns } from '../constants/mock/deals';

export type State = {
  tickets: Ticket[];
  sortType: SortType;
  sortField: keyof Ticket;
  filterObj: BlotterFilterType;
  filterField?: string;
  temp: BlotterTemp;
  ticketNotifications?: Ticket[];
  headerConfig?: {
    startDate?: string;
    endDate?: string;
    allTickets?: number;
    acceptTickets?: number;
    nonAcceptTickets?: number;
  };
  blotterColumns: BlotterColumn[];
};

const initialState: State = {
  tickets: [],
  sortType: 'desc',
  sortField: 'createdAt',
  filterObj: {},
  filterField: '',
  temp: {},
  headerConfig: {},
  blotterColumns: columns,
};

const reducer = reducerWithInitialState<State>(initialState)
  .case(actions.api.blotter.ticketsList.done, (state, payload) => {
    if (payload.result.length) {
      const listStatus = payload.result.map((tickets) => tickets.ticketStatus);
      const listDates = payload.result
        .map((tickets) => ({ dateCreated: tickets.createdAt }))
        .filter((date) => date.dateCreated);

      const { startDate, endDate } = findStartAndEndDate(listDates);

      return {
        ...state,
        tickets: sortTickets(
          payload.result.map((ticket) =>
            replaceTraider(ticket, payload.params.extra?.userId)
          ),
          state.sortType,
          state.sortField
        ),
        headerConfig: {
          startDate: startDate,
          endDate: endDate,
          allTickets: state.filterObj.ticketStatusId?.includes(
            'd506957d-7d7a-40bd-a6bf-d15ab7c9e945'
          )
            ? listStatus.length
            : listStatus.filter((status: string) => status !== 'DECLINED')
                .length,
          acceptTickets: listStatus.filter(
            (status: string) => status === 'ACCEPTED'
          ).length,
          nonAcceptTickets: listStatus.filter((status: string) => {
            if (
              state.filterObj.ticketStatusId?.includes(
                'd506957d-7d7a-40bd-a6bf-d15ab7c9e945'
              )
            ) {
              return status !== 'ACCEPTED';
            } else {
              return status !== 'ACCEPTED' && status !== 'DECLINED';
            }
          }).length,
        },
      };
    }

    return {
      ...state,
      tickets: payload.result,
      headerConfig: {
        startDate: dayjs().format('DD.MM.YYYY'),
        endDate: dayjs().format('DD.MM.YYYY'),
        allTickets: 0,
        acceptTickets: 0,
        nonAcceptTickets: 0,
      },
    };
  })
  .case(actions.ui.blotter.changeSort, (state, payload) => {
    const { sortType, sortField } = payload;
    const sortedTicketList = sortTickets(state.tickets, sortType, sortField);

    return {
      ...state,
      sortType: sortType,
      sortField: sortField,
      tickets: sortedTicketList,
    };
  })
  .case(actions.ui.blotter.changeColumns, (state, payload) => {
    return {
      ...state,
      blotterColumns: payload.columns,
    };
  })
  .case(actions.api.blotter.contact.done, (state, payload) => {
    if (!payload.params?.extra?.isUser) {
      return {
        ...state,
        temp: { ...state.temp, contact: payload.result.Contact },
      };
    }

    return state;
  })
  .case(actions.api.blotter.organization.done, (state, payload) => {
    return {
      ...state,
      temp: { ...state.temp, organization: payload.result.Organization },
    };
  })
  .case(actions.ui.blotter.changeFilter, (state, payload) => {
    if (!payload.filterField) return state;
    return {
      ...state,
      filterField: payload.filterField,
      filterObj: {
        ...state.filterObj,
        [payload.filterField]: payload.filterValue ?? '',
      },
    };
  })
  .case(actions.ui.blotter.setOwnerTicketsFilter, (state, payload) => {
    if (payload.userId) {
      const { organizationTickets, ...rest } = state.filterObj;
      return {
        ...state,
        filterObj: {
          ...rest,
          traderTickets: payload.userId,
        },
      };
    }

    if (payload.orgId) {
      const { traderTickets, ...rest } = state.filterObj;
      return {
        ...state,
        filterObj: {
          ...rest,
          organizationTickets: payload.orgId,
        },
      };
    }
    return state;
  })
  .case(actions.ui.blotter.clearSearch, (state) => {
    return {
      ...state,
      temp: {},
    };
  })
  .case(actions.api.blotter.changeTicketStatus.done, (state, payload) => {
    if (payload.params?.action === 'RECEIVE') {
      return {
        ...state,
        headerConfig: {
          ...state.headerConfig,
          allTickets: state.headerConfig?.allTickets
            ? state.headerConfig?.allTickets + 1
            : 1,
          nonAcceptTickets: state.headerConfig?.nonAcceptTickets
            ? state.headerConfig?.nonAcceptTickets + 1
            : 1,
        },
        tickets: [
          ...state.tickets,
          replaceTraider(payload.result, payload.params?.extra?.userId),
        ],
        ticketNotifications: state.ticketNotifications
          ? [
              ...state.ticketNotifications,
              replaceTraider(payload.result, payload.params?.extra?.userId),
            ]
          : [replaceTraider(payload.result, payload.params?.extra?.userId)],
      };
    } else {
      return {
        ...state,
        headerConfig: {
          ...state.headerConfig,
          acceptTickets: state.headerConfig?.acceptTickets
            ? state.headerConfig?.acceptTickets + 1
            : 1,
          nonAcceptTickets: state.headerConfig?.nonAcceptTickets
            ? state.headerConfig?.nonAcceptTickets - 1
            : 0,
        },
        tickets: state.tickets.map((ticket) =>
          ticket.id === payload.result.id
            ? replaceTraider(payload.result, payload.params?.extra?.userId)
            : ticket
        ),
      };
    }
  })
  .case(actions.ui.blotter.updateTicket, (state, payload) => {
    return {
      ...state,
      tickets: state.tickets.map((ticket) =>
        ticket.id === payload.ticket.id
          ? replaceTraider(payload.ticket, payload.extra?.userId)
          : ticket
      ),
    };
  })
  .case(actions.api.blotter.createTicket.done, (state, payload) => {
    return {
      ...state,
      headerConfig: {
        ...state.headerConfig,
        allTickets: state.headerConfig?.allTickets
          ? state.headerConfig?.allTickets + 1
          : 1,
        nonAcceptTickets: state.headerConfig?.nonAcceptTickets
          ? state.headerConfig?.nonAcceptTickets + 1
          : 1,
      },
      tickets: [payload.result, ...state.tickets],
    };
  })
  .case(actions.ui.blotter.orderHeader, (state, payload) => {
    return {
      ...state,
      headerOrder: payload.order,
    };
  })
  .case(actions.ui.blotter.removeTicketFromModal, (state, payload) => {
    return {
      ...state,
      ticketNotifications: state.ticketNotifications?.filter(
        (ticket) => ticket.id !== payload.id
      ),
    };
  })
  .case(actions.ui.blotter.resetColumns, (state) => {
    return { ...state, blotterColumns: [...columns] };
  })
  .case(actions.api.blotter.settings.done, (state, payload) => {
    const sortField = payload.result?.filter?.find((el) => el.isSort);
    return {
      ...state,
      blotterColumns: payload.result?.filter?.length
        ? payload.result?.filter
        : columns,
      sortField: (sortField?.name as keyof Ticket) || initialState.sortField,
      sortType: sortField?.isSort || initialState.sortType,
    };
  })
  .case(actions.ws.event, (state, payload) => {
    const { method } = payload;
    if (method === 'updateNotification') {
      if (payload.model === 'Contact') {
        return {
          ...state,
          tickets: state.tickets.map((item) => {
            if (item.traderContactId === payload.object.user_id) {
              return {
                ...item,
                traderContact: `${payload.object.first_name} ${payload.object.last_name}`,
              };
            }

            if (item.counterAgentTraderContactId === payload.object.user_id) {
              return {
                ...item,
                counterAgentTraderContact: `${payload.object.first_name} ${payload.object.last_name}`,
              };
            }

            return item;
          }),
        };
      }

      if (payload.model === 'Organization') {
        return {
          ...state,
          tickets: state.tickets.map((item) => {
            if (payload.object.id === item.traderContactOrganizationId) {
              return {
                ...item,
                traderContactOrganizationId: payload.object.id,
                traderContactOrganization: payload.object.title,
              };
            }

            if (
              payload.object.id === item.counterAgentTraderContactOrganizationId
            ) {
              return {
                ...item,
                counterAgentTraderContactOrganizationId: payload.object.id,
                counterAgentTraderContactOrganization: payload.object.title,
              };
            }

            return item;
          }),
        };
      }
    }
    return state;
  });
export const blotter = { initialState, reducer };
