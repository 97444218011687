import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actions } from 'src/actions';
import {
  AuthRecord,
  ChatGroupInternal,
  ChatInstance,
  ChatInternal,
  ChatMessages,
  Chats,
  FilterType,
  Temp,
  searchResponse,
} from 'src/types';
import {
  addNewChatToGroup,
  changeInstance,
  chatsSystemUpdate,
  filterChats,
  filteredMessages,
  getActiveChatId,
  getActiveGroupId,
  getDefaultGroupId,
  getParentGroupByChatGroupId,
  messagesSystemUpdate,
  normalizeGroups,
  normalizeMessages,
  normalizeSearchMessages,
  notificationToMessage,
  removeChatMembers,
  setChatMembersStatus,
  tempSystemUpdate,
  updateChat,
  updateGroup,
} from 'src/utils/chat';
import { getItem } from 'src/utils/storage';

export type State = {
  // multiple chat windows
  instances: ChatInstance[];
  groups: ChatGroupInternal[];
  chats: Chats;
  searchGroupData: searchResponse;
  messages: ChatMessages;
  temp: Temp;
  error?: string;
};

const initialState: State = {
  instances: [{ id: 'default', panels: ['contacts', 'dialog'] }],
  groups: [],
  chats: {},
  messages: {},
  searchGroupData: { chats: [], tools: [], contacts: [], result: false },
  temp: { users: [], currentMessages: [], currentBlastMessage: '' },
  error: '',
};

const currentMemberId = getItem<AuthRecord | null>('token', null)?.userId;

const reducer = reducerWithInitialState<State>(initialState)
  .case(actions.api.chat.groupsList.done, (state, payload): State => {
    const typeSort =
      (localStorage.getItem('filterType') as FilterType) ?? 'none';
    const { groups, chats } = normalizeGroups(payload.result.chatGroups);
    const instances = changeInstance(state.instances, {
      id: 'default',
      activeGroupId: getActiveGroupId(groups),
      activeChatId: getActiveChatId(groups),
    });

    const result = filteredMessages(chats, typeSort);

    return { ...state, groups, chats: result, instances };
  })

  .case(actions.api.chat.messagesList.done, (state, payload) => {
    if (payload.params.extra?.search) {
      return {
        ...state,
        temp: {
          ...state.temp,
          foundMessageList: payload.result[0],
        },
      };
    } else {
      return {
        ...state,
        messages: normalizeMessages(state.messages, payload.result),
      };
    }
  })
  .case(actions.ui.chat.changeActiveGroup, (state, payload) => ({
    ...state,
    instances: changeInstance(state.instances, {
      ...payload,
      activeChatId: getActiveChatId(state.groups, payload.activeGroupId),
    }),
  }))
  .case(actions.ui.chat.changeActiveChat, (state, payload) => ({
    ...state,
    instances: changeInstance(state.instances, payload),
  }))
  .case(actions.ui.chat.changeCurrentMessage, (state, payload) => {
    const currentMessages = state.temp.currentMessages ?? [];
    const currentMessagesCopy = currentMessages && [...currentMessages];
    const equalIndex = currentMessagesCopy?.findIndex(
      (curMessage) => curMessage.id === payload.id
    );
    if (equalIndex !== undefined && equalIndex !== -1 && currentMessagesCopy) {
      currentMessagesCopy[equalIndex].message = payload.message;
    } else {
      currentMessagesCopy?.push(payload);
    }

    return {
      ...state,
      temp: { ...state.temp, currentMessages: currentMessagesCopy },
    };
  })
  .case(actions.ui.chat.changeBlastCurrentMessage, (state, payload) => {
    const currentBlastMessageCopy: string = payload;
    return {
      ...state,
      temp: {
        ...state.temp,
        currentBlastMessage: currentBlastMessageCopy,
      },
    };
  })
  .case(actions.ui.chat.filterChats, (state, payload) => {
    const { type } = payload;
    const chats =
      type !== 'none' ? filteredMessages(state.chats, type) : state.chats;
    return { ...state, chats };
  })
  .case(actions.ui.chat.updateErrorMessage, (state, payload) => {
    return { ...state, error: payload.message ? payload.message : '' };
  })
  .case(actions.ui.chat.clearFoundChats, (state) => {
    return {
      ...state,
      temp: { ...state.temp, foundMessageList: {}, foundChats: [] },
    };
  })

  .case(actions.api.chat.chatEdit.done, (state, payload) => {
    const chatsCopy = Object.values(state.chats);
    const chatsObj: Chats = {};
    chatsCopy.map((chat) => {
      if (chat?.chatId === payload.result.chatId) {
        chat.settings = payload.result.settings;
        chat.members = payload.result.members;
        chat.name = payload.result.name;
      }
      return chat;
    });

    const chatsFiltered = filterChats(chatsCopy);
    chatsFiltered.forEach((element) => {
      if (element) {
        chatsObj[element.chatId] = element;
      }
    });

    const chatEdited = { ...payload.result };
    const isJoin = chatEdited.settings?.some(
      (itemElement) =>
        itemElement.code === 'JOIN_ROOM' && itemElement.value === 'true'
    );

    const groupsCopy = [...state.groups];
    const chatId = payload.result.chatId;
    const groupId = payload.result.group.groupId;
    const groupFiltered = groupsCopy.filter((item) => {
      if (item.chatGroupId === groupId) {
        return item;
      }
    })[0];

    const chatGroupFiltered = !isJoin
      ? groupFiltered.chats.filter((item) => item !== chatId)
      : groupFiltered.chats;

    groupFiltered.chats = chatGroupFiltered;

    // let endObj = {};
    // if (payload.result.chatId) {
    //   endObj = { ...chatsObj, [payload.result.chatId]: payload.result };
    // }

    return {
      ...state,
      chats: chatsObj,
      groups: groupsCopy,
    };
  })

  .case(actions.api.chat.newChat.done, (state, payload) => {
    const typeSort =
      (localStorage.getItem('filterType') as FilterType) ?? 'none';
    const { group, ...chat } = payload.result;
    const chats = updateChat(
      state.chats,
      {
        groupId: group.groupId,
        ...chat,
      },
      undefined,
      typeSort
    );
    const parentGroupId = payload.result.group.parentGroupId;

    return {
      ...state,
      chats,
      instances: changeInstance(state.instances, {
        id: 'default',
        activeGroupId:
          typeof parentGroupId === 'string'
            ? parentGroupId
            : getDefaultGroupId(state.groups),
        activeChatId: payload.result.chatId,
      }),
      groups: addNewChatToGroup(state.groups, chat, group),
    };
  })
  .case(actions.api.chat.searchCategory.done, (state, payload) => {
    const { tools, chats, contacts, result } = payload.result;
    const response = { tools, chats, contacts, result };
    return {
      ...state,
      searchGroupData: response,
    };
  })
  .case(actions.api.chat.globalSearch.done, (state, payload) => {
    return {
      ...state,
      temp: {
        ...state.temp,
        foundChats: normalizeSearchMessages(payload.result),
      },
    };
  })
  .case(actions.api.chat.chatInfo.done, (state, payload) => {
    const typeSort =
      (localStorage.getItem('filterType') as FilterType) ?? 'none';
    const { groups } = state;
    const defaultGroupId = getDefaultGroupId(groups);
    const chats = updateChat(
      state.chats,
      { groupId: defaultGroupId, ...payload.result },
      {
        isUnread:
          payload.params.extra === 'user.message' ||
          payload.params.extra === 'user.sendAlert',
        currentMemberId,
      },
      typeSort
    );
    const updatedGroups = groups.map((group) => {
      if (group.chatGroupId === defaultGroupId) {
        group.chats.push(payload.result.chatId);
      }
      return group;
    });
    const messageType = payload.params.extra;
    if (
      payload.result.settings.find((item) => item.code === 'IS_MUTE')?.value ===
      'false'
    ) {
      if (messageType === 'user.message') {
        const audio = new Audio('/sound/message.mp3');
        audio.play();
      }
      if (messageType === 'user.sendAlert') {
        const audio = new Audio('/sound/notification.mp3');
        audio.play();
      }
    }

    return {
      ...state,
      groups: updatedGroups,
      chats,
    };
  })
  .case(actions.api.chat.chatUsersList.done, (state, payload) => {
    return {
      ...state,
      temp: { users: payload.result },
    };
  })
  .case(actions.ui.chat.changeStatusMessage, (state, payload) => {
    const messageSate =
      typeof state.messages !== 'undefined'
        ? state.messages[payload.chatId]?.map((item) =>
            item.messageId === payload.messageId
              ? { ...item, isNew: false }
              : item
          )
        : [];
    return {
      ...state,
      messages: {
        ...state.messages,
        [payload.chatId]: messageSate,
      },
    };
  })
  .case(actions.ws.event, (state, payload) => {
    const currentMemberId = getItem<AuthRecord | null>('token', null)?.userId;

    const { method } = payload;
    if (method === 'joinRoom' || method === 'userJoinRoom') {
      const { roomId /*message*/ } = payload; // TODO: нужны эти нотификации в сообщениях?
      const chat = state.chats[roomId];

      if (!chat) {
        return state;
      }

      const members = setChatMembersStatus(
        chat.members,
        payload.method === 'joinRoom' ? payload.members : [payload.memberId],
        (payload.method === 'joinRoom' && payload.members.length > 1) ||
          (payload.method === 'joinRoom' &&
            payload.members.length === 1 &&
            payload.members[0] === currentMemberId) ||
          payload.method === 'userJoinRoom'
          ? 'ONLINE'
          : 'OFFLINE'
      );

      return {
        ...state,
        chats: updateChat(
          state.chats,
          { ...chat, members },
          { currentMemberId }
        ),
      };
    }

    if (method === 'leaveRoom') {
      const { roomId, message } = payload;

      return {
        ...state,
        message: normalizeMessages(state.messages, [
          {
            roomId,
            messages: [notificationToMessage(message)],
          },
        ]),
        chats: { ...state.chats, [payload.roomId]: undefined },
      };
    }

    if (method === 'userLeaveRoom') {
      const chat = state.chats[payload.roomId];
      const { roomId, message } = payload;

      if (!chat) {
        return state;
      }

      const members = removeChatMembers(chat.members, [payload.memberId]);

      return {
        ...state,
        messages: normalizeMessages(state.messages, [
          {
            roomId,
            messages: [notificationToMessage(message)],
          },
        ]),
        chats: updateChat(state.chats, { ...chat, members }),
      };
    }

    if (method === 'message') {
      const typeSort =
        (localStorage.getItem('filterType') as FilterType) ?? 'none';
      const { method, roomId, ...message } = payload;
      const chat = state.chats[roomId];
      const group = getParentGroupByChatGroupId(state.groups, chat?.groupId);

      const chatUnreadCondition =
        state.instances[0].activeChatId !== roomId &&
        message.memberId !== currentMemberId;

      const groupUnreadCondition =
        state.instances[0].activeGroupId !== group?.chatGroupId &&
        message.memberId !== currentMemberId;

      const messages = state.messages[roomId]
        ? normalizeMessages(state.messages, [
            {
              roomId,
              messages: [{ ...message, isNew: true }],
            },
          ])
        : state.messages;
      const chats = updateChat(
        state.chats,
        chat,
        {
          isUnread: chatUnreadCondition,
          currentMemberId,
        },
        typeSort
      );

      const result = Object.values(chats)
        ?.map((item) => {
          if (item?.chatId === chat?.chatId) {
            return Object.assign(
              {},
              { [item!.chatId]: { ...item, lastMessage: message } }
            );
          } else {
            return Object.assign({}, { [item!.chatId]: item });
          }
        })
        .reduce((obj, item) => Object.assign(obj, item), {} as Chats) as Chats;

      return {
        ...state,
        messages: messages,
        chats: result,
        groups: updateGroup(state.groups, group, {
          isUnread: groupUnreadCondition,
        }),
      };
    }
    if (method === 'userAddToRoom') {
      const { roomId, message } = payload;

      return {
        ...state,
        messages: normalizeMessages(state.messages, [
          {
            roomId,
            messages: [{ ...message, isNew: true }],
          },
        ]),
      };
    }

    if (method === 'changeMessage') {
      const { method, roomId, ...message } = payload;

      return {
        ...state,
        messages: {
          ...state.messages,
          [roomId]: (state.messages[roomId] || []).map((item) =>
            item.messageId === message.messageId ? message : item
          ),
        },
      };
    }

    if (method === 'readMessage') {
      const typeSort =
        (localStorage.getItem('filterType') as FilterType) ?? 'none';
      const { roomId } = payload;
      const chat = state.chats[roomId];
      const group = getParentGroupByChatGroupId(state.groups, chat?.groupId);

      return {
        ...state,
        messages: {
          ...state.messages,
          [roomId]: (state.messages[roomId] || []).map((message) => ({
            ...message,
            isRead: true,
          })),
        },
        chats: updateChat(state.chats, chat, {
          isUnread: false,
          currentMemberId,
        }),
        typeSort,
        groups: updateGroup(state.groups, group, {
          isUnread: false,
        }),
      };
    }

    if (method === 'disconnect') {
      const { roomId, userId } = payload;

      const updatedChat = {
        ...state.chats[roomId],
        members: state.chats[roomId]?.members.map((item) =>
          item.userId === userId ? { ...item, status: 'OFFLINE' } : item
        ),
        status: {
          userStatus: 'OFFLINE',
          isUnread: state.chats[roomId]?.status?.isUnread,
        },
      } as ChatInternal;

      if (state.chats[roomId] === undefined) {
        return state;
      } else {
        return {
          ...state,
          chats: {
            ...state.chats,
            [roomId]: updatedChat,
          },
        };
      }
    }

    if (method === 'deleteFromRoom') {
      const { roomId } = payload;
      const chats = state.chats;
      delete chats[roomId];
      const groupChats = state.groups.map((item) => {
        if (item.chats.some((i) => i === roomId)) {
          const listChats = item.chats;
          return {
            ...item,
            chats: listChats.filter((i) => i !== roomId),
          };
        } else {
          return item;
        }
      });

      return { ...state, chats: chats, groups: groupChats };
    }

    if (method === 'userDeleteFromRoom') {
      const { roomId, delMemberId, message } = payload;
      const members = state.chats[roomId]?.members.filter(
        (member) => member.userId !== delMemberId
      );
      const chat = state.chats[roomId];
      return {
        ...state,
        messages: normalizeMessages(state.messages, [
          {
            roomId,
            messages: [{ ...message, isNew: true }],
          },
        ]),
        chats: updateChat(state.chats, { ...chat, members }),
      };
    }

    if (method === 'deleteMessage') {
      if (payload.isDeleted) {
        return {
          ...state,
          messages: {
            ...state.messages,
            [payload.roomId]: state.messages[payload.roomId]?.map((item) =>
              item.messageId === payload.messageId
                ? { ...item, isDeleted: true }
                : item
            ),
          },
        };
      }
    }

    if (method === 'systemUpdate') {
      const { orgInfo, userInfo } = payload;

      if (orgInfo) {
        return {
          ...state,
          chats: chatsSystemUpdate(
            state.chats,
            orgInfo,
            undefined,
            currentMemberId
          ),
          messages: messagesSystemUpdate(state.messages, orgInfo, undefined),
          temp: tempSystemUpdate(state.temp, orgInfo, undefined),
        };
      }

      if (userInfo) {
        return {
          ...state,
          chats: chatsSystemUpdate(
            state.chats,
            undefined,
            userInfo,
            currentMemberId
          ),
          messages: messagesSystemUpdate(state.messages, undefined, userInfo),
          temp: tempSystemUpdate(state.temp, undefined, userInfo),
        };
      }
    }

    if (!method && typeof payload === 'string') {
      return { ...state, error: payload };
    }
    return { ...state };
  });

export const chat = { initialState, reducer };
