import { Param } from 'src/components/organisms/SearchMessageMainPanel';
import { CHAT_DEFAULT_TAB, CHAT_FAVORITES_GROUP } from 'src/constants';
import { defaultSettingsForCreate } from 'src/constants/chat';
import {
  Actions,
  AuthRecord,
  Chat,
  ChatGroup,
  ChatGroupInternal,
  ChatGroupNewChat,
  ChatGroupStatus,
  ChatInstance,
  ChatInternal,
  ChatMember,
  ChatMessage,
  ChatMessages,
  ChatStatus,
  Chats,
  FilterType,
  FoundChat,
  ID,
  NewChatRecord,
  OrgInfo,
  Organization,
  Temp,
  User,
  UserInfo,
  UserStatus,
} from 'src/types';
import { DATE_NOW } from 'src/utils/date';

type NormalizedGroups = {
  groups: ChatGroupInternal[];
  chats: Chats;
};

const calcChatUserStatus = (
  members: ChatMember[],
  currentUserId?: ChatMember['userId']
): UserStatus =>
  members?.filter((member) => member.userId !== currentUserId)[0]?.status ===
  'ONLINE'
    ? 'ONLINE'
    : 'OFFLINE';

export const setUserStatus = (
  members: ChatMember[],
  userId: ChatMember['userId'],
  status: UserStatus
) =>
  members?.map((member) => {
    if (member.userId === userId) {
      return { ...member, status: status };
    }
    return member;
  });

export const setChatMembersStatus = (
  list: ChatMember[],
  members: ChatMember['userId'][],
  status: UserStatus
): ChatMember[] =>
  list.map((member) =>
    members.includes(member.userId) ? { ...member, status } : member
  );

export const removeChatMembers = (
  list: ChatMember[],
  members: ChatMember['userId'][]
): ChatMember[] => list.filter((member) => !members.includes(member.userId));

// TODO: order groups?
export const normalizeGroups = (list: ChatGroup[]): NormalizedGroups =>
  list
    .sort((a) => (a.groupType.code === CHAT_DEFAULT_TAB ? -1 : 0))
    .reduce<NormalizedGroups>(
      (acc, item) => {
        const prepare = (item: ChatGroup) => {
          const { childrenGroups, chats, ...group } = item;

          // TODO: order chats
          acc.groups.push({
            ...group,
            chats: chats.map(({ chatId }) => chatId),
          });
          chats.forEach((chat) => {
            acc.chats[chat.chatId] = {
              ...chat,
              groupId: group.chatGroupId,
              members: setChatMembersStatus(
                chat.members,
                chat.members.map(({ userId }) => userId),
                'OFFLINE'
              ),
              status: {
                userStatus: 'OFFLINE',
                isUnread:
                  chat.lastMessage?.isRead === undefined
                    ? false
                    : !chat.lastMessage?.isRead,
              },
            };
          });
        };

        prepare(item);

        if (item.childrenGroups) {
          item.childrenGroups
            .sort((a) => (a.chatGroupName === CHAT_FAVORITES_GROUP ? -1 : 0))
            .forEach((item) => prepare(item));
        }

        return acc;
      },
      { groups: [], chats: {} }
    );

export const normalizeMessages = (
  list: ChatMessages,
  newItems: Actions.api.chat.messagesList.done
): ChatMessages =>
  newItems.reduce<ChatMessages>((acc, item) => {
    if (acc[item.roomId]) {
      acc[item.roomId]?.push(
        ...item.messages.filter(({ isVisible }) => isVisible)
      );
    } else {
      acc[item.roomId] = item.messages.filter(({ isVisible }) => isVisible);
    }

    return acc;
  }, list);
export const normalizeSearchMessages = (
  newItems: Actions.api.chat.globalSearch.done
): FoundChat[] =>
  newItems.map((item) => {
    const seen = new Set();
    return {
      chatId: item.chatId,
      chatType: item.chatType,
      chatName: item.chatName,
      createdAt: item.createdAt,
      status: item.status,
      contact: [
        ...(item.contact?.map((item) => item) ?? [],
        item.queryString?.userNameQuery?.map((item) => item) ?? []),
      ].filter((item) => {
        const duplicate = seen.has(item.userId);
        seen.add(item.userId);
        return !duplicate;
      }),
      messages: item.queryString?.messageQuery?.map((item) => ({
        memberName: item.message.memberName,
        message: item.message.message,
        createdAt: item.message.createdAt,
        countMessages: item.countMessages,
      })),
    };
  });

export const notificationToMessage = (message: string): ChatMessage => ({
  isDeleted: false,
  messageId: '',
  memberId: '',
  message: message,
  type: 'system.addToRoom',
  createdAt: DATE_NOW,
  updatedAt: DATE_NOW,
  isRead: false,
});

export const getActiveGroupId = (
  groups: ChatGroupInternal[]
): ChatGroup['chatGroupId'] | undefined =>
  groups.filter((group) => group.groupType.code === CHAT_DEFAULT_TAB)[0]
    ?.chatGroupId;

export const getActiveChatId = (
  groups: ChatGroupInternal[],
  activeGroupId = getActiveGroupId(groups)
): Chat['chatId'] | undefined =>
  groups.find(
    ({ chatGroupId, parentGroupId, chats }) =>
      (chatGroupId === activeGroupId || parentGroupId === activeGroupId) &&
      chats.length
  )?.chats[0];

export const changeInstance = (
  instances: ChatInstance[],
  fields: Omit<ChatInstance, 'panels'>
): ChatInstance[] =>
  instances.map((instance) =>
    instance.id === fields.id ? { ...instance, ...fields } : instance
  );

export const updateChat = (
  list: Chats,
  chat?: ChatInternal | any, //TODO remove any
  extra?: {
    isUnread?: ChatStatus['isUnread'];
    currentMemberId?: ChatMember['userId'];
  },
  typeSort?: string | null | undefined
): Chats => {
  if (!chat) {
    return list;
  }

  const status = {
    userStatus: calcChatUserStatus(chat.members, extra?.currentMemberId),
    isUnread:
      extra?.isUnread !== undefined
        ? extra?.isUnread
        : chat.status?.isUnread || false,
  };

  if (typeSort === 'date') {
    delete list[chat.chatId];
    return { [chat.chatId]: { ...chat, status }, ...list };
  } else if (typeSort === 'name') {
    delete list[chat.chatId];
    const chats = filteredMessages(
      { [chat.chatId]: { ...chat, status }, ...list },
      typeSort
    );
    return chats;
  } else {
    return { ...list, [chat.chatId]: { ...chat, status } };
  }
};

export const updateGroup = (
  list: ChatGroupInternal[],
  group?: ChatGroupInternal,
  extra?: {
    isUnread: ChatGroupStatus['isUnread'];
  }
): ChatGroupInternal[] => {
  if (!group) {
    return list;
  }

  const status = {
    isUnread: extra?.isUnread || false,
  };

  return list.map((item) =>
    item.chatGroupId === group.chatGroupId ? { ...group, status } : item
  );
};

export const getParentGroupByChatGroupId = (
  list: ChatGroupInternal[],
  groupId?: ChatGroup['chatGroupId']
): ChatGroupInternal | undefined => {
  const parent = list.find(({ chatGroupId }) => chatGroupId === groupId);

  if (!parent?.parentGroupId) {
    return parent;
  }

  return list.find(({ chatGroupId }) => chatGroupId === parent.parentGroupId);
};

export const isFavorite = (chat: Chat, currentIds: string[]): boolean =>
  currentIds.includes(chat.chatId) &&
  chat.settings.some(
    (setting) =>
      setting.code === CHAT_FAVORITES_GROUP && setting.value === 'true'
  );

export const filterFavorite = (chats: string[], favoriteChats: Chat[]) => {
  return chats.filter(
    (id) => !favoriteChats.some((item) => item.chatId === id)
  );
};

export const filterChats = (chats: (ChatInternal | undefined)[]) => {
  return chats.filter((item?: Chat) => {
    if (item) {
      if (
        item.settings?.some(
          (itemElement) =>
            itemElement.code === 'JOIN_ROOM' && itemElement.value === 'true'
        )
      ) {
        return item;
      }
    }
  });
};

export const addNewChatToGroup = (
  list: ChatGroupInternal[],
  newChat: Chat | any, //TODO remove any
  group: ChatGroupNewChat
) =>
  list.map((item) => {
    if (item.chatGroupId === group.groupId) {
      item.chats.push(newChat.chatId);
    }
    return item;
  });

export type CreatingChat = {
  chatType?: NewChatRecord['chatType'];
  name?: NewChatRecord['name'];
  iconId?: NewChatRecord['iconId'];
  isPrivate?: NewChatRecord['isPrivate'];
  members: NewChatRecord['members'];
  settings?: NewChatRecord['settings'];
  groups: NewChatRecord['groups'];
};

export const createChat = ({
  chatType = 'P2P',
  name,
  iconId = null,
  isPrivate = false,
  members,
  settings = defaultSettingsForCreate,
  groups,
}: CreatingChat): NewChatRecord => {
  return {
    chatType,
    name,
    iconId,
    isPrivate,
    members,
    settings,
    groups,
  };
};

export const getDefaultGroupId = (
  groups: ChatGroupInternal[]
): ChatGroup['chatGroupId'] =>
  groups.filter((group) => group.groupType.code === CHAT_DEFAULT_TAB)[0]
    .chatGroupId;

export const filteredMessages = (chats: Chats, type: FilterType): Chats => {
  if (type === 'date') {
    const arr = Object.values(chats)
      .map((item) => item)
      .sort((a, b) => {
        if (a?.lastMessage?.createdAt === undefined) return 1;
        if (b?.lastMessage?.createdAt === undefined) return -1;
        if (a.lastMessage.createdAt > b.lastMessage.createdAt) return -1;
        return 1;
      })
      .reduce((obj, item) => Object.assign(obj, { [item!.chatId]: item }), {});

    return arr;
  } else {
    const filteredMessagesId = Object.keys(chats).sort(
      (c1, c2) =>
        chats[c1]?.name.localeCompare((chats[c2] as Chat).name) as number
    );
    const filteredMessages = filteredMessagesId.reduce((acc, id) => {
      acc[id] = chats[id];
      return acc;
    }, {} as Chats);
    return filteredMessages;
  }
};

export const isIdContainsChecking = (array: User[], id?: ID) => {
  return array?.some((item) => item.userId === id);
};

export const getRightEnding = (membersCount: string) => {
  const base = 'участник';
  const lastNumber = membersCount[membersCount.length - 1];
  const twoLastNumbers = membersCount.slice(membersCount.length - 2);
  if ((membersCount === '1' || lastNumber === '1') && twoLastNumbers !== '11') {
    return `${membersCount} ${base}`;
  }
  if (
    (membersCount >= '2' && membersCount <= '4') ||
    ((lastNumber === '2' || lastNumber === '3' || lastNumber === '4') &&
      twoLastNumbers > '14')
  ) {
    return `${membersCount} ${base}а`;
  }
  if (
    (lastNumber >= '5' && lastNumber <= '9') ||
    lastNumber === '0' ||
    (membersCount >= '11' && membersCount <= '14')
  ) {
    return `${membersCount} ${base}ов`;
  }
  return membersCount;
};

export const parssRoles = (chat: Chat | undefined) => {
  const admins: ChatMember[] = [];
  const members: ChatMember[] = [];
  const owners: ChatMember[] = [];
  if (chat?.chatType.code === 'DEAL') {
    chat.members.forEach((member) => {
      if (member.roles.some(({ roleName }) => roleName === 'Owner')) {
        owners.push(member);
      } else {
        members.push(member);
      }
    });
  } else {
    chat?.members.forEach((member) => {
      if (member.roles.some(({ roleName }) => roleName === 'Owner')) {
        owners.push(member);
      } else if (
        member.roles.some(({ roleName }) => roleName === 'ChatAdmin')
      ) {
        admins.push(member);
      } else {
        members.push(member);
      }
    });
  }
  return { admins, members, owners };
};

export const filterSearchParams = (param: Param[], category: string) =>
  param
    .filter((item) => item.type === category)
    .map((item) => (item.type === 'queryString' ? item.name : item.id)) as [];

export const chatsSystemUpdate = (
  chats: Chats,
  orgInfo?: OrgInfo,
  userInfo?: UserInfo,
  currentMemberId?: AuthRecord['userId']
): Chats => {
  if (orgInfo) {
    const updatedChats = Object.values(chats).reduce((acc, item) => {
      if (item?.chatType.code === 'P2P' || item?.chatType.code === 'PEOPLE') {
        acc[item.chatId] = {
          ...item,
          members: item.members.map((item) =>
            item.organization.id === orgInfo?.org_id
              ? {
                  ...item,
                  organization: {
                    ...item.organization,
                    organizationName: orgInfo.short_title,
                  },
                }
              : item
          ),
        };
      }
      if (item?.chatType.code === 'DEAL') {
        acc[item.chatId] = {
          ...item,
          settings: item.settings.map((item) =>
            item.code === 'ORG_TITLE'
              ? { ...item, value: orgInfo?.short_title ?? item.value }
              : item
          ),
        };
      }
      return acc;
    }, chats);
    return updatedChats;
  }
  if (userInfo) {
    const updatedChats = Object.values(chats).reduce((acc, item) => {
      if (item?.chatType.code === 'P2P' || item?.chatType.code === 'PEOPLE') {
        acc[item.chatId] = {
          ...item,
          name:
            item.chatType.code === 'P2P' &&
            item.members.find((item) => item.userId !== currentMemberId)
              ?.userId === userInfo.userId
              ? userInfo.fullName
              : item.name,
          members: item.members.map((item) =>
            item.userId === userInfo.userId
              ? {
                  ...item,
                  name:
                    item.userId === userInfo.userId
                      ? userInfo.fullName
                      : item.name,
                  organization: {
                    ...item.organization,
                    organizationName: userInfo.organization.short_title,
                  },
                }
              : item
          ),
        };
      }
      if (item?.chatType.code === 'DEAL') {
        acc[item.chatId] = {
          ...item,
          settings: item.settings.map((item) =>
            item.code === 'ORG_TITLE'
              ? {
                  ...item,
                  value: userInfo.organization.short_title ?? item.value,
                }
              : item
          ),
        };
      }
      return acc;
    }, chats);
    return updatedChats;
  }
  return chats;
};
export const messagesSystemUpdate = (
  messages: ChatMessages,
  orgInfo?: OrgInfo,
  userInfo?: UserInfo
  // messages: ChatMessages,
  // users?: User[]
): ChatMessages => {
  if (orgInfo) {
    const updatedMessages = Object.entries(messages).reduce((acc, item) => {
      if (item) {
        acc[item[0]] = item[1]?.map((item) =>
          item.organization?.id === orgInfo.org_id
            ? {
                ...item,
                organization: {
                  ...item.organization,
                  organizationName: orgInfo.short_title,
                },
              }
            : item
        );
      }

      return acc;
    }, messages);
    return updatedMessages;
  }
  if (userInfo) {
    const updatedMessages = Object.entries(messages).reduce((acc, item) => {
      if (item) {
        acc[item[0]] = item[1]?.map((item) =>
          item.memberId === userInfo.userId
            ? {
                ...item,
                memberName: userInfo.fullName,
                organization: {
                  id: item.organization?.id ?? userInfo.organization.org_id,
                  organizationName: userInfo.organization.short_title,
                },
              }
            : item
        );
      }

      return acc;
    }, messages);
    return updatedMessages;
  }
  return messages;
};

export const tempSystemUpdate = (
  temp: Temp,
  orgInfo?: OrgInfo,
  userInfo?: UserInfo
): Temp => {
  if (orgInfo) {
    const updatetTemp = {
      ...temp,
      users: temp.users
        ? temp.users.map((item) =>
            (item.organization as Organization).id === orgInfo.org_id
              ? {
                  ...item,
                  organization: {
                    ...item.organization,
                    title: orgInfo.title,
                  },
                }
              : item
          )
        : temp.users,
    };
    return updatetTemp;
  }
  if (userInfo) {
    const updatetTemp = {
      ...temp,
      users: temp.users
        ? temp.users.map((item) =>
            item.userId === userInfo.userId
              ? {
                  ...item,
                  name: userInfo.fullName,
                  position: userInfo.position,
                  mail: userInfo.email,
                  phone: userInfo.phone,
                  organization: {
                    ...item.organization,
                    title: userInfo.organization.title,
                  },
                }
              : item
          )
        : temp.users,
    };
    return updatetTemp;
  }
  return temp;
};
