import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Contact } from 'src/components/molecules/Contact';
import { TypeDialogPanelProp } from 'src/components/organisms/ChatInstance';
import { ReduxState } from 'src/reducers';
import { Chat, ChatInstance } from 'src/types';

export type Props = {
  chatIds: Chat['chatId'][];
  instanceId: ChatInstance['id'];
  activeChatId: ChatInstance['activeChatId'];
  isEditMode?: boolean;
  handleSelectedChatsClick: (
    chatId: Chat['chatId'],
    selectable: boolean
  ) => void;
  isSelectedLine?: boolean;
  selectedChats: Chat['chatId'][];
  verifyCheckedAccordeon?: (boolean: boolean) => void;
  showContextMenu: (e: React.MouseEvent<HTMLDivElement>) => void;
  chatsValue: Chat[];
  setTypePanel: (value: React.SetStateAction<TypeDialogPanelProp>) => void;
};

export const ChatList: React.FC<Props> = ({
  chatIds,
  instanceId,
  isEditMode,
  activeChatId,
  handleSelectedChatsClick,
  isSelectedLine,
  selectedChats,
  verifyCheckedAccordeon,
  showContextMenu,
  chatsValue,
  setTypePanel,
}) => {
  useEffect(() => {
    if (selectedChats.length > 0) {
      verifyCheckedAccordeon?.(true);
    } else if (selectedChats.length === 0) {
      verifyCheckedAccordeon?.(false);
    }
  }, [isSelectedLine, selectedChats]);

  const chats: Chat[] = [];

  chatsValue.forEach((value) => {
    chatIds.forEach((ids) => {
      if (value.chatId === ids) {
        chats.push(value);
      }
    });
  });
  const currentUserId = useSelector(
    (state: ReduxState) => state.access.authRecord?.userId
  );

  /* need refactoring, when back change */
  const userCompany = (chat: any) => {
    if (chat.chatType.code === 'P2P') {
      return chat.members.find((member: any) => member.userId !== currentUserId)
        ?.organization.organizationName;
    }
    if (chat.settings.find((item: any) => item.code === 'ORG_TITLE'))
      return chat.settings.find((item: any) => item.code === 'ORG_TITLE')
        ?.value;
    return '';
  };

  return (
    <div onContextMenu={showContextMenu} role="presentation">
      {chats.map(
        (chat) =>
          chat && (
            <Contact
              isSelectedLineProps={isSelectedLine}
              handleSelectedChatsClick={handleSelectedChatsClick}
              isCheckBox={isEditMode}
              isActive={chat.chatId === activeChatId}
              key={chat.chatId}
              chatId={chat.chatId}
              name={chat.name}
              company={
                chat.settings.find((item) => item.code === 'ORG_TITLE')
                  ?.value ?? userCompany(chat)
              }
              /* company={chat.settings.find((item) => item.code === 'ORG_TITLE')?.value} */
              type={chat.chatType.code}
              status={chat.status}
              selectedChats={selectedChats}
              instanceId={instanceId}
              chatIds={chatIds}
              setTypePanel={setTypePanel}
              activeChatId={activeChatId}
            />
          )
      )}
    </div>
  );
};
