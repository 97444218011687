import { ENDPOINTS } from 'src/constants/api';
import { Actions } from 'src/types';
import { actionCreatorAsyncWithHandler } from 'src/utils/factories';

export const access = {
  signIn: actionCreatorAsyncWithHandler<
    Actions.api.access.signIn.started,
    Actions.api.access.signIn.done
  >({
    url: ENDPOINTS.SIGN_IN,
    method: 'POST',
    extra: { service: 'auth' },
  }),

  signUp: actionCreatorAsyncWithHandler<
    Actions.api.access.signUp.started,
    Actions.api.access.signUp.done
  >({
    url: ENDPOINTS.SIGN_UP,
    method: 'POST',
    extra: { service: 'auth' },
  }),
};

export const chat = {
  groupsList: actionCreatorAsyncWithHandler<
    Actions.api.chat.groupsList.started,
    Actions.api.chat.groupsList.done
  >({
    url: ENDPOINTS.CHAT_GROUPS_LIST,
    method: 'POST',
    extra: { service: 'chat' },
  }),
  contactsList: actionCreatorAsyncWithHandler<
    Actions.api.chat.contactsList.started,
    Actions.api.chat.contactsList.done
  >({
    url: ENDPOINTS.CHAT_CONTACTS_LIST,
    method: 'GET',
    extra: { service: 'chat' },
  }),
  messagesList: actionCreatorAsyncWithHandler<
    Actions.api.chat.messagesList.started,
    Actions.api.chat.messagesList.done
  >({
    url: ENDPOINTS.CHAT_MESSAGES_LIST,
    method: 'POST',
    extra: { service: 'chat' },
  }),
  readMessage: actionCreatorAsyncWithHandler<
    Actions.api.chat.readMessage.started,
    Actions.api.chat.readMessage.done
  >({
    url: ENDPOINTS.CHAT_MESSAGES_READ,
    method: 'POST',
    extra: { service: 'chat' },
  }),
  chatDelete: actionCreatorAsyncWithHandler<
    Actions.api.chat.chatDelete.started,
    Actions.api.chat.chatDelete.done
  >({
    url: ENDPOINTS.CHAT,
    method: 'DELETE',
    extra: { service: 'chat' },
  }),
  chatEdit: actionCreatorAsyncWithHandler<
    Actions.api.chat.chatEdit.started,
    Actions.api.chat.chatEdit.done
  >({
    url: ENDPOINTS.CHAT_EDIT,
    method: 'PATCH',
    extra: { service: 'chat' },
  }),
  sendToEmail: actionCreatorAsyncWithHandler<
    Actions.api.mail.sendToEmail.started,
    Actions.api.mail.sendToEmail.done
  >({
    url: ENDPOINTS.CHAT_SEND_TO_MAIL,
    method: 'POST',
    extra: { service: 'chat' },
  }),
  searchCategory: actionCreatorAsyncWithHandler<
    Actions.api.chat.searchCategory.started,
    Actions.api.chat.searchCategory.done
  >({
    url: ENDPOINTS.CHAT_SEARCH_IN_CHATS_AND_MEMBERS,
    method: 'GET',
    extra: { service: 'chat' },
  }),
  newChat: actionCreatorAsyncWithHandler<
    Actions.api.chat.newChat.started,
    Actions.api.chat.newChat.done
  >({
    url: ENDPOINTS.CHAT,
    method: 'POST',
    extra: { service: 'chat' },
  }),
  chatInfo: actionCreatorAsyncWithHandler<
    Actions.api.chat.chatInfo.started,
    Actions.api.chat.chatInfo.done
  >({
    url: ENDPOINTS.CHAT_CAHT_INFO,
    method: 'POST',
    extra: { service: 'chat' },
  }),
  chatUsersList: actionCreatorAsyncWithHandler<
    Actions.api.chat.chatUsersList.started,
    Actions.api.chat.chatUsersList.done
  >({
    url: ENDPOINTS.CHAT_USERS_LIST,
    method: 'GET',
    extra: { service: 'chat' },
  }),
  globalSearch: actionCreatorAsyncWithHandler<
    Actions.api.chat.globalSearch.started,
    Actions.api.chat.globalSearch.done
  >({
    url: ENDPOINTS.CHAT_GLOBAL_SEARCH,
    method: 'POST',
    extra: { service: 'chat' },
  }),
};

export const media = {
  upload: actionCreatorAsyncWithHandler<
    Actions.api.media.upload.started,
    Actions.api.media.upload.done
  >({
    url: ENDPOINTS.MEDIA_UPLOAD,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    extra: { service: 'media' },
  }),
  download: actionCreatorAsyncWithHandler<
    Actions.api.media.download.started,
    Actions.api.media.download.done
  >({
    url: ENDPOINTS.MEDIA_DOWNLOAD,
    method: 'GET',
    responseType: 'blob',
    extra: { service: 'media' },
  }),
};

export const dictionaries = {
  activeClass: actionCreatorAsyncWithHandler<
    Actions.dictionaries.activeClass.started,
    Actions.dictionaries.activeClass.done
  >({
    url: ENDPOINTS.DICTIONARY_ACTIVE_CLASS,
    method: 'GET',
    extra: { service: 'monitor' },
  }),
  instrument: actionCreatorAsyncWithHandler<
    Actions.dictionaries.instrument.started,
    Actions.dictionaries.instrument.done
  >({
    url: ENDPOINTS.DICTIONARY_INSTRUMENT,
    method: 'GET',
    extra: { service: 'monitor' },
  }),
  calculationBase: actionCreatorAsyncWithHandler<
    Actions.dictionaries.calculationBase.started,
    Actions.dictionaries.calculationBase.done
  >({
    url: ENDPOINTS.DICTIONARY_CALCULATION_BASE,
    method: 'GET',
    extra: { service: 'monitor' },
  }),
  currency: actionCreatorAsyncWithHandler<
    Actions.dictionaries.currency.started,
    Actions.dictionaries.currency.done
  >({
    url: ENDPOINTS.DICTIONARY_CURRENCY,
    method: 'GET',
    extra: { service: 'monitor' },
  }),
  currencyPair: actionCreatorAsyncWithHandler<
    Actions.dictionaries.currencyPair.started,
    Actions.dictionaries.currencyPair.done
  >({
    url: ENDPOINTS.DICTIONARY_CURRENCY_PAIR,
    method: 'GET',
    extra: { service: 'monitor' },
  }),
  transactionStatus: actionCreatorAsyncWithHandler<
    Actions.dictionaries.ticketStatus.started,
    Actions.dictionaries.ticketStatus.done
  >({
    url: ENDPOINTS.DICTIONARY_TICKET_STATUS,
    method: 'GET',
    extra: { service: 'monitor' },
  }),
  transactionDirection: actionCreatorAsyncWithHandler<
    Actions.dictionaries.transactionDirection.started,
    Actions.dictionaries.transactionDirection.done
  >({
    url: ENDPOINTS.DICTIONARY_TRANSACTION_DIRECTION,
    method: 'GET',
    extra: { service: 'monitor' },
  }),
  tenor: actionCreatorAsyncWithHandler<
    Actions.dictionaries.tenor.started,
    Actions.dictionaries.tenor.done
  >({
    url: ENDPOINTS.DICTIONARY_TENOR,
    method: 'GET',
    extra: { service: 'monitor' },
  }),
};

export const blotter = {
  settings: actionCreatorAsyncWithHandler<
    Actions.api.blotter.settings.started,
    Actions.api.blotter.settings.done
  >({
    url: ENDPOINTS.SET_TIME_ZONE_DATA,
    method: 'PATCH',
    extra: { service: 'monitor' },
  }),
  ticketsList: actionCreatorAsyncWithHandler<
    Actions.blotter.ticketsList.started,
    Actions.blotter.ticketsList.done
  >({
    url: ENDPOINTS.BLOTTER_TICKETS_LIST,
    method: 'POST',
    extra: { service: 'monitor' },
  }),
  createTicket: actionCreatorAsyncWithHandler<
    Actions.blotter.createTicket.started,
    Actions.blotter.createTicket.done
  >({
    url: ENDPOINTS.BLOTTER_TICKET,
    method: 'POST',
    extra: { service: 'monitor' },
  }),
  contact: actionCreatorAsyncWithHandler<
    Actions.blotter.contact.started,
    Actions.blotter.contact.done
  >({
    url: ENDPOINTS.DICTIONARY_CONTACT,
    method: 'GET',
    extra: { service: 'monitor' },
  }),
  organization: actionCreatorAsyncWithHandler<
    Actions.blotter.organization.started,
    Actions.blotter.organization.done
  >({
    url: ENDPOINTS.DICTIONARY_ORGANIZATION,
    method: 'GET',
    extra: { service: 'monitor' },
  }),
  changeTicketStatus: actionCreatorAsyncWithHandler<
    Actions.blotter.changeTicketStatus.started,
    Actions.blotter.changeTicketStatus.done
  >({
    url: ENDPOINTS.BLOTTER_TICKET_STATUS,
    method: 'PATCH',
    extra: { service: 'monitor' },
  }),
  editTicket: actionCreatorAsyncWithHandler<
    Actions.blotter.createTicket.started,
    Actions.blotter.createTicket.done
  >({
    url: ENDPOINTS.BLOTTER_TICKET,
    method: 'PATCH',
    extra: { service: 'monitor' },
  }),
};
