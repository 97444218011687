import { all, put, select, takeEvery } from 'redux-saga/effects';
import { Action, Success } from 'typescript-fsa';

import { actions } from 'src/actions';
import { ReduxState } from 'src/reducers';
import { Actions, AuthRecord, Chats } from 'src/types';

// import { getItem } from 'src/utils/storage';

function* startGenerator() {
  const authRecord: AuthRecord | null = yield select(
    (state: ReduxState) => state.access.authRecord
  );

  if (authRecord) {
    yield all([
      put(
        actions.api.chat.groupsList.started({
          userId: authRecord.userId,
        })
      ),
      put(actions.api.chat.contactsList.started()),
    ]);
  }
}

function* joinChatsGenerator() {
  yield put(actions.ws.send({ type: 'login', message: { room: '*' } }));
}

function* uploadChatsGenerator(
  action: Action<
    Success<Actions.api.media.upload.started, Actions.api.media.upload.done>
  >
) {
  yield put(
    actions.ws.send({
      type: 'sendMessage',
      message: {
        room: action.payload.params.extra.chatId,
        message: JSON.stringify(action.payload.result),
      },
    })
  );
}

function* readMessage(action: Action<Actions.ui.chat.changeActiveChat>) {
  if (action.payload.activeChatId) {
    yield put(
      actions.ws.send({
        type: 'readMessage',
        message: { roomId: action.payload.activeChatId },
      })
    );
  }
}

function* showNewChat(action: Action<Actions.ws.event>) {
  if (action.payload.method === 'addToRoom') {
    const { roomId } = action.payload;
    const chats: Chats = yield select((state: ReduxState) => state.chat.chats);

    if (chats[roomId] === undefined) {
      yield put(
        actions.api.chat.chatInfo.started({
          chatId: roomId,
        })
      );
    }
  }

  if (action.payload.method === 'message') {
    const { roomId } = action.payload;
    const chats: Chats = yield select((state: ReduxState) => state.chat.chats);

    const id: string = yield select(
      (state: ReduxState) => state.access.authRecord?.userId
    );
    if (
      chats[roomId] === undefined &&
      action.payload.type !== 'system.people'
    ) {
      yield put(
        actions.api.chat.chatInfo.started({
          chatId: roomId,
          extra: action.payload.type,
        })
      );
    }
    if (chats[roomId] === undefined && action.payload.type === 'system.deal') {
      yield put(
        actions.ui.chat.changeActiveChat({
          id: 'default',
          activeChatId: roomId,
        })
      );
    }
    if (
      chats[roomId]?.['settings'].find((item) => item.code === 'IS_MUTE')
        ?.value === 'false'
    ) {
      if (
        action.payload.type === 'user.message' &&
        action.payload.memberId !== id
      ) {
        const audio = new Audio('/sound/message.mp3');
        audio.play();
      }
      if (
        action.payload.type === 'user.sendAlert' &&
        action.payload.memberId !== id
      ) {
        const audio = new Audio('/sound/notification.mp3');
        audio.play();
      }
    }
  }
}
function* updateChat(
  action: Action<
    Success<Actions.api.chat.chatInfo.started, Actions.api.chat.chatInfo.done>
  >
) {
  yield put(
    actions.api.chat.chatEdit.started({
      chatId: action.payload.params.chatId,
      members: action.payload.result.members.map((item) => ({
        userId: item.userId,
        roles: item.roles.map((item) => item.roleName),
      })),
      settings: action.payload.result.settings.map((item) =>
        item.code === 'JOIN_ROOM' ? { ...item, value: 'true' } : item
      ),
    })
  );
}

function* updateP2PChat(
  action: Action<
    Success<Actions.api.chat.newChat.started, Actions.api.chat.newChat.done>
  >
) {
  if (action.payload.params.extra?.update) {
    yield put(
      actions.api.chat.chatEdit.started({
        chatId: action.payload.result.chatId,
        // chatGroupId: group.groupId,
        members: action.payload.result.members.map((item) => ({
          userId: item.userId,
          roles: item.roles.map((item) => item.roleName),
        })),
        settings: action.payload.result.settings.map((item) =>
          item.code === 'JOIN_ROOM' ? { ...item, value: 'true' } : item
        ),
      })
    );
  }
}

export function* chatSaga() {
  yield takeEvery(
    [actions.api.access.signUp.done.type, actions.api.access.signIn.done.type],
    startGenerator
  );

  yield takeEvery(actions.ws.connected.type, joinChatsGenerator);
  yield takeEvery(actions.api.media.upload.done.type, uploadChatsGenerator);
  yield takeEvery(actions.ws.event, showNewChat);
  yield takeEvery(actions.api.chat.chatInfo.done.type, updateChat);
  yield takeEvery(actions.api.chat.newChat.done.type, updateP2PChat);
  yield takeEvery(actions.ui.chat.changeActiveChat, readMessage);
}
