import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { TabBar } from 'src/components/organisms/TabBar';
import { ReduxState } from 'src/reducers';
import { ChatGroup, ChatInstance } from 'src/types';

export type Props = {
  instanceId: ChatInstance['id'];
  activeGroupId: ChatInstance['activeGroupId'];
  showContextMenu: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export const ChatHeader: React.FC<Props> = ({
  instanceId,
  activeGroupId,
  showContextMenu,
}) => {
  const changeActiveGroup = (id: ChatGroup['chatGroupId']) => {
    dispatch(
      actions.ui.chat.changeActiveGroup({
        id: instanceId,
        activeGroupId: id,
      })
    );
  };

  const dispatch = useDispatch();
  const groups = useSelector((state: ReduxState) =>
    state.chat.groups.filter((group) => group.parentGroupId === null)
  );

  return (
    <TabBar
      tabsData={groups}
      changeActiveGroup={changeActiveGroup}
      activeGroupId={activeGroupId}
      showContextMenu={showContextMenu}
    />
  );
};
