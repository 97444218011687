import React from 'react';

import { Input } from 'src/components/atoms/Input';

import styles from 'src/components/molecules/GroupChatCreatForm/styles.module.css';

export type Props = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEnterSubmit?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

export const GroupChatCreatForm: React.FC<Props> = ({
  value,
  onChange,
  onEnterSubmit,
  disabled,
}) => {
  return (
    <form className={styles.form} onSubmit={(e) => e.preventDefault()}>
      <label htmlFor="groupName">Имя чата</label>
      <Input
        name="groupName"
        type="text"
        value={value}
        placeholder=""
        onChange={onChange}
        onEnterSubmit={onEnterSubmit}
        disabled={disabled}
      />
    </form>
  );
};
