import { Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { Modal } from 'src/components/atoms/Modal';
import { Accordion } from 'src/components/molecules/Accordion';
import { ChatFooter } from 'src/components/organisms/ChatFooter';
import {
  Position,
  TypeDialogPanelProp,
} from 'src/components/organisms/ChatInstance';
import { ChatList } from 'src/components/organisms/ChatList';
import { DropItem } from 'src/components/organisms/DropItemList';
import { GroupChatCreation } from 'src/components/organisms/GroupChatCreation';
import {
  Functionality,
  SectionHeader,
} from 'src/components/organisms/SectionHeader';
import { CHAT_FAVORITES_GROUP } from 'src/constants';
import { ReduxState } from 'src/reducers';
import {
  Chat,
  ChatInstance,
  ChatStatus,
  ChatType,
  FilterType,
} from 'src/types';
import { filterFavorite, isFavorite } from 'src/utils/chat';
import { getItem, setItem } from 'src/utils/storage';

import styles from 'src/components/organisms/ChatContactsPanel/styles.module.css';

export type Props = {
  activeGroupId: ChatInstance['activeGroupId'];
  activeChatId: ChatInstance['activeChatId'];
  instanceId: ChatInstance['id'];
  isShown: boolean;
  setIsShown: (value: React.SetStateAction<boolean>) => void;
  setIsShownChatMenu: (value: React.SetStateAction<boolean>) => void;
  showContextMenu: (e: React.MouseEvent<HTMLDivElement>) => void;
  position: Position;
  setPosition: React.Dispatch<React.SetStateAction<Position>>;
  setTypePanel: (value: React.SetStateAction<TypeDialogPanelProp>) => void;
};

export const selectableContact = (
  chatType: ChatType['code'],
  userStatus: ChatStatus['userStatus'] | undefined
) => {
  // if (chatType === 'P2P' && userStatus === 'OFFLINE') return false;
  // if (chatType === 'DEAL') return false; // TODO: was for limited types
  if (chatType === 'P2P' && userStatus === 'OFFLINE') return true;
  if (chatType === 'DEAL') return true;
  return true;
};

export const ChatContactsPanel: React.FC<Props> = ({
  instanceId,
  activeGroupId,
  activeChatId,
  isShown,
  setIsShown,
  showContextMenu,
  position,
  setPosition,
  setTypePanel,
}) => {
  const dispatch = useDispatch();
  const [alertType, setAlertType] = useState<'error' | 'success' | 'warning'>(
    'success'
  );
  const [alertMessage, setAlertMessage] = useState('');
  const [visible, setVisible] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedChats, setSelectedChats] = useState<Chat['chatId'][]>([]);
  const users = useSelector((state: ReduxState) => state.chat.temp.users);
  const handleOpenModalGetAllUsers = () => {
    setIsModalOpen((isModalOpen) => !isModalOpen);
    dispatch(actions.api.chat.chatUsersList.started({}));
  };

  const toggleEditMode = () => {
    setEditMode((prevState) => !prevState);
    !isEditMode
      ? setSelectedChats(
          chats
            .filter((item) =>
              selectableContact(item.chatType.code, item.status?.userStatus)
            )
            .map((chat) => chat.chatId)
        )
      : setSelectedChats([]);
  };

  const handleSelectedChatsClick = (chatId: string, selectable: boolean) => {
    if (!selectable) {
      return;
    }
    setSelectedChats((selectedChats) =>
      selectedChats.includes(chatId)
        ? selectedChats.filter((n) => n !== chatId)
        : [chatId, ...selectedChats]
    );
  };

  const addSelectedChatsFromAccordeon = (
    selectedChatsAccordeon: Chat['chatId'][]
  ) => {
    const selectedChatsCopy = [...selectedChats, ...selectedChatsAccordeon];
    const uniqueSelectedChats = selectedChatsCopy.filter(
      (item, index) => selectedChatsCopy.indexOf(item) === index
    );
    setSelectedChats(uniqueSelectedChats);
  };

  const deleteChatsFromAccordeon = (
    chatId: Chat['chatId'] | Chat['chatId'][]
  ) => {
    let selectedChatsCopy;
    if (typeof chatId === 'string') {
      selectedChatsCopy = selectedChats.filter((item) => item !== chatId);
      setSelectedChats(selectedChatsCopy);
    } else if (typeof chatId === 'object') {
      selectedChatsCopy = selectedChats.filter(
        (item) => !chatId.includes(item)
      );
      setSelectedChats(selectedChatsCopy);
    }
  };

  const { activeGroup, subGroups, chats, currentBlastMessage, blastFile } =
    useSelector((state: ReduxState) => ({
      activeGroup: state.chat.groups.find(
        (group) => group.chatGroupId === activeGroupId
      ),
      subGroups: state.chat.groups.filter(
        (group) => group.parentGroupId === activeGroupId
      ),
      chats: Object.values(state.chat.chats) as Chat[],
      currentBlastMessage: state.chat.temp.currentBlastMessage,
      blastFile: state.media.blastFile,
    }));

  const currentChatsIds = [
    ...(activeGroup?.chats.flat() ?? []),
    ...subGroups.map((group) => group.chats).flat(),
  ]; // TODO: move to REDUX

  const favoriteChats = chats.filter((chat) =>
    isFavorite(chat, currentChatsIds)
  ); // TODO: move to REDUX

  const favoriteIds = favoriteChats.map(({ chatId }) => chatId);

  const filteredSubGroups = !!favoriteChats.length
    ? subGroups.map((group) => {
        const chats = filterFavorite(group.chats, favoriteChats);
        return { ...group, chats };
      })
    : subGroups; // TODO: move to REDUX

  const nestedChatsCount = subGroups.reduce(
    (count, current) => count + current.chats.length,
    0
  );
  const activeChatsCount = activeGroup?.chats.length ?? 0;
  const totalChatsCount = activeChatsCount + nestedChatsCount;
  const [functionality, setFunctionality] = useState<Functionality[]>(
    getItem('groupTools', ['blast', 'search'])
  );

  const dropItemsHeader: DropItem[] = [
    {
      id: '0',
      title: 'Поиск',
      endIcon: 'pushpin',
      iconStart: 'search',
      disabled: false,
      pinned: functionality.includes('search'),
    },
    {
      id: '1',
      title: 'Бласт',
      endIcon: 'pushpin',
      iconStart: 'blast',
      disabled: false,
      pinned: functionality.includes('blast'),
    },
  ];

  const dropItemsBody: DropItem[] = [
    {
      id: '2',
      title: 'Сортировать',
      endIcon: 'arrow',
      subItems: [
        {
          id: '3',
          title: 'Сортировка А-Я',
          sortKey: 'name',
          action: () => filterChat('name'),
        },
        {
          id: '4',
          title: 'По последним сообщениям',
          sortKey: 'date',
          action: () => filterChat('date'),
        },
        {
          id: '5',
          title: 'Нет сортировки',
          sortKey: 'none',
          action: () => filterChat('none'),
        },
      ],
    },
    {
      id: '3',
      title: 'Создать групповой чат',
      action: handleOpenModalGetAllUsers,
    },
  ];

  const filterChat = (filterType: FilterType) => {
    localStorage.setItem('filterType', filterType);
    dispatch(actions.ui.chat.filterChats({ type: filterType }));
    setIsShown(false);
  };

  useEffect(() => {
    setItem('groupTools', functionality);
  }, [functionality]);

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setVisible(false);
      }, 5000);
    }
  }, [visible]);

  return (
    <div className={styles.root}>
      <SectionHeader
        isEditMode={isEditMode}
        toggleEditMode={toggleEditMode}
        title={activeGroup?.chatGroupName}
        subTitle={`Чатов: ${totalChatsCount}`}
        functionality={functionality}
        activeChatId={activeChatId}
        dropItemsHeader={dropItemsHeader}
        setFunctionality={setFunctionality}
        isShown={isShown}
        setIsShown={setIsShown}
        position={position}
        setPosition={setPosition}
        dropItemsBody={dropItemsBody}
        setTypePanel={setTypePanel}
        isModalOpen={isModalOpen}
      />
      <div className={styles.chatList}>
        {!!favoriteChats.length && (
          <Accordion
            title="Избранное"
            icon="star"
            isEditMode={isEditMode}
            totalItems={favoriteChats.length}
            nestedChatsIds={favoriteChats.map((item) => item.chatId)}
            favoriteChats={favoriteChats}
            addSelectedChatsFromAccordeon={addSelectedChatsFromAccordeon}
            deleteChatsFromAccordeon={deleteChatsFromAccordeon}
            isActive={true}
          >
            <ChatList
              instanceId={instanceId}
              chatIds={favoriteIds}
              isEditMode={isEditMode}
              activeChatId={activeChatId}
              handleSelectedChatsClick={handleSelectedChatsClick}
              selectedChats={selectedChats}
              showContextMenu={showContextMenu}
              chatsValue={chats}
              setTypePanel={setTypePanel}
            />
          </Accordion>
        )}
        {filteredSubGroups.map((group, index) => (
          <Accordion
            isEditMode={isEditMode}
            key={group.chatGroupId}
            title={group.chatGroupName}
            totalItems={group.chats.length}
            isActive={index === 0}
            nestedChatsIds={group.chats}
            addSelectedChatsFromAccordeon={addSelectedChatsFromAccordeon}
            deleteChatsFromAccordeon={deleteChatsFromAccordeon}
            icon={
              group.chatGroupName === CHAT_FAVORITES_GROUP ? 'star' : 'folder'
            }
          >
            <ChatList
              instanceId={instanceId}
              chatIds={group.chats}
              isEditMode={isEditMode}
              activeChatId={activeChatId}
              handleSelectedChatsClick={handleSelectedChatsClick}
              selectedChats={selectedChats}
              showContextMenu={showContextMenu}
              chatsValue={chats}
              setTypePanel={setTypePanel}
            />
          </Accordion>
        ))}

        <ChatList
          instanceId={instanceId}
          chatIds={
            activeGroup?.chats.filter((id) => !favoriteIds.includes(id)) ?? []
          }
          isEditMode={isEditMode}
          activeChatId={activeChatId}
          handleSelectedChatsClick={handleSelectedChatsClick}
          selectedChats={selectedChats}
          showContextMenu={showContextMenu}
          chatsValue={chats}
          setTypePanel={setTypePanel}
        />
      </div>

      {isEditMode && (
        <ChatFooter
          type="group"
          blast
          chatId={selectedChats}
          activeChatId={activeChatId}
          toggleEditMode={toggleEditMode}
          setAlertType={setAlertType}
          setAlertMessage={setAlertMessage}
          setVisible={setVisible}
          currentBlastMessage={currentBlastMessage}
          blastFile={blastFile}
        />
      )}
      {visible && (
        <Alert
          className={styles.alertWrapper}
          onClose={() => {
            setVisible(false);
          }}
          variant="filled"
          severity={alertType}
        >
          {alertMessage}
        </Alert>
      )}
      {isModalOpen && (
        <Modal isOpen={isModalOpen} handleClose={() => setIsModalOpen(false)}>
          <GroupChatCreation
            activeGroupId={activeGroupId}
            contacts={users}
            handleClose={() => setIsModalOpen(false)}
            filterChat={filterChat}
          />
        </Modal>
      )}
    </div> // TODO remove contacts
  );
};
