import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { ChatName } from 'src/components/molecules/ChatName';
import { SectionEditingGroupChat } from 'src/components/molecules/SectionEditingGroupChat';
import { TypeDialogPanelProp } from 'src/components/organisms/ChatInstance';
import { DropItem } from 'src/components/organisms/DropItemList';
import { ReduxState } from 'src/reducers';
import { Chat, ChatInstance, ChatInternal, ID, User } from 'src/types';
import { createChat, isIdContainsChecking } from 'src/utils/chat';

export type Props = {
  value?: string;
  owners: User[];
  admins: User[];
  activeGroupId: ChatInstance['activeGroupId'];
  members: User[];
  currentUserId?: ID;
  showUserInfo?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string
  ) => void;
  handleMembers?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string
  ) => void;
  isDeal: boolean;
  setTypePanel?: (value: React.SetStateAction<TypeDialogPanelProp>) => void;
  currentChat?: ChatInternal;
  setMemberId?: React.Dispatch<React.SetStateAction<string>>;
  isChatInfo?: boolean;
};

export const EditingGroupChatPanel: React.FC<Props> = ({
  value,
  owners,
  showUserInfo,
  members,
  currentUserId,
  activeGroupId,
  admins,
  handleMembers,
  isDeal,
  setTypePanel,
  setMemberId,
  currentChat,
  isChatInfo,
}) => {
  const [isShown, setIsShown] = useState(false);

  const membersFormat = members.map((item) => ({
    ...item,
    roles: ['ChatMember'],
  }));

  const adminsFormat = admins.map((item) => ({
    ...item,
    roles: ['ChatAdmin'],
  }));

  const ownersFormat = owners.map((item) => ({
    ...item,
    roles: ['Owner'],
  }));

  const unitedMembers = ownersFormat.concat(adminsFormat, membersFormat);

  const isOwner = isIdContainsChecking(owners, currentUserId);
  const isAdmin = isIdContainsChecking(admins, currentUserId);

  const dispatch = useDispatch();

  const openProfile = (memberId: string) => {
    setMemberId?.(memberId);
    setTypePanel?.('infoAboutUser');
    setIsShown(false);
  };
  const { userId, chats, group } = useSelector((state: ReduxState) => ({
    chats: state.chat.chats,
    userId: state.access.authRecord?.userId,
    group: state.chat.groups,
  }));
  const goToActiveGroup = (group: string | undefined) =>
    dispatch(
      actions.ui.chat.changeActiveGroup({
        id: 'default',
        activeGroupId: group,
      })
    );
  const goToActiveChat = (chatId: Chat['chatId'] | undefined) => {
    if (typeof chatId !== 'undefined') {
      dispatch(
        actions.ui.chat.changeActiveChat({
          id: 'default',
          activeChatId: chatId,
        })
      );
    }
  };
  const openChat = (memberId: string) => {
    const existChat = Object.values(chats).find((item) => {
      if (
        item?.members.some((i) => i.userId === memberId) &&
        item?.members.some((i) => i.userId === userId) &&
        item?.chatType.code === 'P2P'
      ) {
        return item;
      }
    });
    if (existChat !== undefined) {
      const existGroup = group.find((item) =>
        item.chats.some((i) => i === existChat.chatId)
      );
      goToActiveGroup(existGroup?.chatGroupId);
      goToActiveChat(existChat?.chatId);
    } else {
      dispatch(
        actions.api.chat.newChat.started({
          ...createChat({
            members: [
              {
                userId: userId ?? 'default',
                roles: ['Owner', 'ChatAdmin'],
                name: 'Отправитель',
              },
              {
                userId: memberId,
                roles: ['ChatMember'],
                name: 'default',
              },
            ],
            groups: [{ chatGroupId: activeGroupId }],
          }),
          extra: { update: true },
        })
      );
    }

    setIsShown(false);
    setTypePanel?.('chat');
  };

  const deleteFromAdmin = (userId: string) => {
    if (currentChat) {
      dispatch(
        actions.api.chat.chatEdit.started({
          chatId: currentChat?.chatId,
          members: unitedMembers.map((member) => {
            if (member.userId === userId) {
              const indexRole = member.roles.indexOf('ChatAdmin');
              member.roles.splice(indexRole, 1);
              member.roles.push('ChatMember');
            }
            return member;
          }),
        })
      );
    }
    setIsShown(false);
  };

  const addToAdmin = (userId: string) => {
    if (currentChat) {
      dispatch(
        actions.api.chat.chatEdit.started({
          chatId: currentChat.chatId,
          members: unitedMembers.map((member) => {
            if (member.userId === userId) {
              const indexRole = member.roles.indexOf('ChatMember');
              member.roles.splice(indexRole, 1);
              member.roles.push('ChatAdmin');
            }
            return member;
          }),
        })
      );
    }
    setIsShown(false);
  };

  const deleteFromGroup = (userId: string) => {
    if (currentChat) {
      dispatch(
        actions.api.chat.chatEdit.started({
          chatId: currentChat.chatId,
          members: unitedMembers.filter((member) => member.userId !== userId),
        })
      );
    }
    setIsShown(false);
  };

  const openProfileItem = {
    id: '0',
    title: 'Открыть профиль',
    action: (memberId?: string) => openProfile(memberId ? memberId : 'default'),
  };

  const openChatItem = {
    id: '1',
    title: 'Начать чат',
    action: (memberId?: string) => openChat(memberId ? memberId : 'default'),
  };

  const deleteFromAdminItem = {
    id: '2',
    title: 'Удалить из администраторов',
    action: (userId?: string) => deleteFromAdmin(userId ? userId : 'default'),
  };

  const addToAdminItem = {
    id: '2',
    title: 'Добавить в администраторы',
    action: (userId?: string) => addToAdmin(userId ? userId : 'default'),
  };

  const deleteFromChatItem = {
    id: '3',
    title: 'Удалить из чата',
    action: (userId?: string) => {
      deleteFromGroup(userId ? userId : 'default');
    },
  };

  const dropItemsHeaderOwner: DropItem[] = [openProfileItem, openChatItem];
  const dropItemsCurrentOwner: DropItem[] = [openProfileItem];

  const dropItemsHeaderAdmin: DropItem[] =
    !isDeal && (isAdmin || isOwner)
      ? [openProfileItem, openChatItem, deleteFromAdminItem, deleteFromChatItem]
      : [openProfileItem, openChatItem];
  const dropItemsCurrentAdmin: DropItem[] = !isDeal
    ? [openProfileItem, deleteFromAdminItem, deleteFromChatItem]
    : [openProfileItem, openChatItem];

  const dropItemsHeaderMember: DropItem[] =
    !isDeal && (isAdmin || isOwner)
      ? [openProfileItem, openChatItem, addToAdminItem, deleteFromChatItem]
      : [openProfileItem, openChatItem];
  const dropItemsCurrentMember: DropItem[] = !isDeal
    ? [openProfileItem, deleteFromChatItem]
    : [openProfileItem, openChatItem];

  useEffect(() => {
    !currentChat && setTypePanel?.('chat');
  }, [currentChat]);

  return (
    <>
      <ChatName
        value={value}
        isDeal={isDeal}
        setTypePanel={setTypePanel}
        activeGroupId={currentChat?.groupId}
        members={members}
        owners={owners}
        admins={admins}
        currentChat={currentChat}
        isOwner={isOwner}
        isAdmin={isAdmin}
      />
      <SectionEditingGroupChat
        people={owners}
        showUserInfo={showUserInfo}
        currentUserId={currentUserId}
        isShown={isShown}
        dropItemsHeader={dropItemsHeaderOwner}
        setIsShown={setIsShown}
        isChatInfo={isChatInfo}
        title="Организатор"
        dropItemsForCurrentUser={dropItemsCurrentOwner}
      />

      {admins.length ? (
        <SectionEditingGroupChat
          people={admins}
          title="Администратор"
          showUserInfo={showUserInfo}
          handleMembers={handleMembers}
          isShown={isShown}
          dropItemsHeader={dropItemsHeaderAdmin}
          setIsShown={setIsShown}
          currentUserId={currentUserId}
          isChatInfo={isChatInfo}
          dropItemsForCurrentUser={dropItemsCurrentAdmin}
        />
      ) : null}

      {members.length ? (
        <SectionEditingGroupChat
          people={members}
          title="Участники"
          showUserInfo={showUserInfo}
          handleMembers={handleMembers}
          isShown={isShown}
          dropItemsHeader={dropItemsHeaderMember}
          setIsShown={setIsShown}
          currentUserId={currentUserId}
          isChatInfo={isChatInfo}
          dropItemsForCurrentUser={dropItemsCurrentMember}
        />
      ) : null}
    </>
  );
};
