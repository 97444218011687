import React from 'react';

import styles from 'src/components/organisms/Hightlight/styles.module.css';

export type HightlightProps = {
  filter: string;
  str?: string;
  setRefsFunc?: HTMLElement[] | ((el: HTMLElement) => number);
};

export const Hightlight = (props: HightlightProps) => {
  const { filter, str, setRefsFunc } = props;

  const filterEsc = filter.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  const regexp = new RegExp(filterEsc, 'ig');
  const matchValue = str?.match(regexp);

  if (!str || !filterEsc || !matchValue) {
    return <>{str}</>;
  }

  return (
    <>
      {str.split(regexp).map((s, index, arr) => {
        if (index < arr.length - 1) {
          const c = matchValue.shift();
          return (
            <React.Fragment key={index}>
              {s}
              <span
                ref={setRefsFunc as (el: HTMLElement) => number | HTMLElement}
                className={styles.hightlight}
              >
                {c}
              </span>
            </React.Fragment>
          );
        }
        return <React.Fragment key={0 - index}>{s}</React.Fragment>;
      })}
    </>
  );
};
