import React from 'react';

import { ReactComponent as LogoDark } from 'src/assets/icons/LogoDark.svg';
import { ReactComponent as LogoLight } from 'src/assets/icons/LogoLight.svg';
import { ReactComponent as LogoTitleDark } from 'src/assets/icons/LogoTitleDark.svg';
import { ReactComponent as LogoTitleLight } from 'src/assets/icons/LogoTitleLight.svg';

import styles from 'src/components/atoms/Logo/styles.module.css';

export type Props = {
  isDark?: boolean;
  isHorizontal?: boolean;
};

export const Logo: React.FC<Props> = ({
  isDark = false,
  isHorizontal = false,
}) => (
  <div className={isHorizontal ? styles.horizontal : styles.wrapper}>
    <div className={styles.logoWrapper}>
      {isDark && <LogoDark className={styles.logo} />}
      {!isDark && <LogoLight className={styles.logo} />}
    </div>
    <div>
      {isDark && <LogoTitleDark />}
      {!isDark && <LogoTitleLight />}
    </div>
  </div>
);
