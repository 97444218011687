import dayjs, { Dayjs } from 'dayjs';

import { DIRECTIONS, INSTRUMENTS } from 'src/constants/blotter';
import { ID, Ticket } from 'src/types';

export const sortTickets = (
  tickets: Ticket[],
  sortType: string,
  sortField: keyof Ticket
): Ticket[] => {
  const sortedTickets = tickets.sort((a, b) => {
    if (sortType === 'asc') {
      return a[sortField]! > b[sortField]! ? 1 : -1;
    }
    return a[sortField]! < b[sortField]! ? 1 : -1;
  });
  return sortedTickets;
};

export const findStartAndEndDate = (
  listDates: { dateCreated: string }[]
): { startDate: string; endDate: string } => {
  const startDate = dayjs(
    listDates.reduce((acc, cur) => {
      if (acc.dateCreated > cur.dateCreated) {
        return cur;
      }
      return acc;
    }).dateCreated
  ).format('DD.MM.YYYY');

  const endDate = dayjs(
    listDates.reduce((acc, cur) => {
      if (acc.dateCreated < cur.dateCreated) {
        return cur;
      }
      return acc;
    }).dateCreated
  ).format('DD.MM.YYYY');

  return { startDate, endDate };
};

export const getWorkingDay = (day: Dayjs) => {
  switch (day.day()) {
    case 0:
      return day.add(1, 'day');

    case 6:
      return day.add(2, 'day');

    default:
      return day;
  }
};

export const addDays = (day: Dayjs, count: number) => {
  let date = day;

  for (let i = 0; i < count; i++) {
    date = getWorkingDay(date.add(1, 'day'));
  }

  return date;
};

export const getCorrectDay = (
  instrument: string,
  nameCurrencyPair: string | null,
  day?: Dayjs
): Dayjs => {
  const currencyPairsWithOneDay = ['USD/CAD', 'USD/TRY', 'USD/PHP', 'USD/RUB'];

  switch (instrument) {
    case INSTRUMENTS.SPOT:
      if (
        nameCurrencyPair &&
        currencyPairsWithOneDay.includes(nameCurrencyPair)
      ) {
        return addDays(day || dayjs(), 1);
      }

      return addDays(dayjs(), 2);

    case INSTRUMENTS.FORWARD || INSTRUMENTS.SWAP:
      return addDays(dayjs(), 7);

    default:
      return getWorkingDay(dayjs());
  }
};

const replaceTransactionDirection = (
  transactionDirection: string,
  transactionDirectionDescription: string
) => {
  if (transactionDirection === DIRECTIONS.BUY) {
    return {
      transactionDirection: DIRECTIONS.SELL,
      transactionDirectionDescription: 'Продажа',
    };
  }
  if (transactionDirection === DIRECTIONS.SELL) {
    return {
      transactionDirection: DIRECTIONS.BUY,
      transactionDirectionDescription: 'Покупка',
    };
  }
  if (transactionDirection === DIRECTIONS.BORROW) {
    return {
      transactionDirection: DIRECTIONS.LEND,
      transactionDirectionDescription: 'Занять',
    };
  }
  if (transactionDirection === DIRECTIONS.LEND) {
    return {
      transactionDirection: DIRECTIONS.BORROW,
      transactionDirectionDescription: 'Одолжить',
    };
  }

  return {
    transactionDirection,
    transactionDirectionDescription,
  };
};

const replaceLegsTransactionDirection = (
  shortLegTransactionDirection: string
) => {
  if (shortLegTransactionDirection === DIRECTIONS.BUY) {
    return {
      shortLegTransactionDirection: DIRECTIONS.SELL,
      shortLegTransactionDirectionDescription: 'Продажа',
      longLegTransactionDirection: DIRECTIONS.BUY,
      longLegTransactionDirectionDescription: 'Покупка',
    };
  }
  if (shortLegTransactionDirection === DIRECTIONS.SELL) {
    return {
      shortLegTransactionDirection: DIRECTIONS.BUY,
      shortLegTransactionDirectionDescription: 'Покупка',
      longLegTransactionDirection: DIRECTIONS.SELL,
      longLegTransactionDirectionDescription: 'Продажа',
    };
  }
};

export const replaceTraider = (ticket: Ticket, currentUserId?: ID): Ticket =>
  currentUserId === ticket.counterAgentTraderContactId
    ? {
        ...ticket,
        traderContact: ticket.counterAgentTraderContact,
        traderContactOrganization: ticket.counterAgentTraderContactOrganization,
        counterAgentTraderContact: ticket.traderContact,
        counterAgentTraderContactOrganization: ticket.traderContactOrganization,
        ...replaceTransactionDirection(
          ticket.transactionDirection,
          ticket.transactionDirectionDescription
        ),
        ...replaceLegsTransactionDirection(ticket.shortLegTransactionDirection),
      }
    : ticket;
