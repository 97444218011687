import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { ChatInstanceData } from 'src/components/organisms/ChatInstance';
import { Hightlight } from 'src/components/organisms/Hightlight';
import { Param } from 'src/components/organisms/SearchMessageMainPanel';
import { ReduxState } from 'src/reducers';
import { Chat } from 'src/types';
import { CreatingChat, createChat } from 'src/utils/chat';

import styles from 'src/components/molecules/SearchRow/styles.module.css';

export type Props = {
  firstText: string;
  downText: string;
  centerText?: string | null;
  rightText?: string;
  isContactSearch?: boolean;
  filter: string;
  itemId: string;
  closeSearch: () => void;
  type: 'contact' | 'chat' | 'tool';
  handleMembers?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string
  ) => void;
  addParamToGlobalSearch?: (param: Param) => void;
  name?: string;
};

export const SearchRow: React.FC<Props> = ({
  firstText,
  downText,
  centerText,
  rightText,
  filter,
  itemId,
  closeSearch,
  type,
  isContactSearch,
  handleMembers,
  addParamToGlobalSearch,
  name,
}) => {
  const dispatch = useDispatch();
  const { activeGroupId } = useContext(ChatInstanceData);

  const { chats, group, userId } = useSelector((state: ReduxState) => ({
    chats: state.chat.chats,
    group: state.chat.groups,
    groups: state.chat.groups,
    userId: state.access.authRecord?.userId,
  }));

  const goToActiveChat = (chatId: Chat['chatId'] | undefined) => {
    if (typeof chatId !== 'undefined') {
      dispatch(
        actions.ui.chat.changeActiveChat({
          id: 'default',
          activeChatId: chatId,
        })
      );
    }
  };

  const goToActiveGroup = (group: string | undefined) =>
    dispatch(
      actions.ui.chat.changeActiveGroup({
        id: 'default',
        activeGroupId: group,
      })
    );

  const handleClick = (newChat: CreatingChat) => {
    const chatExists = chats[itemId];
    const existP2PChat = Object.values(chats).find((item) => {
      if (item?.members === undefined) {
        return false;
      }
      return (
        item?.members.some((item) => item.userId === itemId) &&
        item.chatType.code === 'P2P'
      );
    });
    if (chatExists) {
      const groupId = group.find((item) =>
        item.chats.includes(itemId)
      )?.chatGroupId;
      goToActiveGroup(groupId);
      goToActiveChat(itemId);
    } else if (existP2PChat) {
      goToActiveGroup(existP2PChat.groupId);
      goToActiveChat(existP2PChat.chatId);
    } else {
      if (type === 'chat') {
        dispatch(actions.api.chat.chatInfo.started({ chatId: itemId }));
        goToActiveChat(itemId);
      } else {
        dispatch(
          actions.api.chat.newChat.started({
            ...createChat(newChat),
            extra: { update: true },
          })
        );
      }
    }

    closeSearch();
  };

  return (
    <div
      className={styles.container}
      style={isContactSearch ? { width: '30rem' } : undefined}
    >
      <div className={styles.left}>
        <p
          role="button"
          tabIndex={0}
          className={styles.head}
          onClick={(e) => {
            if (isContactSearch) {
              handleMembers?.(e, itemId);
              closeSearch();
            } else if (addParamToGlobalSearch) {
              addParamToGlobalSearch({ id: itemId, type, name });
            } else {
              handleClick({
                members: [
                  {
                    userId: userId ?? 'default',
                    roles: ['Owner', 'ChatAdmin'],
                    name: 'Отправитель',
                  },
                  {
                    userId: itemId,
                    roles: ['ChatMember'],
                    name: firstText,
                  },
                ],
                groups: [{ chatGroupId: activeGroupId ?? 'default' }],
              });
            }
          }}
        >
          <span className={styles.firstword}>
            <Hightlight filter={filter} str={firstText ?? ''} />
          </span>
        </p>
        <p className={styles.downword}>
          <Hightlight filter={filter} str={downText ?? ''} />
        </p>
      </div>
      <div className={styles.center}>
        <p className={styles.centertext}>{centerText ?? ''}</p>
      </div>
      <div className={styles.right}>
        <p className={styles.righttext}>{rightText ?? ''}</p>
      </div>
    </div>
  );
};
