import * as api from 'src/actions/api';
import * as iframe from 'src/actions/iframe';
import * as ui from 'src/actions/ui';
import * as ws from 'src/actions/ws';

export const actions = {
  api,
  iframe,
  ui,
  ws,
};
