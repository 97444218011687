import { Actions } from 'src/types';
import { actionCreator } from 'src/utils/factories';

export const access = {
  signOut: actionCreator<Actions.ui.access.signOut>('UI_ACCESS_SIGN_OUT'),
};

export const chat = {
  changeActiveGroup: actionCreator<Actions.ui.chat.changeActiveGroup>(
    'UI_CHAT_CHANGE_ACTIVE_GROUP'
  ),
  changeActiveChat: actionCreator<Actions.ui.chat.changeActiveChat>(
    'UI_CHAT_CHANGE_ACTIVE_CHAT'
  ),
  changeCurrentMessage: actionCreator<Actions.ui.chat.changeCurrentMessage>(
    'UI_CHAT_CHANGE_CURRENT_MESSAGE'
  ),
  changeBlastCurrentMessage:
    actionCreator<Actions.ui.chat.changeBlastCurrentMessage>(
      'UI_CHAT_CHANGE_BLAST_CURRENT_MESSAGE'
    ),
  filterChats: actionCreator<Actions.ui.chat.filterChats>('UI_FILTER_CHAT'),
  changeStatusMessage: actionCreator<Actions.ui.chat.changeStatusMessage>(
    'UI_CHANGE_STATUS_MESSAGE'
  ),
  clearFoundChats: actionCreator('UI_CLEAR_FOUND_CHATS'),
  updateErrorMessage: actionCreator<Actions.ui.chat.updateErrorMessage>(
    'UI_UPDATE_ERROR_MESSAGE'
  ),
};

export const media = {
  progress: actionCreator<Actions.ui.media.progress>('UI_MEDIA_PROGRESS'),
  prepareToSend: actionCreator<Actions.ui.media.prepareToSendFile>(
    'UI_MEDIA_PREPARE_TO_SEND_FILE'
  ),
  prepareToSendTemp: actionCreator<Actions.ui.media.prepareToSendTemp>(
    'UI_MEDIA_PREPARE_TO_SEND_TEMP'
  ),
  prepareToSendBlast: actionCreator<Actions.ui.media.prepareToSendFile>(
    'UI_MEDIA_PREPARE_TO_SEND_FILE_BLAST'
  ),
};

export const blotter = {
  changeColumns: actionCreator<Actions.ui.blotter.changeColumns>(
    'UI_BLOTTER_CHANGE_COLUMNS'
  ),
  resetColumns: actionCreator<Actions.ui.blotter.resetColumns>(
    'UI_BLOTTER_RESET_COLUMNS'
  ),
  changeSort: actionCreator<Actions.ui.blotter.changeSort>(
    'UI_BLOTTER_SORT_COLUMNS'
  ),
  changeFilter: actionCreator<Actions.ui.blotter.changeFilter>(
    'UI_BLOTTER_FILTER_COLUMNS'
  ),
  setOwnerTicketsFilter:
    actionCreator<Actions.ui.blotter.setOwnerTicketsFilter>(
      'UI_BLOTTER_SET_OWNER_TICKETS_FILTER'
    ),
  clearSearch: actionCreator<Actions.ui.blotter.clearSearch>(
    'UI_BLOTTER_ClEAR_SEARCH'
  ),
  updateTicket: actionCreator<Actions.ui.blotter.updateTicket>(
    'UI_BLOTTER_UPDATE_TICKET'
  ),
  orderHeader: actionCreator<Actions.ui.blotter.orderHeader>(
    'UI_BLOTTER_ORDER_HEADER'
  ),
  removeTicketFromModal:
    actionCreator<Actions.ui.blotter.removeTicketFromModal>(
      'UI_BLOTTER_REMOVE_TICKET'
    ),
};
