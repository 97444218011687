import React from 'react';

import { Icon } from 'src/components/atoms/Icon';
import { IconButton } from 'src/components/atoms/IconButton';

import styles from 'src/components/atoms/GroupChatTabBar/styles.module.css';

export type Props = {
  title: string;
  handleClose: () => void;
};

export const GroupChatTabBar: React.FC<Props> = ({ title, handleClose }) => (
  <div className={styles.tabBar}>
    <div className={styles.icon}>
      <Icon icon="cable" fill="#f2cd40" />
    </div>
    <h1 className={styles.title}>{title}</h1>
    <div className={styles.wrapper}>
      {/* <IconButton icon="openInFull" color="#ADB0BC" /> */}
      {/* TODO commented unused buttons. Dont delete */}
      <IconButton onClick={() => handleClose()} icon="close" color="#ADB0BC" />
      {/* <IconButton icon="morevert" color="#ADB0BC" /> */}
    </div>
  </div>
);
