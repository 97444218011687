import classNames from 'classnames';
import dayjs from 'dayjs';
import React from 'react';
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Autocomplete } from 'src/components/atoms/Autocomplete';
import { Button } from 'src/components/atoms/Button';
import { DatePickerModal } from 'src/components/atoms/DatePickerModal';
import { InputModal } from 'src/components/atoms/InputModal';
import { DIRECTIONS, INSTRUMENTS, LEGS } from 'src/constants/blotter';
import { REGEXP } from 'src/constants/regexp';
import { ReduxState } from 'src/reducers';
import { DictionariesFormValues, ITicketFormValues } from 'src/types';
import { getWorkingDay } from 'src/utils/blotter';

import styles from 'src/components/molecules/FormLeg/styles.module.css';

export type Props = {
  getValues?: UseFormGetValues<ITicketFormValues>;
  register?: UseFormRegister<ITicketFormValues>;
  leg: 'shortLeg' | 'longLeg';
  control: Control<ITicketFormValues, any>;
  instruments?: DictionariesFormValues[];
  setValue?: UseFormSetValue<ITicketFormValues>;
  directions?: DictionariesFormValues[];
};

export const FormLeg: React.FC<Props> = ({
  getValues,
  register,
  leg,
  control,
  instruments,
  setValue,
  directions,
}) => {
  const tenors = useSelector(
    (state: ReduxState) => state.monitor.dictionaries.tenor
  );

  const makePressets = () => {
    const count = { d: [], M: [], y: [], w: [] };

    const tenorsToUse = tenors?.filter(
      (item) => item.instrument_id === getValues?.(`${leg}.instrument.id`)
    );

    tenorsToUse?.forEach((item) => {
      if (item.code !== 'Сегодня') {
        count[item.value.unit as keyof typeof count].push(item as never);
      }
    });

    const pressets = tenorsToUse?.map((item, index) => {
      const isToday = item.code === 'Сегодня';
      const unitRemainder =
        count[item.value.unit as keyof typeof count].length % 3;
      const unit = item.value.unit;
      const nextUnit = tenorsToUse[index + 1]?.value.unit;
      const gridSpan =
        isToday || (unitRemainder === 1 && unit !== nextUnit)
          ? '3'
          : unitRemainder === 2 && unit !== nextUnit
          ? '2'
          : '1';
      return {
        label: (
          <div
            role="button"
            data-span={gridSpan}
            onClick={() => setValue?.(`${leg}.tenor.id`, item.id)}
            className={classNames(styles.preset, {
              [styles.active]: item.id === getValues?.(`${leg}.tenor.id`),
              [styles.line]:
                (unit !== nextUnit && tenorsToUse.length - 1 !== index) ||
                isToday,
              [styles.today]: isToday,
            })}
          >
            {item.code}
          </div>
        ),
        value: dayjs().add(item.value.count, `${item.value.unit as never}`),
      };
    });

    return pressets;
  };

  const getLimit = () => {
    if (leg === 'shortLeg') {
      return getWorkingDay(dayjs()).subtract(1, 'd');
    }
    if (leg === 'longLeg' && getValues && getValues('shortLeg.date_value')) {
      return getWorkingDay(getValues('shortLeg.date_value')!.add(1, 'd'));
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <span>{leg === LEGS.SHORTLEG ? 'Ближняя нога' : 'Дальняя нога'}</span>
        <div className={styles.direction}>
          <Button
            additionalClass={classNames(styles.buy, {
              [styles.active]:
                getValues?.(`${leg}.transaction_direction.code`) ===
                DIRECTIONS.BUY,
            })}
            size="xs"
            onClick={() => {
              setValue?.(
                `${leg}.transaction_direction`,
                directions?.find((item) => item?.code === DIRECTIONS.BUY) ||
                  null
              );
              setValue?.(
                `${
                  leg === 'shortLeg' ? 'longLeg' : 'shortLeg'
                }.transaction_direction`,
                directions?.find((item) => item?.code === DIRECTIONS.SELL) ||
                  null
              );
            }}
          >
            Покупка
          </Button>
          <Button
            additionalClass={classNames(styles.sell, {
              [styles.active]:
                getValues?.(`${leg}.transaction_direction.code`) ===
                DIRECTIONS.SELL,
            })}
            size="xs"
            onClick={() => {
              setValue?.(
                `${leg}.transaction_direction`,
                directions?.find((item) => item?.code === DIRECTIONS.SELL) ||
                  null
              );
              setValue?.(
                `${
                  leg === 'longLeg' ? 'shortLeg' : 'longLeg'
                }.transaction_direction`,
                directions?.find((item) => item?.code === DIRECTIONS.BUY) ||
                  null
              );
            }}
          >
            Продажа
          </Button>
        </div>
      </div>

      <div className={styles.fields}>
        <div className={styles.field}>
          <InputModal
            name={`${leg}.volume`}
            register={register}
            label="Объем"
            placeholder="Объем"
            regx={REGEXP.NUM2}
          />
        </div>

        <div className={styles.field}>
          <Controller
            name={`${leg}.instrument`}
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <Autocomplete
                  defaultValue={instruments?.find((item) =>
                    leg === 'shortLeg'
                      ? item?.code === INSTRUMENTS.SPOT
                      : item?.code === INSTRUMENTS.FORWARD
                  )}
                  label="Инструмент"
                  list={instruments?.filter((item) =>
                    leg === 'shortLeg'
                      ? item?.code === INSTRUMENTS.SPOT ||
                        item?.code === INSTRUMENTS.FORWARD
                      : item?.code === INSTRUMENTS.FORWARD
                  )}
                  onChange={field.onChange}
                  field={field}
                  placeholder="Инструмент"
                  readOnly={leg === 'longLeg'}
                />
              );
            }}
          />
        </div>

        {((leg === 'shortLeg' &&
          getValues?.('shortLeg.instrument.code') === INSTRUMENTS.FORWARD) ||
          leg === 'longLeg') && (
          <>
            <div className={styles.field}>
              <InputModal
                name={`${leg}.spot_price`}
                register={register}
                label="Спот цена"
                placeholder="Спот цена"
                regx={REGEXP.NUM4}
              />
            </div>

            <div className={styles.field}>
              <InputModal
                name={`${leg}.forward_points`}
                register={register}
                label="Фрвд. пункты"
                placeholder="Фрвд. пункты"
                regx={REGEXP.NUM6}
              />
            </div>
          </>
        )}

        <div className={styles.field}>
          <InputModal
            name={`${leg}.full_price`}
            register={register}
            label="Полная цена"
            placeholder="Полная цена"
            regx={REGEXP.NUM4}
          />
        </div>
        <div className={styles.field}>
          <Controller
            name={`${leg}.date_value`}
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <DatePickerModal
                  field={field}
                  label="Дата вал."
                  dateFrom={getLimit()}
                  presets={makePressets()}
                />
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};
