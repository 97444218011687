import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { Icon } from 'src/components/atoms/Icon';
import { IconButton } from 'src/components/atoms/IconButton';
import { Modal } from 'src/components/atoms/Modal';
import { TypeDialogPanelProp } from 'src/components/organisms/ChatInstance';
import { GroupChatCreation } from 'src/components/organisms/GroupChatCreation';
import { ReduxState } from 'src/reducers';
import { ChatInstance, ChatInternal, User } from 'src/types';

import styles from 'src/components/molecules/ChatName/styles.module.css';

export type Props = {
  value?: string;
  isDeal: boolean;
  setTypePanel?: (value: React.SetStateAction<TypeDialogPanelProp>) => void;
  activeGroupId?: ChatInstance['activeGroupId'];
  owners: User[];
  admins: User[];
  members: User[];
  currentChat?: ChatInternal;
  isOwner?: boolean;
  isAdmin?: boolean;
};

export const ChatName: React.FC<Props> = ({
  value,
  isDeal,
  setTypePanel,
  activeGroupId,
  owners,
  admins,
  members,
  currentChat,
  isOwner,
  isAdmin,
}) => {
  const dispatch = useDispatch();
  const users = useSelector((state: ReduxState) => state.chat.temp.users);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModalGetAllUsers = () => {
    setIsModalOpen((isModalOpen) => !isModalOpen);
    dispatch(actions.api.chat.chatUsersList.started({}));
  };
  return (
    <div className={styles.groupName}>
      <div className={styles.wrapperIcon}>
        <Icon icon="PEOPLE" fill="#00B7F0" />
      </div>
      <p>{value}</p>
      <div className={styles.wrapperIcons}>
        {!isDeal ? (
          <>
            <IconButton
              icon="chat"
              color="#ADB0BC"
              onClick={() => setTypePanel && setTypePanel('chat')}
            />
            {(isOwner || isAdmin) && (
              <IconButton
                icon="personAdd"
                color="#ADB0BC"
                onClick={handleOpenModalGetAllUsers}
              />
            )}
          </>
        ) : (
          <IconButton
            icon="chat"
            color="#ADB0BC"
            onClick={() => setTypePanel && setTypePanel('chat')}
          />
        )}

        {/* <IconButton icon="starBorder" color="#ADB0BC" /> */}
      </div>
      <Modal isOpen={isModalOpen} handleClose={() => setIsModalOpen(false)}>
        <GroupChatCreation
          defaultValue={value}
          defaultMembers={members}
          defaultOwners={owners}
          defaultAdmins={admins}
          activeGroupId={activeGroupId}
          contacts={users}
          handleClose={() => setIsModalOpen(false)}
          isEditingChat={true}
          currentChat={currentChat}
          isAdmin={isAdmin}
        />
      </Modal>
    </div>
  );
};
