import { FC, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { Button } from 'src/components/atoms/Button';
import { TicketContentRow } from 'src/components/molecules/TicketContentRow';
import { TicketHeader } from 'src/components/molecules/TicketHeader';
import { TicketSubHeader } from 'src/components/molecules/TicketSubHeader';
import { ModalStateContext } from 'src/components/providers/ModalProvider';
import { INSTRUMENTS } from 'src/constants/blotter';
import { ReduxState } from 'src/reducers';
import { Ticket, TicketStatus } from 'src/types';
import { convertCurrentDate } from 'src/utils/date';

import styles from 'src/components/organisms/modals/TicketNotification/styles.module.css';

export type Props = {
  defaultValue: Ticket;
};

export const TicketNotification: FC<Props> = ({ defaultValue }) => {
  const dispatch = useDispatch();
  const { userId, ticketNotifications } = useSelector((state: ReduxState) => ({
    userId: state.access.authRecord?.userId,
    ticketNotifications: state.blotter.ticketNotifications,
  }));
  // const filterObj = useSelector((state: ReduxState) => state.blotter.filterObj);

  const { setIsActiveNotification, clearDefaultNotification } =
    useContext(ModalStateContext);

  const isModalClosing =
    !ticketNotifications?.length || ticketNotifications?.length === 1;

  const handleTicket = (action: TicketStatus, id: string) => {
    if (isModalClosing) {
      setIsActiveNotification?.(false);
      clearDefaultNotification?.();
    }
    dispatch(
      actions.api.blotter.changeTicketStatus.started({
        ticket_id: defaultValue.id,
        action,
        extra: { userId: userId },
      })
    );
    dispatch(
      actions.ui.blotter.removeTicketFromModal({
        id,
      })
    );
  };

  const handleClose = (id: string) => {
    if (isModalClosing) {
      setIsActiveNotification?.(false);
    }
    dispatch(
      actions.ui.blotter.removeTicketFromModal({
        id,
      })
    );
  };

  const isNewRecepient = !!(
    defaultValue.ticketStatus === 'RECEIVED' &&
    userId === defaultValue.counterAgentTraderContactId
  );

  return (
    <form
      onSubmit={() => handleTicket('ACCEPT', defaultValue.id)}
      className={styles.wrapper}
    >
      <TicketHeader
        handleClose={() => handleClose(defaultValue.id)}
        title={defaultValue.counterAgentTraderContactOrganization}
      />
      <TicketSubHeader title={defaultValue.transactionNumber} />
      <ul className={styles.content}>
        <div className={styles.contentSection}>
          <TicketContentRow
            field="Класс"
            value={defaultValue.activeClassDescription}
          />
          <TicketContentRow
            field="Инструмент"
            value={defaultValue.instrumentDescription}
          />
          {defaultValue.instrument !== 'SWAP' && (
            <TicketContentRow
              field="Направление"
              value={defaultValue.transactionDirectionDescription}
            />
          )}
          <TicketContentRow field="Дилкод" value={defaultValue.dealCode} />
          {!isNewRecepient && (
            <>
              <TicketContentRow
                field="Статус"
                value={defaultValue.dealStatus}
              />
              {defaultValue.date && (
                <TicketContentRow
                  field="Дата & Время"
                  value={convertCurrentDate(defaultValue.date, 'dateTime')}
                />
              )}
            </>
          )}
        </div>
        <div className={styles.contentSection}>
          <TicketContentRow
            field="Трейдер"
            value={defaultValue.traderContact}
          />
          <TicketContentRow
            field="Фирма"
            value={defaultValue.traderContactOrganization}
          />
        </div>
        <div className={styles.contentSection}>
          <TicketContentRow
            field="Контрагент"
            value={defaultValue.counterAgentTraderContactOrganization}
          />
          <TicketContentRow
            field="Трейдер контр."
            value={defaultValue.counterAgentTraderContact}
          />
        </div>
        {(defaultValue.instrument === 'SPOT' ||
          defaultValue.instrument === 'FORWARD') && (
          <div className={styles.contentSection}>
            <TicketContentRow
              field="Вал. пара"
              value={defaultValue.currencyPair}
            />
            <TicketContentRow
              field="Объем"
              value={defaultValue.transactionVolume}
            />
            {defaultValue.instrument === 'FORWARD' && (
              <>
                <TicketContentRow field="Спот" value={defaultValue.spotPrice} />
                <TicketContentRow
                  field="Фрвд. пункты"
                  value={defaultValue.forwardPoints}
                />
              </>
            )}
            <TicketContentRow
              field="Полная цена"
              value={defaultValue.fullPrice}
            />
            <TicketContentRow
              field="Дата вал."
              value={convertCurrentDate(defaultValue.valueDate, 'shortRussian')}
            />
          </div>
        )}
        {defaultValue.instrument === 'CREDIT' && (
          <div className={styles.contentSection}>
            <TicketContentRow
              field="Валюта"
              value={defaultValue.firstCurrency}
            />
            <TicketContentRow
              field="Сумма кредита"
              value={defaultValue.creditSum}
            />
            <TicketContentRow
              field="Дата с"
              value={convertCurrentDate(defaultValue.dateFrom, 'shortRussian')}
            />

            <TicketContentRow
              field="Дата по"
              value={convertCurrentDate(defaultValue.dateTo, 'shortRussian')}
            />
            <TicketContentRow
              field="Проц. ставка"
              value={defaultValue.interestRate}
            />
            <TicketContentRow
              field="Дата уплаты %"
              value={convertCurrentDate(
                defaultValue.interestPaymentDate,
                'shortRussian'
              )}
            />
            <TicketContentRow
              field="База"
              value={defaultValue.calculationBase}
            />
            <TicketContentRow
              field="Валюта расчета"
              value={defaultValue.secondCurrency}
            />
          </div>
        )}
        {defaultValue.instrument === 'SWAP' && (
          <div className={styles.contentSection}>
            <TicketContentRow
              field="Вал. пара"
              value={defaultValue.currencyPair}
            />
            <div className={styles.leg}>
              <h6 className={styles.legSubtitle}>Ближняя нога</h6>
              <TicketContentRow
                field="Инструмент"
                value={defaultValue.shortLegInstrumentDescription}
              />
              <TicketContentRow
                field="Направление"
                value={defaultValue.shortLegTransactionDirectionDescription}
              />
              <TicketContentRow
                field="Объем"
                value={defaultValue.shortLegTransactionVolume}
              />
              {defaultValue.shortLegInstrument === INSTRUMENTS.FORWARD && (
                <>
                  <TicketContentRow
                    field="Спот цена"
                    value={defaultValue.shortLegSpotPrice}
                  />
                  <TicketContentRow
                    field="Фрвд. пункты"
                    value={defaultValue.shortLegForwardPoints}
                  />
                </>
              )}

              <TicketContentRow
                field="Полная цена"
                value={defaultValue.shortLegFullPrice}
              />
              <TicketContentRow
                field="Тенор"
                value={defaultValue.shortLegTenor}
              />
              <TicketContentRow
                field="Дата вал."
                value={convertCurrentDate(
                  defaultValue.shortLegValueDate,
                  'shortRussian'
                )}
              />
            </div>
            <TicketContentRow
              field="Своп пункты"
              value={defaultValue.swapPoints}
            />
            <div className={styles.leg}>
              <h6 className={styles.legSubtitle}>Дальняя нога</h6>
              <TicketContentRow
                field="Инструмент"
                value={defaultValue.longLegInstrumentDescription}
              />
              <TicketContentRow
                field="Направление"
                value={defaultValue.longLegTransactionDirectionDescription}
              />
              <TicketContentRow
                field="Объем"
                value={defaultValue.longLegTransactionVolume}
              />
              <TicketContentRow
                field="Спот цена"
                value={defaultValue.longLegSpotPrice}
              />
              <TicketContentRow
                field="Фрвд. пункты"
                value={defaultValue.longLegForwardPoints}
              />
              <TicketContentRow
                field="Полная цена"
                value={defaultValue.longLegFullPrice}
              />
              <TicketContentRow
                field="Тенор"
                value={defaultValue.longLegTenor}
              />
              <TicketContentRow
                field="Дата вал."
                value={convertCurrentDate(
                  defaultValue.longLegValueDate,
                  'shortRussian'
                )}
              />
            </div>
          </div>
        )}
      </ul>
      {isNewRecepient && (
        <footer className={styles.footer}>
          <Button
            onClick={() => handleTicket('DECLINE', defaultValue.id)}
            variant="outline"
            size="s"
          >
            Отклонить
          </Button>
          <Button
            onClick={() => handleTicket('ACCEPT', defaultValue.id)}
            size="s"
          >
            Принять
          </Button>
        </footer>
      )}
    </form>
  );
};
