import classNames from 'classnames';
import React, { useState } from 'react';

import { Checkbox } from 'src/components/atoms/Checkbox';
import { Icon, IconType } from 'src/components/atoms/Icon';
import { IconButton } from 'src/components/atoms/IconButton';
import {
  DropDownSubItem,
  DropSubItem,
} from 'src/components/molecules/DropDownSubItem';
import { Functionality } from 'src/components/organisms/SectionHeader';
import { ChatGroup } from 'src/types';

import styles from 'src/components/molecules/DropDownItem/styles.module.css';

export type Props = {
  id: string;
  title: string;
  disabled?: boolean;
  iconStart?: null | IconType;
  endIcon?: 'checkbox' | 'arrow' | 'pushpin';
  pinned?: boolean;
  setFunctionality?: (f: Functionality[]) => void;
  endIconAction?: (event: React.MouseEvent<HTMLElement>) => void;
  functionality?: Functionality[];
  handleActive?: (id: ChatGroup['chatGroupId']) => void;
  closePopup?: () => void;
  action?: (id?: string, id2?: string) => void;
  subItems?: DropSubItem[];
  memberId?: string;
  ownerId?: string;
};

export const DropDownItem: React.FC<Props> = ({
  id,
  title,
  disabled,
  iconStart,
  endIcon,
  pinned,
  setFunctionality,
  functionality,
  action,
  subItems,
  memberId,
  ownerId,
}) => {
  const [isToFix, setIsToFix] = useState(pinned);
  const [isActive, setIsActive] = useState(false);

  const DropDownItem = classNames(styles.wrapper, {
    [styles.disabled]: disabled,
  });

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsToFix(!isToFix);
    if (functionality && setFunctionality) {
      const flag = functionality.includes(iconStart as Functionality);
      setFunctionality(
        flag
          ? functionality.filter((i) => i !== iconStart)
          : [...functionality, iconStart as Functionality]
      );
    }
  };

  return (
    <div>
      <div
        role="button"
        onClick={() => {
          endIcon === 'arrow'
            ? setIsActive(!isActive)
            : action?.(memberId, ownerId);
        }}
        className={DropDownItem}
      >
        <div className={styles.content}>
          <div className={styles.iconContainer}>
            <div className={styles.wrapperIcon}>
              {iconStart ? <Icon icon={iconStart} size="xs" /> : null}
            </div>
          </div>

          <p className={styles.title}>{title}</p>
        </div>

        {endIcon === 'checkbox' && <Checkbox id={id} disabled={disabled} />}
        {endIcon === 'arrow' && (
          <IconButton
            icon={'arrowRight'}
            onClick={(e) => {
              e.stopPropagation();
              setIsActive(!isActive);
            }}
          />
        )}
        {endIcon === 'pushpin' && (
          <IconButton
            icon={isToFix ? 'pushPinActive' : 'pushPin'}
            onClick={(e) => handleClick(e)}
          />
        )}
      </div>
      {isActive && subItems && (
        <div className={styles.dropContent}>
          {subItems.map((item) => (
            <DropDownSubItem subItem={item} key={item.id} />
          ))}
        </div>
      )}
    </div>
  );
};
