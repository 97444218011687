import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actions } from 'src/actions';
import { Contacts } from 'src/types';

export type State = {
  list: Contacts[];
};

const initialState: State = {
  list: [],
};

const reducer = reducerWithInitialState<State>(initialState).case(
  actions.api.chat.contactsList.done,
  (state, payload): State => ({
    ...state,
    list: payload.result,
  })
);

export const contacts = { initialState, reducer };
