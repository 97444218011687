import React, { useState } from 'react';

import { Icon } from 'src/components/atoms/Icon';
import { IconButton } from 'src/components/atoms/IconButton';
import { UserStatus } from 'src/components/molecules/UserStatus';
import { Position } from 'src/components/organisms/ChatInstance';
import { DropDownMenu } from 'src/components/organisms/DropDownMenu';
import { DropItem } from 'src/components/organisms/DropItemList';
import { ID, Organization2, User } from 'src/types';
import { isIdContainsChecking } from 'src/utils/chat';

import styles from 'src/components/molecules/GroupChatPeopleSection/styles.module.css';

export type Props = {
  contacts: User[];
  showUserInfo?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string
  ) => void;
  currentUserId?: ID;
  handleMembers?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string
  ) => void;
  setIsShown?: React.Dispatch<React.SetStateAction<boolean>>;
  isShown?: boolean;
  dropItemsHeader?: DropItem[];
  dropItemsForCurrentUser?: DropItem[];
  isChatInfo?: boolean;
  isActiveId?: string;
  members?: User[];
  admins?: User[];
};

export const GroupChatPeopleSection: React.FC<Props> = ({
  contacts,
  showUserInfo,
  currentUserId,
  handleMembers,
  setIsShown,
  isShown = false,
  dropItemsHeader,
  isChatInfo,
  members,
  admins,
  dropItemsForCurrentUser,
}) => {
  const [, setIsHover] = useState(false);
  const [position, setPosition] = useState<Position>({ x: 0, y: 0 });
  const openMenu = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    const newPosition = {
      x: e.pageX - 200,
      y: e.pageY + 5,
    };
    setPosition(newPosition);
    setIsShown?.((prevState) => !prevState);
  };

  const onContextMenu = () => {
    setIsHover((prevState) => !prevState);
    if (isShown) setIsShown?.((prevState) => !prevState);
  };

  return (
    <div className={styles.wrapperContact}>
      {contacts?.map((contact) => (
        <div
          role="presentation"
          key={contact.userId}
          className={styles.contactChatInfoItem}
          onClick={(e) => showUserInfo?.(e, contact.userId)}
          onMouseLeave={() => onContextMenu()}
        >
          <UserStatus status={contact?.status ?? 'OFFLINE'} />
          <p>{contact.name}</p>
          <div className={styles.contactItemOrg}>
            <span className={styles.organizationName}>
              {(contact?.organization as Organization2)?.organizationName}
            </span>
          </div>
          <div
            role="presentation"
            key={contact.userId}
            className={styles.icon}
            onClick={(e) => handleMembers?.(e, contact.userId)}
          >
            {isChatInfo ? (
              <>
                <IconButton
                  icon="burger"
                  onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                    openMenu(e)
                  }
                />
              </>
            ) : members && currentUserId !== contact.userId ? (
              <Icon
                icon={
                  isIdContainsChecking(members, contact.userId) ||
                  (admins && isIdContainsChecking(admins, contact.userId))
                    ? 'remove'
                    : 'add'
                }
                fill="#EDEEF2"
              />
            ) : currentUserId === contact.userId ? null : (
              <Icon icon="remove" fill="#EDEEF2" />
            )}

            {isShown && (
              <DropDownMenu
                dropItemsHeader={
                  dropItemsForCurrentUser && contact.userId === currentUserId
                    ? dropItemsForCurrentUser
                    : dropItemsHeader || []
                }
                position={position}
                memberId={contact.userId}
                ownerId={currentUserId}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
